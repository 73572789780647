/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearMassDeleteStates,
  massDeletePayment,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { MetroSpinner } from "react-spinners-kit";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const MassDeletePopup = ({ token, setMassDeletePopup, allCampaigns }) => {
  // redux state
  const { massDeleteLoading, massDeleteData } = useSelector(
    (state) => state.eCheck
  );

  // close modal
  const handleCloseModal = () => {
    if (!massDeleteLoading) {
      setMassDeletePopup(false);
    }
  };

  // campaign
  const [selectedCampaign, setSelectedCampaign] = useState({
    value: "",
    label: "",
    minimumAmount: "",
  });

  // start date
  const [displayDateTimeStartDate, setDisplayDateTimeStartDate] =
    useState(null);
  const [apiDateTimeStartDate, setApiDateTimeStartDate] = useState(null);

  // Convert unformatted display date into formatted string for API
  useEffect(() => {
    if (displayDateTimeStartDate) {
      const year = displayDateTimeStartDate.getFullYear();
      const month = String(displayDateTimeStartDate.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(displayDateTimeStartDate.getDate()).padStart(2, "0");

      const formatted = `${year}-${month}-${day}`;
      setApiDateTimeStartDate(formatted);
    } else {
      setApiDateTimeStartDate(null);
    }
  }, [displayDateTimeStartDate]);

  // end date
  const [displayDateTimeEndDate, setDisplayDateTimeEndDate] = useState(null);
  const [apiDateTimeEndDate, setApiDateTimeEndDate] = useState(null);

  // Convert unformatted display date into formatted string for API
  useEffect(() => {
    if (displayDateTimeEndDate) {
      const year = displayDateTimeEndDate.getFullYear();
      const month = String(displayDateTimeEndDate.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(displayDateTimeEndDate.getDate()).padStart(2, "0");

      const formatted = `${year}-${month}-${day}`;
      setApiDateTimeEndDate(formatted);
    } else {
      setApiDateTimeEndDate(null);
    }
  }, [displayDateTimeEndDate]);

  // comments
  const [comments, setComments] = useState("");

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear previous error msg
  useEffect(() => {
    dispatch(clearMassDeleteStates());
  }, []);

  // mass delete event
  const handleMassDelete = (e) => {
    e.preventDefault();
    // Show a warning using SweetAlert
    Swal.fire({
      title: "Warning!",
      html: `<div><p style="color:#dc3546">Are you sure you want to cancel all payments between <span style="font-weight:600">${apiDateTimeStartDate}</span> and <span style="font-weight:600">${apiDateTimeEndDate}</span> for the campaign <span style="font-weight:600">${selectedCampaign.label}</span>?</p><p style="color: rgb(106, 108, 111); font-weight: 600">You cannot undo this action.</p></div>`,
      icon: "warning",
      customClass: {
        container: "custom-sweetalert-container",
        confirmButton: "custom-danger-button",
      },
      didOpen: () => {
        // Set the zIndex for the SweetAlert container
        document.querySelector(
          ".custom-sweetalert-container"
        ).style.zIndex = 999999;
      },
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          massDeletePayment({
            token,
            campaignTag: selectedCampaign.value,
            startDate: apiDateTimeStartDate,
            endDate: apiDateTimeEndDate,
            comments,
          })
        ).then((res) => {
          if (res.payload.successMsg) {
            setMassDeletePopup(false);
            toast.success(`${res.payload.successMsg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (
            res.payload.axiosError === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Mass Delete Payment</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleMassDelete}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Campaign</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="bottom"
                    value={allCampaigns.find(
                      (option) => option.value === selectedCampaign.value
                    )}
                    onChange={(option) => setSelectedCampaign(option)}
                    options={allCampaigns}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Start Date</label>
                  <DatePicker
                    style={{ width: 100 + "%" }}
                    className="edit-media-inputs"
                    selected={displayDateTimeStartDate}
                    onChange={(value) => setDisplayDateTimeStartDate(value)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Start Date"
                    isClearable
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>End Date</label>
                  <DatePicker
                    style={{ width: 100 + "%" }}
                    className="edit-media-inputs"
                    selected={displayDateTimeEndDate}
                    onChange={(value) => setDisplayDateTimeEndDate(value)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="End Date"
                    isClearable
                    required
                    portalId="end-date-datepicker"
                    popperClassName="custom-datepicker-zindex"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Comments</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    autoComplete="off"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    title=""
                    required
                    placeholder="Comments"
                  />
                </MDBCol>
              </MDBRow>

              {massDeleteData &&
                (massDeleteData.apiError || massDeleteData.axiosError) && (
                  <div className="error-msg">
                    {massDeleteData.apiError ? (
                      <>
                        {typeof massDeleteData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(massDeleteData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {massDeleteData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{massDeleteData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{massDeleteData.axiosError}</>
                    )}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  style={{
                    color: "#fff",
                    background: "#dc3546",
                    borderColor: "#dc3546",
                  }}
                  disabled={massDeleteLoading}
                >
                  {massDeleteLoading ? (
                    <MetroSpinner
                      loading={massDeleteLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "DELETE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MassDeletePopup;
