import React from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import noDataFound from "../../../../Images/noDataFound.png";
import chipImg from "../../../../Images/chip.png";

const Wallets = ({
  handleDeleteWalletPopup,
  handleEditWalletPopup,
  handleWalletChargePopup,
}) => {
  // redux state
  const { walletData, walletError } = useSelector((state) => state.wallet);

  return (
    <>
      {walletError ? (
        <div
          className="error-msg"
          style={{ marginTop: 0, width: "fit-content" }}
        >
          {walletError}
        </div>
      ) : (
        <>
          {walletData && walletData.wallets && walletData.wallets.length > 0 ? (
            <div className="wallet-container">
              {walletData.wallets.map((wallet) => {
                // Split the name by spaces
                const nameParts = wallet.name.split(" ");

                // Determine the display name based on the number of name parts
                let displayName = wallet.name;
                if (nameParts.length === 3) {
                  displayName = `${nameParts[0]} ${
                    nameParts[nameParts.length - 1]
                  }`;
                }
                return (
                  <div
                    className="wallet-card payments-card"
                    key={wallet.cardId}
                    style={{
                      borderTop: wallet.isExpired
                        ? "3px solid #f2b92c"
                        : "3px solid #007c16",
                      borderRight: "1px solid #eaeaea",
                      borderLeft: "1px solid #eaeaea",
                      borderBottom: "1px solid #eaeaea",
                    }}
                  >
                    <h6>{wallet.function}</h6>
                    <div className="chip-img">
                      <img alt="chip" src={chipImg} />
                    </div>
                    <h4>{wallet.number}</h4>
                    <div className="name-container">
                      <div className="valid-thru">
                        <div className="expiry-date">
                          <h6>
                            VALID
                            <br /> THRU
                          </h6>
                          <h4>{wallet.expiryDate}</h4>
                        </div>
                        <h4>{displayName}</h4>
                      </div>
                      <div className="card-brand-image">
                        <img alt="card" src={wallet.cardImage} />
                      </div>
                    </div>
                    <div className="wallet-action-buttons-div">
                      <button
                        type="button"
                        className="wallet-action-btns charge"
                        disabled={wallet.isExpired}
                        onClick={() => handleWalletChargePopup(wallet)}
                      >
                        CHARGE
                      </button>
                      <button
                        type="button"
                        className="wallet-action-btns edit"
                        onClick={() => handleEditWalletPopup(wallet)}
                      >
                        EDIT
                      </button>
                      <button
                        type="button"
                        className="wallet-action-btns delete"
                        onClick={() => handleDeleteWalletPopup(wallet)}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Cards Found" zIndex={100001}>
                <img src={noDataFound} alt="No Cards Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Wallets;
