/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Icon from "react-icons-kit";
import { ic_close } from "react-icons-kit/md/";
import { list } from "react-icons-kit/feather/list";
import { MetroSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateCampaign,
  updateModalMount,
} from "../../Store/Slices/Sidebar/Campaigns/CampaignSlice";
import IconDrawer from "./IconDrawer";
import AmountsDrawer from "./AmountsDrawer";

// multi select animated components
const animatedComponents = makeAnimated();

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

function UpdateCampaignModal({ token, open, setOpen, data }) {
  // backshadow variants
  const backVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // modal variants
  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // reset update campaign states in redux store
  useEffect(() => {
    dispatch(updateModalMount());
  }, []);

  // get icons from redux store
  const { iconsData } = useSelector((state) => state.getIconsAndNodes);

  // get update campaign states from redux store
  const { updateLoader, updateData } = useSelector((state) => state.campaign);

  // close modal
  const closeModal = () => {
    if (!updateLoader) {
      setOpen(false);
    }
  };

  // tabs
  const [activeTab, setActiveTab] = useState("General");

  // tab click
  const handleTabClick = (tabName) => {
    if (!updateLoader) {
      setActiveTab(tabName);
    }
  };

  // start of general tab

  const [name, setName] = useState(data.name || "");
  const [description, setDescription] = useState(data.description || "");
  const [step, setStep] = useState("campaign-details");
  const [iconTag, setIconTag] = useState(data.icon.tagNumber || 0);

  // go to browse icon popup
  const handlesetStep = () => {
    setStep("icon-step");
  };

  // change icon function
  const changeIconFunction = (id) => {
    setIconTag(id);
    setStep("campaign-details");
  };

  const [minimumAmount, setMinimumAmount] = useState(
    data.minimumAmount || 0.01
  );
  const [sortOrder, setSortOrder] = useState(data.sortOrder);
  const [issueTaxReceipt, setIssueTaxReceipt] = useState(
    data.issueTaxReceipt ? 1 : 0
  );
  const [status, setStatus] = useState(data.status ? 1 : 0);

  // end of general tab

  // go to advance tab
  const handleGoToAdvanceTab = (e) => {
    e.preventDefault();
    if (iconTag) {
      handleTabClick("Advance");
    } else {
      setStep("icon-step");
      toast.error("Select an icon for your campaign", {
        position: "top-center",
        duration: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // start of advance tab

  // display states
  const [displayStartDate, setDisplayStartDate] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(null);
  // api states
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // convert formatted date into unformatted for datepicker to display
  useEffect(() => {
    // start date
    const startDate = new Date(data.startDate);
    setDisplayStartDate(startDate);

    // end date
    const endDate = new Date(data.endDate);
    setDisplayEndDate(endDate);
  }, [data]);

  // convert the unformatted api response into the formatted for edit api
  useEffect(() => {
    if (displayStartDate) {
      const year = displayStartDate.getFullYear();
      const month = String(displayStartDate.getMonth() + 1).padStart(2, "0");
      const day = String(displayStartDate.getDate()).padStart(2, "0");
      const hours = String(displayStartDate.getHours()).padStart(2, "0");
      const minutes = String(displayStartDate.getMinutes()).padStart(2, "0");
      const seconds = String(displayStartDate.getSeconds()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      setStartDate(formatted);
    } else {
      setStartDate(null);
    }
  }, [displayStartDate]);

  // convert the unformatted api response into the formatted for edit api
  useEffect(() => {
    if (displayEndDate) {
      const year = displayEndDate.getFullYear();
      const month = String(displayEndDate.getMonth() + 1).padStart(2, "0");
      const day = String(displayEndDate.getDate()).padStart(2, "0");
      const hours = String(displayEndDate.getHours()).padStart(2, "0");
      const minutes = String(displayEndDate.getMinutes()).padStart(2, "0");
      const seconds = String(displayEndDate.getSeconds()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      setEndDate(formatted);
    } else {
      setEndDate(null);
    }
  }, [displayEndDate]);

  // handle date
  const handleDate = (setState) => (value) => {
    setState(value);
  };

  const [targetAmount, setTargetAmount] = useState(data.targetAmount || 0);
  const [targetAchieved, setTargetAchieved] = useState(data.targetAchieved || 0);
  const [fees, setFees] = useState(data.fees || 0.0);
  const [taxReceiptRatio, setTaxReceiptRatio] = useState(
    data.taxReceiptRatio || 1.0
  );
  const [allowRecurringModification, setAllowRecurringModification] = useState(
    data.allowRecurringModification ? 1 : 0
  );
  const [donationCampaign, setDonationCampaign] = useState(
    data.donationCampaign ? 1 : 0
  );
  const [hidden, setHidden] = useState(data.hidden ? true : false);
  const [enableQuantity, setEnableQuantity] = useState(
    data.enableQuantity ? true : false
  );

  // amounts
  const [amounts, setAmounts] = useState(
    data.amounts?.length > 0 ? data.amounts : []
  );

  // Rearrange the amounts in ascending order whenever the amounts array changes
  useEffect(() => {
    if (amounts.length > 0) {
      const sortedAmounts = [...amounts].sort((a, b) => a - b);
      setAmounts(sortedAmounts);
    } else {
      setAmounts([]);
    }
  }, []);

  // amount drawer
  const [amountsDrawer, setAmountsDrawer] = useState(false);

  // frequency
  const [selectedFrequency, setSelectedFrequency] = useState(
    data.frequency?.length > 0
      ? data.frequency.map((freq) => ({
          value: freq,
          label: freq,
        }))
      : []
  );

  // frequency options
  const frequencyOptions = [
    { value: "ONETIME", label: "ONETIME" },
    { value: "DAILY", label: "DAILY" },
    { value: "WEEKLY", label: "WEEKLY" },
    { value: "BIWEEKLY", label: "BIWEEKLY" },
    { value: "MONTHLY", label: "MONTHLY" },
  ];

  // frequency change
  const handleFrequencyChange = (selectedOptions) => {
    setSelectedFrequency(selectedOptions);
    if (!selectedOptions.some((freq) => freq.value === "WEEKLY")) {
      setDay(null);
    }
  };

  // recurring day
  const [day, setDay] = useState(data.recurringDay); // 1 or null

  // day options
  const days = [
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
    { value: 7, label: "Sunday" },
  ];

  // setstate function
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  // handle Number sort order
  const handleNumberInput = (setState) => (event) => {
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 10) {
      newValue = 10;
    }
    setState(newValue);
  };

  // handle number not negative
  const handleNumberNotNegative = (setState) => (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setState(newValue);
  };

  // handle tax receipt ratio
  const handleTaxReceiptRatio = (event) => {
    let newValue = parseFloat(event.target.value);
    // Ensure value is within the specified range
    newValue = Math.min(Math.max(newValue, 0), 1);
    // Round to two decimal places
    newValue = Math.round(newValue * 100) / 100;
    if (isNaN(newValue)) {
      newValue = "";
    }
    setTaxReceiptRatio(newValue);
  };

  // end of advance tab

  // go to devices tab
  const handleGoToDevicesTab = (e) => {
    e.preventDefault();
    handleTabClick("Devices");
  };

  // start of devices tab

  const [nodes, setNodes] = useState(data.nodes?.length > 0 ? data.nodes : []);
  const [applyToAll, setApplyToAll] = useState(false);

  // Check if all nodes have status set to true, then set apply to all to true
  useEffect(() => {
    const allNodesChecked = data.nodes.every((node) => node.status);

    // Set applyToAll based on the result
    setApplyToAll(allNodesChecked);
  }, []);

  // apply to all
  const handleApplyToAll = (isChecked) => {
    setApplyToAll(isChecked);
    const updatedNodes = nodes.map((node) => ({
      ...node,
      status: isChecked, // Set status to true if isChecked is true, false otherwise
    }));

    // Update the state variable nodes with the new array
    setNodes(updatedNodes);
  };

  // handle nodes function
  const handleNodesChange = (tag) => (event) => {
    const isChecked = event.target.checked;
    const updatedNodes = nodes.map((node) =>
      node.nodeTag === tag ? { ...node, status: isChecked } : node
    );
    // Check if all nodes have status set to true
    const allNodesChecked = updatedNodes.every((node) => node.status);

    // Set applyToAll based on the result
    setApplyToAll(allNodesChecked);
    setNodes(updatedNodes);
  };

  // submit function
  const handleSubmit = (event) => {
    event.preventDefault();
    let id = data.tagNumber;
    let obj = {
      name,
      description,
      startDate,
      endDate,
      status,
      targetAmount,
      minimumAmount,
      sortOrder,
      iconTag,
      issueTaxReceipt,
      nodes,
      donationCampaign,
      taxReceiptRatio,
      allowRecurringModification,
      hidden,
      enableQuantity,
      recurringDay: day,
    };

    if (targetAmount === "") {
      obj.targetAmount = 0;
    } else {
      obj.targetAmount = parseFloat(targetAmount);
    }

    if (targetAchieved === "") {
      obj.targetAchieved = 0;
    } else {
      obj.targetAchieved = parseFloat(targetAchieved);
    }

    if (minimumAmount === "") {
      obj.minimumAmount = 0;
    } else {
      obj.minimumAmount = parseFloat(minimumAmount);
    }

    if (fees === "") {
      obj.fees = 0.0;
    } else {
      obj.fees = parseFloat(fees);
    }
    
    if (amounts.length > 0) {
      obj.amounts = amounts;
    }

    if (selectedFrequency.length > 0) {
      obj.frequency = selectedFrequency.map((freq) => freq.value);
    }

    dispatch(updateCampaign({ id, token, obj })).then((res) => {
      if (res.payload.successMsg) {
        setOpen(false);

        toast.success(res.payload.successMsg, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <div className="updateCampaignComponent">
      {open && (
        <AnimatePresence>
          <motion.div
            className="modalOverlay"
            variants={backVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div
              className="modalContent"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
            >
              <div className="modalHeader">
                <h5 className="mb-0 fs-14">Update Campaign</h5>
                <button onClick={closeModal} className="close">
                  <Icon icon={ic_close} size={20} />
                </button>
              </div>
              <div className="modalBody">
                {/* tabs container */}
                <div className="tabs-container">
                  <div
                    className={`tab ${activeTab === "General" ? "active" : ""}`}
                    onClick={() => handleTabClick("General")}
                    style={{
                      opacity: updateLoader ? 0.5 : 1,
                      pointerEvents: updateLoader ? "none" : "auto",
                    }}
                  >
                    General
                  </div>
                  <div
                    className={`tab ${activeTab === "Advance" ? "active" : ""}`}
                    onClick={() => handleTabClick("Advance")}
                    style={{
                      opacity:
                        updateLoader || activeTab === "General" ? 0.5 : 1,
                      pointerEvents:
                        updateLoader || activeTab === "General"
                          ? "none"
                          : "auto",
                    }}
                  >
                    Advance
                  </div>
                  <div
                    className={`tab ${activeTab === "Devices" ? "active" : ""}`}
                    onClick={() => handleTabClick("Devices")}
                    style={{
                      opacity:
                        updateLoader ||
                        activeTab === "General" ||
                        activeTab === "Advance"
                          ? 0.5
                          : 1,
                      pointerEvents:
                        updateLoader ||
                        activeTab === "General" ||
                        activeTab === "Advance"
                          ? "none"
                          : "auto",
                    }}
                  >
                    Devices
                  </div>
                </div>

                {/* general */}
                {activeTab === "General" && (
                  <form onSubmit={handleGoToAdvanceTab}>
                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">
                        <div className="form-group mb-3">
                          <label className="form-label">Name</label>
                          <input
                            onChange={handleChange(setName)}
                            value={name}
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>

                        <div className="form-group mb-3 position-relative">
                          <label className="form-label me-2">Icon</label>
                          {iconTag && (
                            <img
                              alt="icon"
                              src={
                                iconsData.find(
                                  (state) => iconTag === state.tagNumber
                                ).filename
                              }
                              style={{
                                width: "32px",
                                height: "32px",
                                marginLeft: "8px",
                                marginBottom: "4px",
                              }}
                            />
                          )}
                          <button
                            onClick={handlesetStep}
                            type="button"
                            className="browseIcon"
                          >
                            Browse
                          </button>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <label className="form-label">
                          Description (Max Characters: 1000)
                        </label>
                        <textarea
                          maxLength={1000}
                          onChange={handleChange(setDescription)}
                          value={description}
                          className="form-control"
                          required
                          style={{ minHeight: "128px" }}
                        ></textarea>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">
                        <label className="form-label">Minimum Amount</label>
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <input
                            type="number"
                            className="form-control"
                            value={minimumAmount}
                            onChange={handleNumberNotNegative(setMinimumAmount)}
                            inputMode="decimal"
                            step="0.01"
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <label className="form-label">Sort Order</label>
                        <div className="input-group">
                          <input
                            type="number"
                            value={sortOrder}
                            onChange={handleNumberInput(setSortOrder)}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">
                        <label className="form-label">Issue Tax Receipt</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={issueTaxReceipt}
                              onChange={(e) =>
                                setIssueTaxReceipt(e.target.checked ? 1 : 0)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <label className="form-label">Status</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={status}
                              onChange={(e) =>
                                setStatus(e.target.checked ? 1 : 0)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={closeModal}
                        className="modalCancelBtn me-2"
                      >
                        CANCEL
                      </button>
                      <button type="submit" className="modalSubmitBtn">
                        NEXT
                      </button>
                    </div>
                  </form>
                )}

                {/* advance */}
                {activeTab === "Advance" && (
                  <form onSubmit={handleGoToDevicesTab}>
                    <div className="row">
                      <div className="col-12 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="form-label">
                            Start Date and Time
                          </label>
                          <DatePicker
                            selected={displayStartDate}
                            onChange={handleDate(setDisplayStartDate)}
                            className="form-control"
                            showTimeSelect
                            timeIntervals={5}
                            timeFormat="HH:mm"
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption="Time"
                            placeholderText="Start Date and Time"
                            isClearable
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mb-4">
                        <div className="form-group">
                          <label className="form-label">
                            End Date and Time
                          </label>
                          <DatePicker
                            selected={displayEndDate}
                            onChange={handleDate(setDisplayEndDate)}
                            className="form-control"
                            showTimeSelect
                            timeIntervals={5}
                            timeFormat="HH:mm"
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption="Time"
                            placeholderText="End Date and Time"
                            isClearable
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Target Amount</label>
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <input
                            type="number"
                            className="form-control"
                            value={targetAmount}
                            onChange={handleNumberNotNegative(setTargetAmount)}
                            inputMode="decimal"
                            step="100"
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Target Achieved</label>
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <input
                            type="number"
                            className="form-control"
                            value={targetAchieved}
                            onChange={handleNumberNotNegative(setTargetAchieved)}
                            inputMode="decimal"
                            step="10"
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Fee</label>
                        <div className="input-group">
                          <span className="input-group-text">%</span>
                          <input
                            type="number"
                            className="form-control"
                            value={fees}
                            onChange={handleNumberNotNegative(setFees)}
                            inputMode="decimal"
                            step="0.01"
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Tax Receipt Ratio</label>
                        <div className="input-group">
                          <input
                            type="number"
                            value={taxReceiptRatio}
                            onChange={handleTaxReceiptRatio}
                            className="form-control"
                            required
                            inputMode="decimal"
                            title=""
                            step={0.01}
                            min={0}
                            max={1}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">
                          Allow Recurring Changes
                        </label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={allowRecurringModification}
                              onChange={(e) =>
                                setAllowRecurringModification(
                                  e.target.checked ? 1 : 0
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Enable Quantity</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={enableQuantity}
                              onChange={(e) =>
                                setEnableQuantity(
                                  e.target.checked ? true : false
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Donation Campaign</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={donationCampaign}
                              onChange={(e) =>
                                setDonationCampaign(e.target.checked ? 1 : 0)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Hidden</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={hidden}
                              onChange={(e) =>
                                setHidden(e.target.checked ? true : false)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">
                          Amounts
                          <Tippy
                            content={
                              amounts.length > 0 ? (
                                <ul
                                  style={{
                                    listStyle: "none",
                                    paddingLeft: "0",
                                    margin: "0",
                                  }}
                                >
                                  {amounts.map((amt, index) => (
                                    <li
                                      key={index}
                                      style={{ marginBottom: "4px" }}
                                    >
                                      {amt}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                "No Amount Added Yet"
                              )
                            }
                          >
                            <span style={{ marginLeft: "8px" }}>
                              <Icon icon={list} size={24} />
                            </span>
                          </Tippy>
                        </label>
                        <button
                          type="button"
                          className="configure-amounts-btn"
                          onClick={() => setAmountsDrawer(true)}
                        >
                          Configure Amounts
                        </button>
                      </div>

                      <div className="col-12 col-md-6 mb-4">
                        <label className="form-label">Frequency</label>
                        <Select
                          isMulti
                          components={animatedComponents}
                          value={selectedFrequency}
                          onChange={handleFrequencyChange}
                          options={frequencyOptions}
                          styles={styles}
                          menuPlacement="top"
                        />
                      </div>

                      <div className="col-12 col-md-3 mb-4">
                        <label className="form-label">Recurring Day</label>
                        <Select
                          value={
                            day
                              ? days.find((option) => option.value === day)
                              : null
                          }
                          onChange={(option) =>
                            setDay(option ? option.value : null)
                          }
                          options={days}
                          styles={styles}
                          menuPlacement="top"
                          placeholder="Recurring Day"
                          isClearable
                          isDisabled={
                            !selectedFrequency.some(
                              (freq) => freq.value === "WEEKLY"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={() => handleTabClick("General")}
                        className="modalCancelBtn me-2"
                      >
                        BACK
                      </button>
                      <button type="submit" className="modalSubmitBtn">
                        NEXT
                      </button>
                    </div>
                  </form>
                )}

                {/* devices */}
                {activeTab === "Devices" && (
                  <>
                    {nodes.length ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label className="form-label">Devices</label>
                          <Tippy content="Apply To All">
                            <label className="switch switch-apply">
                              <input
                                type="checkbox"
                                checked={applyToAll}
                                onChange={(e) =>
                                  handleApplyToAll(e.target.checked)
                                }
                              />
                              <span className="slider round slider-apply"></span>
                            </label>
                          </Tippy>
                        </div>

                        <div className="row mx-0 border nodesContainer">
                          {nodes.map((v, i) => (
                            <div
                              key={v.nodeTag}
                              className="col-12 col-md-4 py-2"
                            >
                              <p className="mb-2">
                                {v.organizationDefinedName}
                              </p>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={v.status}
                                  onChange={handleNodesChange(v.nodeTag)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="error-msg">No Device Found</div>
                    )}

                    <div className="border-top py-3">
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="modalCancelBtn me-2"
                          disabled={updateLoader}
                          onClick={() => handleTabClick("Advance")}
                        >
                          BACK
                        </button>
                        <button
                          type="button"
                          className="modalSubmitBtn"
                          disabled={updateLoader}
                          onClick={handleSubmit}
                        >
                          {updateLoader ? (
                            <div className="d-flex justify-content-center">
                              <MetroSpinner
                                size={20}
                                color="#fff"
                                loading={updateLoader}
                              />
                            </div>
                          ) : (
                            "UPDATE"
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {/* icon step */}
                {activeTab === "General" && step === "icon-step" && (
                  <IconDrawer
                    setStep={setStep}
                    iconTag={iconTag}
                    changeIconFunction={changeIconFunction}
                  />
                )}

                {/* amounts drawer */}
                {amountsDrawer && (
                  <AmountsDrawer
                    setAmountsDrawer={setAmountsDrawer}
                    amounts={amounts}
                    setAmounts={setAmounts}
                  />
                )}

                {/* error msg */}
                {updateData &&
                  updateData.campaignTag === data.tagNumber &&
                  (updateData.apiError || updateData.axiosError) && (
                    <div className="error-msg">
                      {updateData.apiError ? (
                        <>
                          {typeof updateData.apiError === "object" ? (
                            // Case 1: If apiError is an object
                            Object.keys(updateData.apiError).map(
                              (field, index) => (
                                <p key={index} style={{ marginBottom: 0 }}>
                                  {updateData.apiError[field][0]}
                                </p>
                              )
                            )
                          ) : (
                            // Case 2: If apiError is a simple string
                            <>{updateData.apiError}</>
                          )}
                        </>
                      ) : (
                        <>{updateData.axiosError}</>
                      )}
                    </div>
                  )}
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default UpdateCampaignModal;
