import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const HistoryNotesPopup = ({ setHistoryNotesPopup, historyNotesObj }) => {
  // close modal
  const handleCloseModal = () => {
    setHistoryNotesPopup(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Notes</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            {/* notes */}
            {historyNotesObj.historyNotes && (
              <div className="recurring-notes-div">
                <div>
                  {historyNotesObj.historyNotes
                    .split("<br/>")
                    .filter((line) => line.trim() !== "")
                    .map((line, index, arr) => (
                      <p
                        key={index}
                        style={{
                          marginBottom:
                            index === arr.length - 1 ? "0px" : "15px", // No margin for the last element
                        }}
                      >
                        {line}
                      </p>
                    ))}
                </div>
              </div>
            )}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default HistoryNotesPopup;
