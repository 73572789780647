import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MetroSpinner } from "react-spinners-kit";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { alertCircle } from "react-icons-kit/feather/alertCircle";

const ContactDetailsForm = ({
  organization,
  setOrganization,
  handleOrganizationDetailsSubmit,
  contactPhone,
  formattedContactPhone,
  handleContactPhoneChange,
}) => {
  // redux state
  const { updateOrganizationLoading, updateOrganizationData } = useSelector(
    (state) => state.organization
  );

  const [isFocused, setIsFocused] = useState(false);

  return (
    <form
      onSubmit={handleOrganizationDetailsSubmit}
      className="update-media-form"
    >
      <h4
        style={{
          borderBottom: 1 + "px solid #e4e4e4",
          paddingBottom: 5 + "px",
          marginBottom: 15 + "px",
        }}
      >
        Contact Details
      </h4>
      <MDBRow className="mb-3">
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Name</label>
          <input
            type="text"
            className="edit-media-inputs"
            value={organization.contactName}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                contactName: e.target.value,
              }))
            }
            required
            placeholder="Name"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>Email</label>
          <input
            type="email"
            className="edit-media-inputs"
            value={organization.contactEmail}
            onChange={(e) =>
              setOrganization((prevOrganization) => ({
                ...prevOrganization,
                contactEmail: e.target.value,
              }))
            }
            placeholder="Email"
          />
        </MDBCol>
        <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
          <label>
            Phone{" "}
            <Tippy
              content="Enter a valid 10-digit phone number."
              zIndex={100001}
            >
              <span style={{ cursor: "pointer" }}>
                <Icon icon={alertCircle} size={20} />
              </span>
            </Tippy>
          </label>
          <input
            type="tel"
            inputMode="numeric"
            value={isFocused ? contactPhone : formattedContactPhone}
            onChange={handleContactPhoneChange}
            placeholder="5555551234"
            className="edit-media-inputs"
            maxLength={14} // Limit to (###) ###-####
            required
            pattern="^\(\d{3}\) \d{3}-\d{4}$"
            title="Please enter a valid phone number with at least 10 digits."
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </MDBCol>
      </MDBRow>

      {updateOrganizationData &&
        (updateOrganizationData.apiError ? (
          <div className="error-msg" style={{ width: "fit-content" }}>
            {typeof updateOrganizationData.apiError === "object" ? (
              // Case 1: If apiError is an object
              Object.keys(updateOrganizationData.apiError).map(
                (field, index) => (
                  <p key={index} style={{ marginBottom: 0 }}>
                    {updateOrganizationData.apiError[field][0]}
                  </p>
                )
              )
            ) : (
              // Case 2: If apiError is a simple string
              <>{updateOrganizationData.apiError}</>
            )}
          </div>
        ) : (
          // Case 3: If apiError does not exist, render axiosError
          <>
            {updateOrganizationData && updateOrganizationData.axiosError && (
              <div className="error-msg" style={{ width: "fit-content" }}>
                {updateOrganizationData.axiosError}
              </div>
            )}
          </>
        ))}

      <div className="submit-and-cancel-div">
        <button
          type="submit"
          className="submit addModal"
          disabled={updateOrganizationLoading}
        >
          {updateOrganizationLoading ? (
            <MetroSpinner
              loading={updateOrganizationLoading}
              size={22}
              color="#fff"
            />
          ) : (
            "UPDATE"
          )}
        </button>
      </div>
    </form>
  );
};

export default ContactDetailsForm;
