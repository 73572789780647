import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { basic_download } from "react-icons-kit/linea/basic_download";
import { useNavigate } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import axios from "axios";
import hostName from "../../config";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const ListViewTransactionDetailsPopup = ({
  token,
  setTransactionDetailsPopup,
  transactionObj,
}) => {
  // download receipt states
  const [downloadReceiptLoading, setDownloadReceiptLoading] = useState(false);
  const [downloadReceiptError, setDownloadReceiptError] = useState(null);

  // close modal
  const handleCloseModal = () => {
    if (!downloadReceiptLoading) {
      setTransactionDetailsPopup(false);
    }
  };

  // navigate
  const navigate = useNavigate();

  // handle download receipt
  const handleDownloadReceipt = () => {
    setDownloadReceiptLoading(true);
    setDownloadReceiptError(null);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .get(
        `${hostName}api/admin/v1/transaction/reports/receipt?pTransactionTag=${transactionObj.transactionTag}`,
        axiosConfig
      )
      .then((response) => {
        // download pdf
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${transactionObj.transactionTag}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setDownloadReceiptError(error.message);
        }
      })
      .finally(() => setDownloadReceiptLoading(false));
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Transaction Details</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="transaction-details">
              <div className="header">
                <div className="merchant">
                  <h4>{transactionObj.accountHolderName}</h4>
                  <h5>{transactionObj.campaign}</h5>
                </div>
                <div className="amount">
                  {transactionObj.currencySymbol}
                  {transactionObj.amount}
                </div>
              </div>
              <div className="content">
                {/* processed number */}
                <div className="detail">
                  <h6 className="key">Processed Number</h6>
                  <h6>
                    {transactionObj.processedNumber && (
                      <>{transactionObj.processedNumber}</>
                    )}
                  </h6>
                </div>

                {/* billing expiry */}
                <div className="detail">
                  <h6 className="key">Billing Expiry</h6>
                  <h6>{transactionObj.billingExpiry}</h6>
                </div>
                {/* invoice */}
                <div className="detail">
                  <h6 className="key">Invoice</h6>
                  <h6>{transactionObj.invoice}</h6>
                </div>
                {/* created by */}
                <div className="detail">
                  <h6 className="key">Created By</h6>
                  <h6>{transactionObj.createdBy}</h6>
                </div>
                {/* next billing */}
                <div className="detail">
                  <h6 className="key">Next Billing</h6>
                  <h6>{transactionObj.nextBilling}</h6>
                </div>
                {/* batch id */}
                <div className="detail">
                  <h6 className="key">Batch ID</h6>
                  <h6>
                    {transactionObj.batchId && <>{transactionObj.batchId}</>}
                  </h6>
                </div>
                {/* return date */}
                {transactionObj.returnDate && (
                  <div className="detail">
                    <h6 className="key">Return Date</h6>
                    <h6>{transactionObj.returnDate}</h6>
                  </div>
                )}
                {/* start date */}
                <div className="detail">
                  <h6 className="key">Start Date</h6>
                  <h6>{transactionObj.startDate}</h6>
                </div>
                {/* status */}
                <div className="detail">
                  <h6 className="key">Status</h6>
                  <h6>{transactionObj.statusText}</h6>
                </div>
                {/* total payments */}
                <div className="detail">
                  <h6 className="key">Total Payments</h6>
                  <h6>{transactionObj.totalPayments}</h6>
                </div>
                {/* transaction id */}
                {transactionObj.transactionId && (
                  <div className="detail">
                    <h6 className="key">Transaction ID</h6>
                    <h6>{transactionObj.transactionId}</h6>
                  </div>
                )}
              </div>
              {/* notes */}
              {transactionObj.notes && (
                <div className="echeck-notes-div">
                  <h5>Notes</h5>
                  <div>
                    {transactionObj.notes
                      .split("<BR/>")
                      .filter((line) => line.trim() !== "")
                      .map((line, index, arr) => (
                        <p
                          key={index}
                          style={{
                            marginBottom:
                              index === arr.length - 1 ? "0px" : "15px", // No margin for the last element
                          }}
                        >
                          {line}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>

            {/* error in case */}
            {downloadReceiptError && (
              <div className="error-msg">{downloadReceiptError}</div>
            )}

            <div
              className="reset-and-filter-div"
              style={{ padding: 10 + "px" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
              <button
                type="button"
                className="filter-records-btn"
                style={{
                  opacity: transactionObj.transactionTag ? 1 : 0.5,
                  pointerEvents: transactionObj.transactionTag
                    ? "auto"
                    : "none",
                }}
                onClick={() =>
                  handleDownloadReceipt(transactionObj.transactionTag)
                }
              >
                {downloadReceiptLoading ? (
                  <MetroSpinner
                    loading={downloadReceiptLoading}
                    color="#fff"
                    size={22}
                  />
                ) : (
                  <>
                    <Icon
                      icon={basic_download}
                      size={20}
                      style={{ marginRight: 10 + "px" }}
                    />
                    DOWNLOAD
                  </>
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ListViewTransactionDetailsPopup;
