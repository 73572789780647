import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { ecommerce_creditcard } from "react-icons-kit/linea/ecommerce_creditcard";
import { basic_trashcan_remove } from "react-icons-kit/linea/basic_trashcan_remove";
import { star } from "react-icons-kit/fa/star";
import { basic_star } from "react-icons-kit/linea/basic_star";
import { ecommerce_dollar } from "react-icons-kit/linea/ecommerce_dollar";
import { useSelector } from "react-redux";

const BankingDetails = ({
  handleUpdateBankingDetailsPopup,
  handleDeleteBankingDetailsPopup,
  handleMakeAccountDefaultPopup,
  setNewECheckTransactionPopup,
  setCopiedObj,
  setMakeDisabled,
}) => {
  // redux state
  const { bankAccountsData, bankAccountsError } = useSelector(
    (state) => state.eCheck
  );

  return (
    <>
      {bankAccountsError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {bankAccountsError}
        </div>
      ) : (
        <>
          {bankAccountsData?.length > 0 ? (
            <div className="transactions-table" id="bankAccountSection">
              <MDBTable align="middle">
                <MDBTableHead className="thead">
                  <tr>
                    <th scope="col">Account Title</th>
                    <th scope="col">Institute Name</th>
                    <th scope="col">Transit#</th>
                    <th scope="col">Account#</th>
                    <th scope="col">Actions</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {bankAccountsData.map((data, index) => (
                    <tr
                      key={data.tagNumber}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                        borderLeft: data.isDefault && "4px solid #007c16",
                      }}
                    >
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.accountHolderName}
                        </p>
                      </td>
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.institutionName}
                        </p>
                      </td>
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.transitNumber}
                        </p>
                      </td>
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.accountNumber}
                        </p>
                      </td>
                      <td className="icons-td">
                        <div className="actions-icon-container">
                          {/* update banking details */}
                          <Tippy
                            content="Update Banking Details"
                            zIndex={10004}
                          >
                            <span
                              onClick={() =>
                                handleUpdateBankingDetailsPopup(data)
                              }
                            >
                              <Icon icon={ecommerce_creditcard} size={20} />
                            </span>
                          </Tippy>

                          {/* delete banking details */}
                          <Tippy
                            content="Delete Banking Details"
                            zIndex={10004}
                          >
                            <span
                              onClick={() =>
                                handleDeleteBankingDetailsPopup(data)
                              }
                            >
                              <Icon icon={basic_trashcan_remove} size={20} />
                            </span>
                          </Tippy>

                          {/* make account default */}
                          <Tippy
                            content={
                              data.isDefault ? "Default" : "Set as Default"
                            }
                            zIndex={10004}
                          >
                            {data.isDefault ? (
                              <span>
                                <Icon icon={star} size={22} />
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  handleMakeAccountDefaultPopup(data)
                                }
                              >
                                <Icon icon={basic_star} size={20} />
                              </span>
                            )}
                          </Tippy>

                          {/* add a new eCheck transaction */}
                          <Tippy
                            content="New eCheck Transaction"
                            zIndex={10004}
                          >
                            <span
                              onClick={() => {
                                setNewECheckTransactionPopup(true);
                                setCopiedObj(data);
                                setMakeDisabled(true);
                              }}
                            >
                              <Icon icon={ecommerce_dollar} size={20} />
                            </span>
                          </Tippy>
                        </div>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Accounts Found">
                <img src={noDataFound} alt="No Accounts Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BankingDetails;
