import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { basic_trashcan_remove } from "react-icons-kit/linea/basic_trashcan_remove";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { basic_calendar } from "react-icons-kit/linea/basic_calendar";
import { basic_todo_txt } from "react-icons-kit/linea/basic_todo_txt";
import { messageCircle } from "react-icons-kit/feather/messageCircle";
import { basic_book_pen } from "react-icons-kit/linea/basic_book_pen";
import { copy } from "react-icons-kit/feather/copy";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const CardDataTable = ({
  data,
  index,
  donorSpecific,
  handleMoreDetailsPopup,
  handleSchedulePopup,
  handleEditECheckPopup,
  handleCloseECheckPopup,
  handleAddPaymentPopup,
  setNewECheckTransactionPopup,
  setCopiedObj,
  setMakeDisabled,
}) => {
  const circleStyle = {
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };
  return (
    <tr
      style={{
        backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
        borderLeft:
          data.status === "P"
            ? "4px solid grey"
            : data.status === "A"
            ? "4px solid #007c16"
            : "4px solid #f2b92c",
      }}
    >
      <td>
        <div className="d-flex align-items-center">
          <div style={circleStyle}>
            <LazyLoadImage
              src={data.campaignImage}
              alt={data.campaign}
              effect="blur"
              width={"100%"}
              height={"100%"}
              style={{
                objectFit: "cover",
                overflowClipMargin: "unset",
              }}
            />
          </div>
          <div className="ms-3">
            <span style={{ color: "#757575" }}>
              <p className="fw-bold mb-1">{data.campaign}</p>
            </span>
            {donorSpecific === undefined && (
              <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
                {data.donor ? (
                  <Tippy
                    content={`${data.donor.firstName} ${data.donor.lastName}`}
                    zIndex={10005}
                  >
                    <Link
                      to={`/donors/${data.donor.firstName}_${data.donor.lastName}_${data.donor.tagNumber}`}
                      className="donor-hyper-link"
                    >
                      {data.email}
                    </Link>
                  </Tippy>
                ) : (
                  <>{data.email}</>
                )}
              </p>
            )}
          </div>
        </div>
      </td>
      <td>
        <p
          className="fw-normal mb-1"
          style={{ fontSize: "16px", textAlign: "right" }}
        >
          {data.currencySymbol}

          {data.amount}
        </p>
      </td>
      <td>
        <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
          {data.billingPeriod}
        </p>
      </td>
      <td>
        <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
          {data.accountHolderName}
        </p>
      </td>
      <td>
        <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
          {data.instituteName}
        </p>
      </td>
      <td>
        <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
          {data.transitNumber}
        </p>
      </td>
      <td>
        <p className="fw-normal mb-1" style={{ fontSize: "16px" }}>
          {data.accountNumber}
        </p>
      </td>
      <td style={{ textAlign: "center" }}>
        <Tippy content={data.statusText} zIndex={10004}>
          <img
            src={data.statusImage}
            alt={data.statusText}
            style={{ width: "30px", cursor: "pointer" }}
          />
        </Tippy>
      </td>
      <td style={{ textAlign: "center" }}>
        {data.comments ? (
          <Tippy content={data.comments} zIndex={10004}>
            <span
              style={{
                cursor: "pointer",
                color: "#007c16",
              }}
            >
              <Icon icon={messageCircle} size={28} />
            </span>
          </Tippy>
        ) : (
          ""
        )}
      </td>
      <td className="icons-td">
        <div className="actions-icon-container">
          <Tippy content="More Details" zIndex={10004}>
            <span onClick={() => handleMoreDetailsPopup(data)}>
              <Icon icon={basic_todo_txt} size={20} />
            </span>
          </Tippy>

          <Tippy content="Schedule" zIndex={10004}>
            <span onClick={() => handleSchedulePopup(data.tagNumber)}>
              <Icon icon={basic_calendar} size={20} />
            </span>
          </Tippy>

          <Tippy content="Edit" zIndex={10004}>
            <span
              style={{
                pointerEvents: data.status === "C" ? "none" : "auto",
                opacity: data.status === "C" ? 0.5 : 1,
              }}
              onClick={() => {
                handleEditECheckPopup(data);
              }}
            >
              <Icon icon={software_pencil} size={20} />
            </span>
          </Tippy>

          <Tippy content="Add Payment" zIndex={10004}>
            <span
              style={{
                pointerEvents:
                  data.status === "A" && data.billingPeriod !== "One Time"
                    ? "auto"
                    : "none",
                opacity:
                  data.status === "A" && data.billingPeriod !== "One Time"
                    ? 1
                    : 0.5,
              }}
              onClick={() => {
                handleAddPaymentPopup(data);
              }}
            >
              <Icon icon={basic_book_pen} size={20} />
            </span>
          </Tippy>

          <Tippy content="Close" zIndex={10004}>
            <span
              style={{
                pointerEvents: data.status === "A" ? "auto" : "none",
                opacity: data.status === "A" ? 1 : 0.5,
              }}
              onClick={() => {
                handleCloseECheckPopup(data);
              }}
            >
              <Icon icon={basic_trashcan_remove} size={20} />
            </span>
          </Tippy>
          <Tippy content="Copy Record" zIndex={10004}>
            <span
              onClick={() => {
                setNewECheckTransactionPopup(true);
                setCopiedObj(data);
                
                // Call setMakeDisabled(false) only if setMakeDisabled exists
                if (typeof setMakeDisabled === "function") {
                  setMakeDisabled(false);
                }
              }}
            >
              <Icon icon={copy} size={20} />
            </span>
          </Tippy>
        </div>
      </td>
    </tr>
  );
};

export default CardDataTable;
