/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Tippy from "@tippyjs/react";
import chequeSample from "../../Images/cheque_sample.jpg";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { info } from "react-icons-kit/feather/info";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import Select from "react-select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearBankingDetailsStates,
  clearUpdateBankingDetailsStates,
  filterListViewRecords,
  getBankingDetails,
  readListViewData,
  updateBankingDetails,
  clearTransitNumbersStates,
  getTransitNumbers,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 100001, // Set the z-index high enough to appear above all elements
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// custom text field
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
  & .Mui-disabled {
    background-color: #f5f5f5;
  }
`;

const UpdateBankingDetailsPopup = ({
  token,
  setBankingDetailsPopup,
  bankingDetailsObj,
  instituteOptions,
  listViewFilteredParams,
  setListViewCurrentPage,
  setListViewPerPage,
  setListViewPageSize,
  setListViewTotalPages,
  donorSpecific,
  donorSpecificEmail,
  triggerScroll,
}) => {
  // redux state
  const {
    updateBankingDetailsLoading,
    updateBankingDetailsData,
    bankingDetailsLoading,
    bankingDetailsData,
    transitNumbersLoading,
    transitNumbersData,
    transitNumbersError,
  } = useSelector((state) => state.eCheck);

  // close modal
  const handleCloseModal = () => {
    if (!updateBankingDetailsLoading) {
      setBankingDetailsPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // account holder name
  const [accountHolderName, setAccountHolderName] = useState(
    bankingDetailsObj.accountHolderName
  );

  // browse step
  const [browseStep, setBrowseStep] = useState(false);

  // selected object
  const [selectedObj, setSelectedObj] = useState(null);

  // clear redux state on component mount
  useEffect(() => {
    dispatch(clearBankingDetailsStates());
    dispatch(clearUpdateBankingDetailsStates());
    dispatch(clearTransitNumbersStates());
  }, []);

  // handle search email
  const handleSearchEmail = () => {
    if (
      bankingDetailsObj.email &&
      !updateBankingDetailsLoading &&
      !bankingDetailsData
    ) {
      dispatch(
        getBankingDetails({
          token,
          email: bankingDetailsObj.email,
        })
      ).then((res) => {
        if (res.payload.axiosError === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    }
  };

  // handle confirm
  const handleConfirm = () => {
    setBrowseStep(false);
    if (selectedObj) {
      setAccountHolderName(selectedObj.accountHolderName);
      setSelectedInstitute(selectedObj.instituteNumber);
      setTransitNumber(selectedObj.transitNumber);
      setAccountNumber(selectedObj.accountNumber);
    }
  };

  // insititute
  const [selectedInstitute, setSelectedInstitute] = useState("");

  // updating selected institute
  useEffect(() => {
    const wantedInstitute = instituteOptions.find(
      (option) => option.value === bankingDetailsObj.instituteNumber
    );
    if (wantedInstitute !== undefined) {
      setSelectedInstitute(wantedInstitute.value);
    } else {
      setSelectedInstitute("");
    }

    // populate combobox
    dispatch(
      getTransitNumbers({
        token,
        instituteNumber: bankingDetailsObj.instituteNumber,
      })
    );
  }, []);

  // transit number
  const [transitNumber, setTransitNumber] = useState(
    bankingDetailsObj.transitNumber
  );
  const handleTransitNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 10.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setTransitNumber(sanitizedInput);
  };

  // a/c number
  const [accountNumber, setAccountNumber] = useState(
    bankingDetailsObj.accountNumber
  );
  const handleAccountNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 20.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 20);
    setAccountNumber(sanitizedInput);
  };

  // submit event
  const handleUpdateBankingDetails = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      scheduleTag: bankingDetailsObj.scheduleTag,
      accountHolderName,
      instituteNumber: selectedInstitute,
      transitNumber,
      accountNumber: String(accountNumber),
    };
    dispatch(updateBankingDetails(singleObj)).then((res) => {
      if (res.payload.successMsg) {
        setBankingDetailsPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // requery data
        if (listViewFilteredParams) {
          dispatch(
            filterListViewRecords({
              token,
              page: 1,
              pageSize: listViewFilteredParams.pageSize,
              order: listViewFilteredParams.order,
              apiData: donorSpecific
                ? {
                    ...listViewFilteredParams.apiData,
                    email: donorSpecificEmail,
                  }
                : listViewFilteredParams.apiData,
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setListViewCurrentPage(res.payload.links.currentPage);
              setListViewPerPage(res.payload.links.perPage);
              setListViewPageSize(res.payload.links.perPage);
              setListViewTotalPages(res.payload.links.lastPage);

              if (donorSpecific) {
                triggerScroll();
              }
            }
          });
        } else {
          // get all listview data
          if (donorSpecific) {
            dispatch(
              filterListViewRecords({
                token,
                page: 1,
                pageSize: "20",
                order: "asc",
                apiData: {
                  email: donorSpecificEmail,
                  by: "email",
                },
              })
            ).then((res) => {
              if (
                res.error &&
                res.error.message === "Request failed with status code 401"
              ) {
                localStorage.removeItem("user");
                navigate("/login");
              } else {
                setListViewCurrentPage(res.payload.links.currentPage);
                setListViewPerPage(res.payload.links.perPage);
                setListViewPageSize(res.payload.links.perPage);
                setListViewTotalPages(res.payload.links.lastPage);

                triggerScroll();
              }
            });
          } else {
            dispatch(
              readListViewData({
                token,
                page: 1,
                pageSize: "20",
                order: "asc",
                by: "email",
              })
            ).then((res) => {
              if (
                res.error &&
                res.error.message === "Request failed with status code 401"
              ) {
                localStorage.removeItem("user");
                navigate("/login");
              } else {
                setListViewCurrentPage(res.payload.links.currentPage);
                setListViewPerPage(res.payload.links.perPage);
                setListViewPageSize(res.payload.links.perPage);
                setListViewTotalPages(res.payload.links.lastPage);
              }
            });
          }
        }
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className={`update-widgets-modal w-500 ${
            browseStep && "media extra-width"
          }`}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          {!browseStep ? (
            <>
              <div className="update-widgets-modal-header">
                <div>Update Banking Details</div>
                <div
                  className="cancel-widget-update-icon"
                  onClick={handleCloseModal}
                >
                  <Icon icon={x} size={16} />
                </div>
              </div>
              <div className="update-widgets-modal-body">
                <form onSubmit={handleUpdateBankingDetails}>
                  {bankingDetailsObj?.email && (
                    <MDBRow className="mb-3">
                      <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                        <div className="banking-details-instruction">
                          Click on the email address below to view the
                          associated banking details.
                        </div>
                        <button
                          type="button"
                          className="associate-email-btn"
                          style={{
                            pointerEvents:
                              bankingDetailsObj.status !== "P" &&
                              bankingDetailsObj.email &&
                              !updateBankingDetailsLoading
                                ? "auto"
                                : "none",
                            opacity:
                              bankingDetailsObj.status !== "P" &&
                              bankingDetailsObj.email &&
                              !updateBankingDetailsLoading
                                ? 1
                                : 0.5,
                          }}
                          onClick={() => {
                            if (
                              bankingDetailsObj.status !== "P" &&
                              bankingDetailsObj.email &&
                              !updateBankingDetailsLoading
                            ) {
                              setBrowseStep(true);
                              handleSearchEmail();
                            }
                          }}
                        >
                          {bankingDetailsObj.email}
                        </button>
                      </MDBCol>
                    </MDBRow>
                  )}
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label htmlFor="accountHolderName">Account Title</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder="Account Title"
                        required
                        value={accountHolderName}
                        onChange={(e) => setAccountHolderName(e.target.value)}
                        readOnly={bankingDetailsObj.status === "P"}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label htmlFor="instituteName">Institute Name</label>
                      <Select
                        styles={styles}
                        required
                        menuPlacement="bottom"
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        value={instituteOptions.find(
                          (option) => option.value === selectedInstitute
                        )}
                        onChange={(option) => {
                          setSelectedInstitute(option.value);
                          setTransitNumber("");
                          dispatch(
                            getTransitNumbers({
                              token,
                              instituteNumber: option.value,
                            })
                          );
                        }}
                        options={instituteOptions}
                        isDisabled={
                          bankingDetailsObj.status === "P" ||
                          transitNumbersLoading
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label htmlFor="transitNumber">
                        Transit#
                        <Tippy
                          content={<img src={chequeSample} alt="cheque" />}
                          zIndex={100001}
                          placement="top"
                          maxWidth={100 + "%"}
                        >
                          <span
                            style={{
                              marginLeft: 5 + "px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon icon={info} size={18} />
                          </span>
                        </Tippy>
                      </label>
                      {transitNumbersError ||
                      !transitNumbersData ||
                      transitNumbersData.length <= 0 ? (
                        <CustomTextField
                          type="text"
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                          size="small"
                          value={transitNumber}
                          disabled={
                            bankingDetailsObj.status === "P" ||
                            transitNumbersLoading
                              ? true
                              : false
                          }
                          onChange={handleTransitNumber}
                          title=""
                          required
                          placeholder="Transit#"
                          helperText={
                            transitNumber !== "" && transitNumber.length < 5
                              ? `Transit Number must be at least 5 numeric digits`
                              : ""
                          }
                          error={
                            transitNumber !== "" && transitNumber.length < 5
                          }
                        />
                      ) : (
                        <Autocomplete
                          freeSolo
                          fullWidth
                          options={transitNumbersData}
                          getOptionLabel={(option) => option.address}
                          onChange={(event, value) => {
                            if (value) {
                              setTransitNumber(value.transitNumber);
                            } else {
                              setTransitNumber("");
                            }
                          }}
                          // onInputChange={}
                          inputValue={transitNumber}
                          renderOption={(props, option) => (
                            <div {...props} className="list-div">
                              {option.address}
                            </div>
                          )}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              autoComplete="off"
                              size="small"
                              title=""
                              required
                              placeholder="Transit#"
                              value={transitNumber}
                              disabled={
                                bankingDetailsObj.status === "P" ||
                                transitNumbersLoading
                                  ? true
                                  : false
                              }
                              onChange={handleTransitNumber}
                              helperText={
                                transitNumber !== "" && transitNumber.length < 5
                                  ? `Transit Number must be at least 5 numeric digits`
                                  : ""
                              }
                              error={
                                transitNumber !== "" && transitNumber.length < 5
                              }
                            />
                          )}
                        />
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label htmlFor="accountNumber">
                        Account#
                        <Tippy
                          content={<img src={chequeSample} alt="cheque" />}
                          zIndex={100001}
                          placement="top-end"
                          maxWidth={100 + "%"}
                        >
                          <span
                            style={{
                              marginLeft: 5 + "px",
                              cursor: "pointer",
                            }}
                          >
                            <Icon icon={info} size={18} />
                          </span>
                        </Tippy>
                      </label>
                      <CustomTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        value={accountNumber}
                        onChange={handleAccountNumber}
                        title=""
                        required
                        placeholder="Account#"
                        helperText={
                          accountNumber !== "" && accountNumber.length < 7
                            ? `Account Number must be at least 7 numeric digits`
                            : ""
                        }
                        error={accountNumber !== "" && accountNumber.length < 7}
                        disabled={bankingDetailsObj.status === "P"}
                      />
                    </MDBCol>
                  </MDBRow>

                  {updateBankingDetailsData &&
                    bankingDetailsObj.scheduleTag ===
                      updateBankingDetailsData.scheduleTag &&
                    (updateBankingDetailsData.apiError ||
                      updateBankingDetailsData.axiosError) && (
                      <div className="error-msg">
                        {updateBankingDetailsData.apiError ? (
                          <>
                            {typeof updateBankingDetailsData.apiError ===
                            "object" ? (
                              // Case 1: If apiError is an object
                              Object.keys(
                                updateBankingDetailsData.apiError
                              ).map((field, index) => (
                                <p key={index} style={{ marginBottom: 0 }}>
                                  {updateBankingDetailsData.apiError[field][0]}
                                </p>
                              ))
                            ) : (
                              // Case 2: If apiError is a simple string
                              <>{updateBankingDetailsData.apiError}</>
                            )}
                          </>
                        ) : (
                          <>{updateBankingDetailsData.axiosError}</>
                        )}
                      </div>
                    )}

                  <div className="submit-and-cancel-div">
                    <button
                      type="button"
                      className="cancel"
                      onClick={handleCloseModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="submit addModal"
                      disabled={updateBankingDetailsLoading}
                      style={{
                        opacity:
                          accountNumber.length < 7 || transitNumber.length < 5
                            ? 0.5
                            : 1,
                        pointerEvents:
                          accountNumber.length < 7 || transitNumber.length < 5
                            ? "none"
                            : "auto",
                      }}
                    >
                      {updateBankingDetailsLoading ? (
                        <MetroSpinner
                          loading={updateBankingDetailsLoading}
                          size={22}
                          color="#fff"
                        />
                      ) : (
                        "UPDATE"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="update-widgets-modal-header">
                <div>Browse Banking Details</div>
                <div
                  className="cancel-widget-update-icon"
                  onClick={() => {
                    if (!bankingDetailsLoading) {
                      setBrowseStep(false);
                      setSelectedObj(null);
                    }
                  }}
                >
                  <Icon icon={x} size={16} />
                </div>
              </div>
              <div className="update-widgets-modal-body">
                {bankingDetailsLoading ? (
                  <div
                    style={{
                      padding: 50 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MetroSpinner
                      size={30}
                      color="#007C16"
                      loading={bankingDetailsLoading}
                    />
                  </div>
                ) : (
                  <>
                    {bankingDetailsData &&
                    (bankingDetailsData.apiError ||
                      bankingDetailsData.axiosError) ? (
                      <div
                        style={{
                          padding: 50 + "px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="error-msg">
                          {bankingDetailsData.apiError ? (
                            <>
                              {typeof bankingDetailsData.apiError ===
                              "object" ? (
                                // Case 1: If apiError is an object
                                Object.keys(bankingDetailsData.apiError).map(
                                  (field, index) => (
                                    <p key={index} style={{ marginBottom: 0 }}>
                                      {bankingDetailsData.apiError[field][0]}
                                    </p>
                                  )
                                )
                              ) : (
                                // Case 2: If apiError is a simple string
                                <>{bankingDetailsData.apiError}</>
                              )}
                            </>
                          ) : (
                            <>{bankingDetailsData.axiosError}</>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        {bankingDetailsData?.data?.length > 0 ? (
                          <>
                            <h5 style={{ fontWeight: 600 }}>
                              Select a row below to autocomplete banking
                              details:
                            </h5>
                            <div className="transactions-table">
                              <MDBTable align="middle">
                                <MDBTableHead
                                  className="thead"
                                  style={{ zIndex: 0 }}
                                >
                                  <tr>
                                    <th scope="col">Account Title</th>
                                    <th scope="col">Institute Name</th>
                                    <th scope="col">Institute#</th>
                                    <th scope="col">Transit#</th>
                                    <th scope="col">Account#</th>
                                  </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                  {bankingDetailsData.data.map(
                                    (data, index) => {
                                      return (
                                        <tr
                                          style={{
                                            backgroundColor:
                                              selectedObj === data
                                                ? "rgba(0, 124, 22, 0.1)"
                                                : "#fff",
                                            cursor: "pointer",
                                          }}
                                          key={index}
                                          onClick={() => {
                                            setSelectedObj(data);
                                          }}
                                        >
                                          <td>
                                            <p
                                              className="fw-normal mb-1"
                                              style={{
                                                fontSize: "16px",
                                              }}
                                            >
                                              {data.accountHolderName}
                                            </p>
                                          </td>
                                          <td>
                                            <p
                                              className="fw-normal mb-1"
                                              style={{
                                                fontSize: "16px",
                                              }}
                                            >
                                              {data.institutionName}
                                            </p>
                                          </td>
                                          <td>
                                            <p
                                              className="fw-normal mb-1"
                                              style={{
                                                fontSize: "16px",
                                              }}
                                            >
                                              {data.instituteNumber}
                                            </p>
                                          </td>
                                          <td>
                                            <p
                                              className="fw-normal mb-1"
                                              style={{
                                                fontSize: "16px",
                                              }}
                                            >
                                              {data.transitNumber}
                                            </p>
                                          </td>
                                          <td>
                                            <p
                                              className="fw-normal mb-1"
                                              style={{
                                                fontSize: "16px",
                                              }}
                                            >
                                              {data.accountNumber}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </MDBTableBody>
                              </MDBTable>
                            </div>
                          </>
                        ) : (
                          <div
                            className="no-data-found-div"
                            style={{ padding: 50 + "px" }}
                          >
                            <Tippy content="No Data Found" zIndex={100005}>
                              <img src={noDataFound} alt="No Data Found" />
                            </Tippy>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => {
                      if (!bankingDetailsLoading) {
                        setBrowseStep(false);
                        setSelectedObj(null);
                      }
                    }}
                  >
                    BACK
                  </button>
                  {bankingDetailsData?.data?.length > 0 && selectedObj && (
                    <button
                      type="button"
                      className="submit addModal"
                      onClick={handleConfirm}
                    >
                      CONFIRM
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UpdateBankingDetailsPopup;
