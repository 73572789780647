import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  filterTransactionRecords,
  getTransactions,
  sendEmail,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { basic_todo_txt } from "react-icons-kit/linea/basic_todo_txt";
import { basic_mail } from "react-icons-kit/linea/basic_mail";
import { basic_paperplane } from "react-icons-kit/linea/basic_paperplane";
import { basic_trashcan_remove } from "react-icons-kit/linea/basic_trashcan_remove";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { basic_sheet_pencil } from "react-icons-kit/linea/basic_sheet_pencil";
import { ecommerce_wallet } from "react-icons-kit/linea/ecommerce_wallet";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import { toast } from "react-hot-toast";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const TransactionsTable = ({
  token,
  filteredParams,
  setLoadings,
  currentPage,
  totalPages,
  perPage,
  setCurrentPage,
  setTotalPages,
  setPerPage,
  setPageSize,
  handleEmailChangePopup,
  handleTransactionDetailsPopup,
  handleUpdateCampaignPopup,
  handleDeleteTransactionsPopup,
  handleEditTransactionPopup,
  handleTransactionTypePopup,
  transactionTypePopup,
  donorSpecific,
  email,
}) => {
  // redux state
  const { transactionsData, transactionsError } = useSelector(
    (state) => state.transactions
  );

  // redux state
  const { sendEmailLoading, sendEmailData } = useSelector(
    (state) => state.transactions
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterTransactionRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        // get donor transactions
        dispatch(
          filterTransactionRecords({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            apiData: {
              email,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getTransactions({
            token,
            page: 1,
            pageSize: value,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterTransactionRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        // get donor transactions
        dispatch(
          filterTransactionRecords({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            apiData: {
              email,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getTransactions({
            token,
            page: value,
            pageSize: perPage,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // email change event
  const handleSendEmail = (transaction) => {
    dispatch(
      sendEmail({
        token,
        tagNumber: transaction.transactionTag,
      })
    ).then((res) => {
      if (!res.payload.error) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (res.payload.error === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <>
      {transactionsError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {transactionsError}
        </div>
      ) : (
        <>
          {transactionsData && transactionsData.data.transactions.length > 0 ? (
            <>
              <div className="transactions-table">
                <MDBTable align="middle">
                  <MDBTableHead className="thead">
                    <tr>
                      {donorSpecific === undefined && (
                        <th scope="col">Email</th>
                      )}
                      <th scope="col">Campaign</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Invoice</th>
                      <th scope="col">Date</th>
                      <th scope="col">Channel Name</th>
                      <th scope="col">Account#</th>
                      <th scope="col">Tender Type</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {transactionsData.data.transactions.map(
                      (transaction, index) => (
                        <tr
                          key={transaction.transactionTag}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#fff" : "#fafafa",
                          }}
                        >
                          {donorSpecific === undefined && (
                            <td>
                              <p
                                className="fw-normal mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {transaction.email ? (
                                  <>
                                    {transaction.donor ? (
                                      <Tippy
                                        content={`${transaction.donor.firstName} ${transaction.donor.lastName}`}
                                        zIndex={10004}
                                      >
                                        <Link
                                          to={`/donors/${transaction.donor.firstName}_${transaction.donor.lastName}_${transaction.donor.tagNumber}`}
                                          className="donor-hyper-link"
                                        >
                                          {transaction.email}
                                        </Link>
                                      </Tippy>
                                    ) : (
                                      <>{transaction.email}</>
                                    )}
                                  </>
                                ) : (
                                  <>Anonymous</>
                                )}
                              </p>
                            </td>
                          )}
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{ fontSize: "16px" }}
                            >
                              {transaction.campaign}
                            </p>
                          </td>
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{
                                fontSize: "16px",
                                textAlign: "right",
                              }}
                            >
                              {transactionsData?.data?.currencySymbol}
                              {transaction.approvedAmount}
                            </p>
                          </td>
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{ fontSize: "16px" }}
                            >
                              {transaction.invoice}
                            </p>
                          </td>
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{ fontSize: "16px" }}
                            >
                              {transaction.transactionDate}
                            </p>
                          </td>
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{ fontSize: "16px" }}
                            >
                              {transaction.nodeName}
                            </p>
                          </td>
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{ fontSize: "16px" }}
                            >
                              {transaction.cardAccount}
                            </p>
                          </td>
                          <td>
                            <p
                              className="fw-normal mb-1"
                              style={{ fontSize: "16px" }}
                            >
                              {transaction.cardType.toUpperCase()}
                            </p>
                          </td>
                          <td className="icons-td">
                            <div className="actions-icon-container">
                              {/* update email */}
                              {donorSpecific === undefined && (
                                <Tippy content="Update Email" zIndex={10004}>
                                  <span
                                    onClick={() =>
                                      handleEmailChangePopup(transaction)
                                    }
                                  >
                                    <Icon icon={basic_mail} size={20} />
                                  </span>
                                </Tippy>
                              )}

                              {/* send email */}
                              {transaction.email ? (
                                <>
                                  {sendEmailData &&
                                  sendEmailData.tagNumber ===
                                    transaction.transactionTag &&
                                  sendEmailLoading === true &&
                                  !transactionTypePopup ? (
                                    <span className="loader-span">
                                      <MetroSpinner
                                        loading={sendEmailLoading}
                                        color="#007c16"
                                        size={20}
                                      />
                                    </span>
                                  ) : (
                                    <Tippy content="Send Email" zIndex={10004}>
                                      <span
                                        onClick={() =>
                                          handleSendEmail(transaction)
                                        }
                                      >
                                        <Icon
                                          icon={basic_paperplane}
                                          size={20}
                                        />
                                      </span>
                                    </Tippy>
                                  )}
                                </>
                              ) : (
                                <Tippy
                                  content="Email Not Available"
                                  zIndex={10004}
                                >
                                  <span>
                                    <Icon
                                      icon={basic_paperplane}
                                      size={20}
                                      style={{
                                        cursor: "not-allowed",
                                        opacity: 0.5,
                                      }}
                                    />
                                  </span>
                                </Tippy>
                              )}

                              {/* transaction details */}
                              <Tippy
                                content="Transaction Details"
                                zIndex={10004}
                              >
                                <span
                                  onClick={() =>
                                    handleTransactionDetailsPopup(transaction)
                                  }
                                >
                                  <Icon icon={basic_todo_txt} size={20} />
                                </span>
                              </Tippy>

                              {/* update campaign */}
                              <Tippy content="Update Campaign" zIndex={10004}>
                                <span
                                  onClick={() =>
                                    handleUpdateCampaignPopup(transaction)
                                  }
                                >
                                  <Icon icon={basic_sheet_pencil} size={20} />
                                </span>
                              </Tippy>

                              {/* edit transaction */}
                              {transaction.canEdit ? (
                                <Tippy
                                  content="Edit Transaction"
                                  zIndex={10004}
                                >
                                  <span
                                    onClick={() =>
                                      handleEditTransactionPopup(transaction)
                                    }
                                  >
                                    <Icon icon={software_pencil} size={20} />
                                  </span>
                                </Tippy>
                              ) : (
                                <Tippy
                                  content="This transaction cannot be edited"
                                  zIndex={10004}
                                >
                                  <span>
                                    <Icon
                                      icon={software_pencil}
                                      size={20}
                                      style={{
                                        cursor: "not-allowed",
                                        opacity: 0.5,
                                      }}
                                    />
                                  </span>
                                </Tippy>
                              )}

                              {/* delete transaction */}
                              {transaction.canDelete ? (
                                <Tippy
                                  content="Delete Transaction"
                                  zIndex={10004}
                                >
                                  <span
                                    onClick={() =>
                                      handleDeleteTransactionsPopup(transaction)
                                    }
                                  >
                                    <Icon
                                      icon={basic_trashcan_remove}
                                      size={20}
                                    />
                                  </span>
                                </Tippy>
                              ) : (
                                <Tippy
                                  content="This transaction cannot be deleted"
                                  zIndex={10004}
                                >
                                  <span>
                                    <Icon
                                      icon={basic_trashcan_remove}
                                      size={20}
                                      style={{
                                        cursor: "not-allowed",
                                        opacity: 0.5,
                                      }}
                                    />
                                  </span>
                                </Tippy>
                              )}

                              {/* refund transaction */}
                              {transaction.canRefund ? (
                                <Tippy content="Refund" zIndex={10004}>
                                  <span
                                    onClick={() =>
                                      handleTransactionTypePopup(transaction)
                                    }
                                  >
                                    <Icon icon={ecommerce_wallet} size={20} />
                                  </span>
                                </Tippy>
                              ) : (
                                <Tippy
                                  content="This transaction is not refundable"
                                  zIndex={10004}
                                >
                                  <span>
                                    <Icon
                                      icon={ecommerce_wallet}
                                      size={20}
                                      style={{
                                        cursor: "not-allowed",
                                        opacity: 0.5,
                                      }}
                                    />
                                  </span>
                                </Tippy>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </MDBTableBody>
                </MDBTable>
              </div>

              {/* pagination */}
              <div className="transactions-pagination">
                <div className="per-page-and-pagination">
                  <div className="per-page">
                    <label>Records per page</label>
                    <Select
                      value={perPageOptions.find(
                        (option) => option.value === String(perPage)
                      )}
                      onChange={(option) => handlePerPage(option.value)}
                      options={perPageOptions}
                      styles={styles}
                      menuPlacement="top"
                    />
                  </div>
                  <ResponsivePagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Transactions Found">
                <img src={noDataFound} alt="No Transactions Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TransactionsTable;
