/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Tippy from "@tippyjs/react";
import chequeSample from "../../Images/cheque_sample.jpg";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { info } from "react-icons-kit/feather/info";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MetroSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addNewBankAccount,
  clearAddNewBankAccountStates,
  clearTransitNumbersStates,
  getTransitNumbers,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 100001, // Set the z-index high enough to appear above all elements
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// custom text field
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
  & .Mui-disabled {
    background-color: #f5f5f5;
  }
`;

const AddBankAccount = ({
  token,
  setAddBankAccountPopup,
  instituteOptions,
  email,
}) => {
  // redux state
  const {
    addNewBankAccountLoading,
    addNewBankAccountData,
    transitNumbersLoading,
    transitNumbersData,
    transitNumbersError,
  } = useSelector((state) => state.eCheck);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear prev addNewBankAccount and get transit numbers redux state on component mount
  useEffect(() => {
    dispatch(clearAddNewBankAccountStates());
    dispatch(clearTransitNumbersStates());
  }, []);

  // close modal
  const handleCloseModal = () => {
    if (!addNewBankAccountLoading) {
      setAddBankAccountPopup(false);
    }
  };

  // account holder name
  const [accountHolderName, setAccountHolderName] = useState("");

  // insititute
  const [selectedInstitute, setSelectedInstitute] = useState("");

  // transit number
  const [transitNumber, setTransitNumber] = useState("");
  const handleTransitNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 10.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setTransitNumber(sanitizedInput);
  };

  // a/c number
  const [accountNumber, setAccountNumber] = useState("");
  const handleAccountNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 20.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 20);
    setAccountNumber(sanitizedInput);
  };

  // add new bank account event
  const handleAddNewBankAccount = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      email,
      accountHolderName,
      instituteNumber: selectedInstitute,
      transitNumber,
      accountNumber: String(accountNumber),
    };

    dispatch(addNewBankAccount(singleObj)).then((res) => {
      if (res.payload.successMsg) {
        setAddBankAccountPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal w-500"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>New Bank Account</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleAddNewBankAccount}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="accountHolderName">Account Title</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder="Account Title"
                    required
                    value={accountHolderName}
                    onChange={(e) => setAccountHolderName(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="instituteName">Institute Name</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="bottom"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    value={instituteOptions.find(
                      (option) => option.value === selectedInstitute
                    )}
                    onChange={(option) => {
                      setSelectedInstitute(option.value);
                      setTransitNumber("");
                      dispatch(
                        getTransitNumbers({
                          token,
                          instituteNumber: option.value,
                        })
                      );
                    }}
                    options={instituteOptions}
                    isDisabled={transitNumbersLoading ? true : false}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="transitNumber">
                    Transit#
                    <Tippy
                      content={<img src={chequeSample} alt="cheque" />}
                      zIndex={100001}
                      placement="top"
                      maxWidth={100 + "%"}
                    >
                      <span
                        style={{
                          marginLeft: 5 + "px",
                          cursor: "pointer",
                        }}
                      >
                        <Icon icon={info} size={18} />
                      </span>
                    </Tippy>
                  </label>
                  {transitNumbersError ||
                  !transitNumbersData ||
                  transitNumbersData.length <= 0 ? (
                    <CustomTextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      value={transitNumber}
                      disabled={transitNumbersLoading ? true : false}
                      onChange={handleTransitNumber}
                      title=""
                      required
                      placeholder="Transit#"
                      helperText={
                        transitNumber !== "" && transitNumber.length < 5
                          ? `Transit Number must be at least 5 numeric digits`
                          : ""
                      }
                      error={transitNumber !== "" && transitNumber.length < 5}
                    />
                  ) : (
                    <Autocomplete
                      freeSolo
                      fullWidth
                      options={transitNumbersData}
                      getOptionLabel={(option) => option.address}
                      onChange={(event, value) => {
                        if (value) {
                          setTransitNumber(value.transitNumber);
                        } else {
                          setTransitNumber("");
                        }
                      }}
                      // onInputChange={}
                      inputValue={transitNumber}
                      renderOption={(props, option) => (
                        <div {...props} className="list-div">
                          {option.address}
                        </div>
                      )}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                          size="small"
                          title=""
                          required
                          placeholder="Transit#"
                          value={transitNumber}
                          disabled={
                            transitNumbersLoading
                              ? true
                              : false
                          }
                          onChange={handleTransitNumber}
                          helperText={
                            transitNumber !== "" && transitNumber.length < 5
                              ? `Transit Number must be at least 5 numeric digits`
                              : ""
                          }
                          error={
                            transitNumber !== "" && transitNumber.length < 5
                          }
                        />
                      )}
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="accountNumber">
                    Account#
                    <Tippy
                      content={<img src={chequeSample} alt="cheque" />}
                      zIndex={100001}
                      placement="top-end"
                      maxWidth={100 + "%"}
                    >
                      <span
                        style={{
                          marginLeft: 5 + "px",
                          cursor: "pointer",
                        }}
                      >
                        <Icon icon={info} size={18} />
                      </span>
                    </Tippy>
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={accountNumber}
                    onChange={handleAccountNumber}
                    title=""
                    required
                    placeholder="Account#"
                    helperText={
                      accountNumber !== "" && accountNumber.length < 7
                        ? `Account Number must be at least 7 numeric digits`
                        : ""
                    }
                    error={accountNumber !== "" && accountNumber.length < 7}
                  />
                </MDBCol>
              </MDBRow>

              {addNewBankAccountData &&
                (addNewBankAccountData.apiError ||
                  addNewBankAccountData.axiosError) && (
                  <div className="error-msg">
                    {addNewBankAccountData.apiError ? (
                      <>
                        {typeof addNewBankAccountData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(addNewBankAccountData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {addNewBankAccountData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{addNewBankAccountData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{addNewBankAccountData.axiosError}</>
                    )}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={addNewBankAccountLoading}
                  style={{
                    opacity:
                      accountNumber.length < 7 || transitNumber.length < 5
                        ? 0.5
                        : 1,
                    pointerEvents:
                      accountNumber.length < 7 || transitNumber.length < 5
                        ? "none"
                        : "auto",
                  }}
                >
                  {addNewBankAccountLoading ? (
                    <MetroSpinner
                      loading={addNewBankAccountLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddBankAccount;
