/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../CSS/Sidebar/Reports.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllReports,
  getUniqueReport,
  setDisplayValuesForSelect,
} from "../../Store/Slices/Sidebar/Reports/ReportsSlice";
import { MetroSpinner } from "react-spinners-kit";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import DatePicker from "react-datepicker";
import { MDBCheckbox } from "mdb-react-ui-kit";
import hostName from "../../config";
import axios from "axios";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const ReportsComponent = ({ token }) => {
  // redux state
  const {
    reportsLoading,
    reportsData,
    reportsError,
    reportsUniqueLoading,
    reportsUniqueData,
    reportsUniqueError,
    reportTag,
    reportLabel,
    reportDescription,
  } = useSelector((state) => state.reports);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get all reports
  useEffect(() => {
    dispatch(getAllReports(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // changing the response in to label, value description
  const [listOfValuesData, setListOfValuesData] = useState([]);
  useEffect(() => {
    if (reportsData && reportsData.length > 0) {
      const data = reportsData.map((item) => {
        return {
          value: item.tagNumber,
          label: item.name,
          description: item.description,
        };
      });
      setListOfValuesData(data);
    } else {
      setListOfValuesData([]);
    }
  }, [reportsData]);

  // main loadings state
  const [loadings, setLoadings] = useState(false);
  // checking if any loading is true
  const allLoadingStates = [reportsUniqueLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadingStates.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadingStates]);

  // select change
  const handleChange = (option) => {
    if (option.value !== reportTag) {
      setLoadings(true);
      const tag = option.value;
      dispatch(
        setDisplayValuesForSelect({
          reportTag: tag,
          reportLabel: option.label,
          reportDescription: option.description,
        })
      );
      dispatch(getUniqueReport({ token, tag }))
        .unwrap()
        .catch((error) => {
          if (error.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  };

  // states for listBoxes, textBoxes, and date
  // listBoxes
  const [downloadFormat, setDownloadFormat] = useState("");
  const [includeStats, setIncludeStats] = useState("");

  // textBoxes
  const [numberOfMonths, setNumberOfMonths] = useState("");
  const [postalZipCode, setPostalZipCode] = useState("");
  const [radius, setRadius] = useState("");
  const [tagNumber, setTagNumber] = useState("");

  // date
  const [formData, setFormData] = useState({});

  // selected status
  const [selectedStatus, setSelectedStatus] = useState([]);

  // reset states and update defaultValue for above states
  useEffect(() => {
    // reset states
    setDownloadFormat("");
    setIncludeStats("");
    setNumberOfMonths("");
    setPostalZipCode("");
    setRadius("");
    setTagNumber("");
    setFormData({});
    setSelectedStatus([]);

    reportsUniqueData?.length > 0 &&
      reportsUniqueData.forEach((report) => {
        // downloadFormat
        if (report.name === "pFormat" && report.defaultValue) {
          setDownloadFormat(report.defaultValue);
        }

        // includeStats
        if (report.name === "pStats" && report.defaultValue) {
          setIncludeStats(report.defaultValue);
        }

        // numberOfMonths
        if (report.name === "pMonths" && report.defaultValue) {
          setNumberOfMonths(report.defaultValue);
        }

        // postalZipCode
        if (report.name === "pPostalZipCode" && report.defaultValue) {
          setPostalZipCode(report.defaultValue);
        }

        // radius
        if (report.name === "pRadius" && report.defaultValue) {
          setRadius(report.defaultValue);
        }

        // tagNumber
        if (report.name === "pTransactionTag" && report.defaultValue) {
          setTagNumber(report.defaultValue);
        }

        // date/formData
        let initialFormData = {};
        if (report.control === "date" && report.defaultValue) {
          const parsedDate = new Date();
          initialFormData[report.name] = parsedDate;
          setFormData(initialFormData);
        }
      });
  }, [reportsUniqueData]);

  // handle checkbox change
  const handleCheckboxChange = (value, isChecked) => {
    if (isChecked) {
      // If the checkbox is checked, add the value to the selectedStatus array
      setSelectedStatus((prevSelectedStatus) => [...prevSelectedStatus, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the selectedStatus array
      setSelectedStatus((prevSelectedStatus) =>
        prevSelectedStatus.filter((item) => item !== value)
      );
    }
  };

  // date change
  const handleDateChange = (name, date) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  };

  // handle only allow numbers
  const handleOnlyAllowNumbers = (value, item) => {
    let newValue = value;
    newValue = newValue.replace(/[^0-9]/g, "");
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }

    // numberOfMonths
    if (item.name === "pMonths") {
      setNumberOfMonths(newValue);
    }

    // radius
    if (item.name === "pRadius") {
      setRadius(newValue);
    }

    // tagNumber
    if (item.name === "pTransactionTag") {
      setTagNumber(newValue);
    }
  };

  // handle only allow numbers with negative values
  const handleAllowNumbersWithNegativeValues = (value) => {
    let newValue = value;

    // Allow only digits and a leading negative sign
    newValue = newValue.replace(/[^0-9-]/g, "");

    // Ensure the negative sign is only at the start
    if (newValue.indexOf("-") > 0) {
      newValue = newValue.replace("-", "");
    }

    // Allow the negative sign as intermediate input
    if (newValue === "-") {
      setNumberOfMonths(newValue);
      return;
    }

    // Ensure the value is a valid integer
    if (isNaN(newValue) || !Number.isInteger(Number(newValue))) {
      newValue = 0;
    }

    setNumberOfMonths(newValue);
  };

  // generate report states
  const [previewReportLoading, setPreviewReportLoading] = useState(false);
  const [previewReportError, setPreviewReportError] = useState(null);

  // generate report event
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      token,
      reportTag,
      downloadFormat,
      fromDate: formatDate(formData.pFrom),
      toDate: formatDate(formData.pTo),
      status: selectedStatus,
      numberOfMonths,
      tagNumber,
      postalZipCode,
      radius,
      includeStats,
    };

    setPreviewReportLoading(true);
    setPreviewReportError(null);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${data.token}`,
      },
      responseType: "arraybuffer",
    };

    // Create the URL string with the required parameters
    let url = `${hostName}api/admin/v1/reportHelper/preview?reportTag=${data.reportTag}`;

    // add pFormat if downloadFormat exist (not empty string)
    if (data.downloadFormat) {
      url += `&pFormat=${data.downloadFormat}`;
    }

    // add pFrom if fromDate exist (not null)
    if (data.fromDate) {
      url += `&pFrom=${data.fromDate}`;
    }

    // add pTo if toDate exist (not null)
    if (data.toDate) {
      url += `&pTo=${data.toDate}`;
    }

    // add pStatus if status.length > 0
    if (data.status.length > 0) {
      if (Array.isArray(data.status)) {
        // If status is an array, append multiple pStatus parameters
        data.status.forEach((s) => {
          if (s !== undefined) {
            url += `&pStatus[]=${s}`;
          }
        });
      }
    }

    // add pMonths if numberOfMonths exist (not empty string)
    if (data.numberOfMonths) {
      url += `&pMonths=${data.numberOfMonths}`;
    }

    // add pTransactionTag if tagNumber exist (not empty string)
    if (data.tagNumber) {
      url += `&pTransactionTag=${data.tagNumber}`;
    }

    // add pPostalZipCode if postalZipCode exist (not empty string)
    if (data.postalZipCode) {
      url += `&pPostalZipCode=${data.postalZipCode}`;
    }

    // add pRadius if radius exist (not empty string)
    if (data.radius) {
      url += `&pRadius=${data.radius}`;
    }

    // add pStats if includeStats exist (not empty string)
    if (data.includeStats) {
      url += `&pStats=${data.includeStats}`;
    }

    // Make the API call directly in the component
    axios
      .get(url, axiosConfig)
      .then((response) => {
        // Download the file
        const file = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.setAttribute("download", `${reportLabel}.${downloadFormat}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        // Handle unauthorized error
        if (error && error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setPreviewReportError(error.message);
        }
      })
      .finally(() => {
        setPreviewReportLoading(false);
      });
  };

  return (
    <div className="middle-area reportsComponent">
      <div
        className={`middle-content flex-start ${
          reportsLoading ? "height" : ""
        }`}
      >
        {reportsLoading ? (
          <>
            <div className="full-loading-screen">
              <MetroSpinner
                size={30}
                color="#007C16"
                loading={reportsLoading}
              />
            </div>
          </>
        ) : (
          <>
            {reportsError ? (
              <div className="error-msg" style={{ width: "fit-content" }}>
                {reportsError}
              </div>
            ) : (
              <>
                {listOfValuesData.length > 0 ? (
                  <div className="mb-3 w-100">
                    <div className="searchReports mx-auto">
                      <h6 className="mb-0">Reports</h6>
                      <Select
                        styles={styles}
                        value={listOfValuesData.find(
                          (state) => state.value === reportTag
                        )}
                        options={listOfValuesData}
                        onChange={handleChange}
                      />
                      {reportDescription && (
                        <div className="paragraph-box">
                          <h6>Description</h6>
                          <p className="textJustify">{reportDescription}</p>
                        </div>
                      )}
                      <div className="controls-box">
                        {loadings ? (
                          <MetroSpinner
                            size={30}
                            color="#007C16"
                            loading={loadings}
                          />
                        ) : (
                          <>
                            {reportsUniqueError ? (
                              <div className="error-msg">
                                {reportsUniqueError}
                              </div>
                            ) : (
                              <>
                                {reportsUniqueData?.length > 0 ? (
                                  <form
                                    className="controls-form"
                                    onSubmit={handleSubmit}
                                  >
                                    {reportsUniqueData.map((item) => (
                                      <div
                                        className="controls-div"
                                        key={item.tagNumber}
                                      >
                                        <label className="form-label">
                                          {item.label}
                                        </label>
                                        {/* dates */}
                                        {item.control === "date" && (
                                          <div className="date-picker-container">
                                            <DatePicker
                                              style={{
                                                width: 100 + "%",
                                              }}
                                              className="edit-media-inputs"
                                              selected={formData[item.name]}
                                              onChange={(date) =>
                                                handleDateChange(
                                                  item.name,
                                                  date
                                                )
                                              }
                                              dateFormat="yyyy-MM-dd"
                                              placeholderText={item.label}
                                              isClearable
                                              required={item.required}
                                            />
                                          </div>
                                        )}
                                        {/* checkboxList */}
                                        {item.control === "checkboxList" && (
                                          <div className="checkbox-container">
                                            {item.values.map((data) => (
                                              <div
                                                className="checkbox-div"
                                                key={data.value}
                                              >
                                                <MDBCheckbox
                                                  value={data.value}
                                                  label={data.label}
                                                  checked={selectedStatus.includes(
                                                    data.value
                                                  )}
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      data.value,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                        {/* select */}
                                        {item.control === "list" && (
                                          <>
                                            {item.name === "pFormat" && (
                                              <Select
                                                styles={styles}
                                                menuPlacement="top"
                                                required={item.required}
                                                options={item.values}
                                                value={item.values.find(
                                                  (option) =>
                                                    option.value ===
                                                    downloadFormat
                                                )}
                                                onChange={(option) =>
                                                  setDownloadFormat(
                                                    option.value
                                                  )
                                                }
                                              />
                                            )}
                                            {item.name === "pStats" && (
                                              <Select
                                                styles={styles}
                                                menuPlacement="top"
                                                required={item.required}
                                                options={item.values}
                                                value={item.values.find(
                                                  (option) =>
                                                    option.value ===
                                                    includeStats
                                                )}
                                                onChange={(option) =>
                                                  setIncludeStats(option.value)
                                                }
                                              />
                                            )}
                                          </>
                                        )}
                                        {/* textbox */}
                                        {item.control === "textbox" && (
                                          <input
                                            type="text"
                                            name={item.name}
                                            style={{
                                              width: 100 + "%",
                                            }}
                                            className="edit-media-inputs"
                                            required={item.required}
                                            placeholder={item.label}
                                            value={
                                              item.name === "pMonths"
                                                ? numberOfMonths
                                                : item.name ===
                                                  "pTransactionTag"
                                                ? tagNumber
                                                : item.name === "pRadius"
                                                ? radius
                                                : item.name === "pPostalZipCode"
                                                ? postalZipCode
                                                : ""
                                            }
                                            onChange={(e) => {
                                              item.name !== "pPostalZipCode"
                                                ? item.name !== "pMonths"
                                                  ? handleOnlyAllowNumbers(
                                                      e.target.value,
                                                      item
                                                    )
                                                  : handleAllowNumbersWithNegativeValues(
                                                      e.target.value
                                                    )
                                                : setPostalZipCode(
                                                    e.target.value
                                                  );
                                            }}
                                          />
                                        )}
                                      </div>
                                    ))}
                                    {previewReportError && (
                                      <div className="error-msg">
                                        {previewReportError}
                                      </div>
                                    )}
                                    <div className="submit">
                                      <button
                                        type="submit"
                                        className="btnGreen"
                                        disabled={previewReportLoading}
                                      >
                                        {previewReportLoading ? (
                                          <MetroSpinner
                                            loading={previewReportLoading}
                                            color="#fff"
                                            size={22}
                                          />
                                        ) : (
                                          "Generate Report"
                                        )}
                                      </button>
                                    </div>
                                  </form>
                                ) : (
                                  <div className="no-data-found-div">
                                    <Tippy content="No Controls Found">
                                      <img
                                        src={noDataFound}
                                        alt="No Controls Found"
                                      />
                                    </Tippy>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Reports Found">
                      <img src={noDataFound} alt="No Reports Found" />
                    </Tippy>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReportsComponent;
