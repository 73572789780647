import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const CampaignDetailsDrawer = ({ setCampaignDetailsDrawer, campaignObj }) => {
  // close modal
  const handleCloseModal = () => {
    setCampaignDetailsDrawer(false);
  };

  // day options
  const days = [
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
    { value: 7, label: "Sunday" },
  ];

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>{campaignObj.name}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body" style={{ padding: "4px" }}>
            <div className="transaction-details" style={{ padding: "8px" }}>
              <div className="notes-div">
                <h5>Description</h5>
                <div>{campaignObj.description}</div>
              </div>

              <div className="notes-div">
                <h5>Amounts</h5>
                <div>
                  {campaignObj.amounts
                    .slice() // Create a copy of the array to avoid mutating the original
                    .sort((a, b) => a - b) // Sort the amounts in ascending order
                    .map((amount, index) => (
                      <span
                        key={index}
                        style={{
                          padding: "8px",
                          borderRight: "1px solid #e4e4e4",
                        }}
                      >
                        {amount}
                      </span>
                    ))}
                </div>
              </div>

              <div className="notes-div">
                <h5>Frequency</h5>
                <div>
                  {campaignObj.frequency.map((frq, index) => (
                    <span
                      key={index}
                      style={{
                        padding: "8px",
                        borderRight: "1px solid #e4e4e4",
                      }}
                    >
                      {frq}
                    </span>
                  ))}
                </div>
              </div>

              <div className="notes-div nodes">
                <h5>Nodes</h5>
                <div>
                  {campaignObj.nodes.map((node, index) => (
                    <span
                      key={index}
                      style={{
                        padding: "8px",
                        borderRight: "1px solid #e4e4e4",
                        whiteSpace: "nowrap",
                        color: node.status && "#007c16",
                        fontWeight: node.status && 600,
                      }}
                    >
                      {node.organizationDefinedName}
                    </span>
                  ))}
                </div>
              </div>

              <hr />

              <div className="content" style={{ padding: "8px" }}>
                <div className="detail">
                  <h6 className="key">Allow Recurring</h6>
                  <h6>
                    {campaignObj.allowRecurringModification ? (
                      <>Yes</>
                    ) : (
                      <>No</>
                    )}
                  </h6>
                </div>

                <div className="detail">
                  <h6 className="key">Quantity</h6>
                  <h6>{campaignObj.enableQuantity ? <>Yes</> : <>No</>}</h6>
                </div>

                <div className="detail">
                  <h6 className="key">Hidden</h6>
                  <h6>{campaignObj.hidden ? <>Yes</> : <>No</>}</h6>
                </div>

                <div className="detail">
                  <h6 className="key">Type</h6>
                  <h6>
                    {campaignObj.donationCampaign ? (
                      <>Donation</>
                    ) : (
                      <>Payment</>
                    )}
                  </h6>
                </div>

                <div className="detail">
                  <h6 className="key">Target Achieved</h6>
                  <h6>{campaignObj.targetAchieved}</h6>
                </div>

                <div className="detail">
                  <h6 className="key">Fee</h6>
                  <h6>{campaignObj.fees}%</h6>
                </div>

                {campaignObj.recurringDay && (
                  <div className="detail">
                    <h6 className="key">Recurring Day</h6>
                    <h6>
                      {days.find(
                        (day) => day.value === campaignObj.recurringDay
                      )?.label || "N/A"}
                    </h6>
                  </div>
                )}

                <div className="detail">
                  <h6 className="key">Tax Deductible</h6>
                  <h6>{campaignObj.issueTaxReceipt ? <>Yes</> : <>No</>}</h6>
                </div>

                <div className="detail">
                  <h6 className="key">Tax Receipt Ratio</h6>
                  <h6>{campaignObj.taxReceiptRatio}</h6>
                </div>
              </div>
              <hr />
            </div>

            <div
              className="reset-and-filter-div"
              style={{ padding: "8px", width: "100%" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={() => setCampaignDetailsDrawer(false)}
                style={{ width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CampaignDetailsDrawer;
