import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  filterRecurringRecords,
  getAllData,
} from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const RecurringContainer = ({
  token,
  filteredParams,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  setPageSize,
  handleRecurringDetailsPopup,
  handleUpdateCardPopup,
  handleChangeAmountPopup,
  handleCloseRecurringPopup,
  handleSwitchToActivePopup,
  handleSwitchToHoldPopup,
  handleDeclinedTransactionsPopup,
  handleHistoryNotesPopup,
  donorSpecific,
  email,
  triggerScroll,
  setLoadings,
}) => {
  // redux state
  const { recurringPaymentsData, recurringPaymentsError } = useSelector(
    (state) => state.recurringPayments
  );

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterRecurringRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        dispatch(
          filterRecurringRecords({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            apiData: {
              email,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
            triggerScroll();
          }
        });
      } else {
        dispatch(
          getAllData({
            token,
            page: 1,
            pageSize: value,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterRecurringRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        dispatch(
          filterRecurringRecords({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            apiData: {
              email,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
            triggerScroll();
          }
        });
      } else {
        dispatch(
          getAllData({
            token,
            page: value,
            pageSize: perPage,
            by: "email",
            order: "asc",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  return (
    <>
      {recurringPaymentsError ? (
        <div className="error-msg" style={{ width: "fit-content" }}>
          {recurringPaymentsError}
        </div>
      ) : (
        <>
          {recurringPaymentsData &&
          recurringPaymentsData.data.recurring.length > 0 ? (
            <>
              {recurringPaymentsData.data.recurring.map((recurring) => (
                <div
                  key={recurring.tagNumber}
                  className="media-box"
                  style={{
                    borderTop:
                      recurring.status === "HOLD"
                        ? "3px solid grey"
                        : recurring.status === "ACTIVE"
                        ? "3px solid #007c16"
                        : "3px solid #f2b92c",
                    background: "#fff",
                  }}
                >
                  <div className="recurring-row">
                    <div className="recurring-leftside">
                      <div className="recurring-campaign-image">
                        <img
                          src={recurring.campaignImage}
                          alt="recurring-campaign"
                        />
                      </div>
                      <div className="recurring-campaign-details">
                        <h6>{recurring.campaign}</h6>
                        <h1>
                          {" "}
                          {recurringPaymentsData && (
                            <>{recurringPaymentsData.data.currencySymbol}</>
                          )}
                          {recurring.amount}
                        </h1>
                        <h5>
                          {recurring.donor ? (
                            <Tippy
                              content={`${recurring.donor.firstName} ${recurring.donor.lastName}`}
                            >
                              <Link
                                to={`/donors/${recurring.donor.firstName}_${recurring.donor.lastName}_${recurring.donor.tagNumber}`}
                                className="donor-hyper-link"
                              >
                                {recurring.email}
                              </Link>
                            </Tippy>
                          ) : (
                            <>{recurring.email}</>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="recurring-rightside">
                      <h6>{recurring.createdBy}</h6>
                      <h1 style={{ textTransform: "uppercase" }}>
                        {recurring.billingPeriod}
                      </h1>
                      <h6>{recurring.startDate}</h6>
                    </div>
                  </div>
                  <div className="media-table-container">
                    <MDBTable className="recurring-table">
                      <MDBTableHead className="media-thead">
                        <tr>
                          <th scope="col">Status</th>
                          <th scope="col">Invoice</th>
                          <th scope="col">Account ID</th>
                          <th scope="col">Card</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody className="media-body">
                        <tr>
                          <td>
                            <p className="text-muted mb-0">
                              {recurring.statusText}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              {recurring.invoice}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              {recurring.accountId}
                            </p>
                          </td>

                          <td>
                            <p className="text-muted mb-0">
                              {recurring.cardNumber}
                            </p>
                          </td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                  <div className="media-table-container">
                    <MDBTable className="recurring-table">
                      <MDBTableHead className="media-thead">
                        <tr>
                          <th scope="col">Card Expiry</th>
                          <th scope="col">Billing Expiry</th>
                          <th scope="col">Created</th>
                          <th scope="col">Updated</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody className="media-body">
                        <tr>
                          <td>
                            <p className="text-muted mb-0">
                              {recurring.cardExpiry}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              {recurring.billingExpiry}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0">
                              {recurring.createDateTime.split(" ")[0]}
                            </p>
                          </td>

                          <td>
                            <p className="text-muted mb-0">
                              {recurring.updateDateTime.split(" ")[0]}
                            </p>
                          </td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <MDBDropdown dropup group>
                      <MDBDropdownToggle
                        color="success"
                        style={{ background: "#007c16" }}
                      >
                        Actions
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem
                          link
                          style={{
                            pointerEvents:
                              recurring.status === "ACTIVE" &&
                              recurring.canUpdate
                                ? "auto"
                                : "none",
                            opacity:
                              recurring.status === "ACTIVE" &&
                              recurring.canUpdate
                                ? 1
                                : 0.5,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleChangeAmountPopup(recurring);
                          }}
                        >
                          Change Amount
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          style={{
                            pointerEvents: recurring.canActivate
                              ? "auto"
                              : "none",
                            opacity: recurring.canActivate ? 1 : 0.5,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSwitchToActivePopup(recurring);
                          }}
                        >
                          Reinstate
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          style={{
                            pointerEvents: recurring.canHold ? "auto" : "none",
                            opacity: recurring.canHold ? 1 : 0.5,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSwitchToHoldPopup(recurring);
                          }}
                        >
                          Hold
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          style={{
                            pointerEvents:
                              recurring.status === "ACTIVE" &&
                              recurring.canUpdate
                                ? "auto"
                                : "none",
                            opacity:
                              recurring.status === "ACTIVE" &&
                              recurring.canUpdate
                                ? 1
                                : 0.5,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleCloseRecurringPopup(recurring);
                          }}
                        >
                          Close Recurring
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          style={{
                            opacity: recurring.status !== "CLOSED" ? 1 : 0.5,
                            pointerEvents:
                              recurring.status !== "CLOSED" ? "auto" : "none",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdateCardPopup(recurring);
                          }}
                        >
                          Update Card
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          onClick={(e) => {
                            e.preventDefault();
                            handleRecurringDetailsPopup(recurring);
                          }}
                        >
                          Transactions History
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          style={{
                            opacity:
                              recurring.declineTransactions?.length > 0
                                ? 1
                                : 0.5,
                            pointerEvents:
                              recurring.declineTransactions?.length > 0
                                ? "auto"
                                : "none",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeclinedTransactionsPopup(recurring);
                          }}
                        >
                          Declined Transactions
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          link
                          style={{
                            opacity: recurring.historyNotes ? 1 : 0.5,
                            pointerEvents: recurring.historyNotes
                              ? "auto"
                              : "none",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleHistoryNotesPopup(recurring);
                          }}
                        >
                          Notes
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </div>
                </div>
              ))}

              {/* pagination */}
              <div className="transactions-pagination">
                <div className="per-page-and-pagination">
                  <div className="per-page">
                    <label>Records per page</label>
                    <Select
                      value={perPageOptions.find(
                        (option) => option.value === String(perPage)
                      )}
                      onChange={(option) => handlePerPage(option.value)}
                      options={perPageOptions}
                      styles={styles}
                      menuPlacement="top"
                    />
                  </div>
                  <ResponsivePagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="no-data-found-div">
              <Tippy content="No Recurring Payments Found">
                <img src={noDataFound} alt="No Recurring Payments Found" />
              </Tippy>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RecurringContainer;
