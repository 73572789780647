import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const MoreDetailsPopup = ({ setMoreDetailsPopup, moreDetailsObj }) => {
  // close modal
  const handleCloseModal = () => {
    setMoreDetailsPopup(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>More Details</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="transaction-details">
              <div className="header">
                <div className="merchant">
                  <h4>{moreDetailsObj.accountHolderName}</h4>
                  <h5>{moreDetailsObj.campaign}</h5>
                </div>
                <div className="amount">
                  {moreDetailsObj.currencySymbol}
                  {moreDetailsObj.amount}
                </div>
              </div>
              <div className="content">
                {/* createdBy */}
                <div className="detail">
                  <h6 className="key">Create By</h6>
                  <h6>{moreDetailsObj.createdBy}</h6>
                </div>

                {/* invoice */}
                <div className="detail">
                  <h6 className="key">Invoice</h6>
                  <h6>{moreDetailsObj.invoice}</h6>
                </div>

                {/* total payments */}
                <div className="detail">
                  <h6 className="key">Total Payments</h6>
                  <h6>{moreDetailsObj.totalPayments}</h6>
                </div>

                {/* processed number */}
                <div className="detail">
                  <h6 className="key">Processed Number</h6>
                  <h6>
                    {moreDetailsObj.processedNumber && (
                      <>{moreDetailsObj.processedNumber}</>
                    )}
                  </h6>
                </div>

                {/* start date */}
                <div className="detail">
                  <h6 className="key">Start Date</h6>
                  <h6>{moreDetailsObj.startDate}</h6>
                </div>

                {/* billing expiry */}
                <div className="detail">
                  <h6 className="key">Billing Expiry</h6>
                  <h6>{moreDetailsObj.billingExpiry}</h6>
                </div>

                {/* next billing */}
                <div className="detail">
                  <h6 className="key">Next Billing</h6>
                  <h6>{moreDetailsObj.nextBilling}</h6>
                </div>
              </div>
            </div>

            <div
              className="reset-and-filter-div"
              style={{ padding: 10 + "px" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MoreDetailsPopup;
