/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Donors.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  deletePhoto,
  getSpecificDonor,
} from "../../Store/Slices/Sidebar/Donors/DonorsCrudSlice";
import { MetroSpinner } from "react-spinners-kit";
import { Toaster, toast } from "react-hot-toast";
import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import { software_pencil } from "react-icons-kit/linea/software_pencil";
import { arrows_plus } from "react-icons-kit/linea/arrows_plus";
import { chevronLeft } from "react-icons-kit/feather/chevronLeft";
import { arrowUp } from "react-icons-kit/feather/arrowUp";
import { mail } from "react-icons-kit/feather/mail";
import { mapPin } from "react-icons-kit/feather/mapPin";
import { phone } from "react-icons-kit/feather/phone";
import { list } from "react-icons-kit/feather/list";
import { creditCard } from "react-icons-kit/feather/creditCard";
import axios from "axios";
import hostName from "../../config";
// common
import Header from "../../Components/UI/Header";
import Sidebar from "../../Components/UI/Sidebar";
// update photo popup
import UpdatePhotoPopup from "./UpdatePhotoPopup";
// highlights
import {
  getDonorKeyDates,
  getDonorLastNMonthSummary,
  getDonorLifetime,
  getDonorRecentTransactions,
  getDonorYearly,
  getDonorYearOverYears,
  getDonorCampaignsSummary,
} from "../../Store/Slices/Sidebar/Donors/DonorHighlightsSlice";
import Lifetime from "../../Components/Donors/DonorDetails/Highlights/Lifetime";
import ThisYear from "../../Components/Donors/DonorDetails/Highlights/ThisYear";
import KeyDates from "../../Components/Donors/DonorDetails/Highlights/KeyDates";
import NMonthSummary from "../../Components/Donors/DonorDetails/Highlights/NMonthSummary";
import RecentTransactions from "../../Components/Donors/DonorDetails/Highlights/RecentTransactions";
import YearOverYears from "../../Components/Donors/DonorDetails/Highlights/YearOverYears";
import CampaignsSummary from "../../Components/Donors/DonorDetails/Highlights/CampaignsSummary";
// payments
import { getWallets } from "../../Store/Slices/Wallet/WalletSlice";
import { filterRecurringRecords } from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import Wallets from "../../Components/Donors/DonorDetails/Payments/Wallets";
import AddWalletPopus from "../../Components/Donors/DonorDetails/Payments/AddWalletPopus";
import DeleteWalletPopup from "../../Components/Donors/DonorDetails/Payments/DeleteWalletPopup";
import EditWalletPopup from "../../Components/Donors/DonorDetails/Payments/EditWalletPopup";
import WalletChargePopup from "../../Components/Donors/DonorDetails/Payments/WalletChargePopup";
import RecurringContainer from "../../Components/Recurring/RecurringContainer";
import RecurringDetailsPopup from "../../Components/Recurring/RecurringDetailsPopup";
import ChangeAmountPopup from "../../Components/Recurring/ChangeAmountPopup";
import CloseRecurringPopup from "../../Components/Recurring/CloseRecurringPopup";
import HistoryNotesPopup from "../../Components/Recurring/HistoryNotesPopup";
import DeclinedTransactionsPopup from "../../Components/Recurring/DeclinedTransactionsPopup";
import SwitchToActivePopup from "../../Components/Recurring/SwitchToActivePopup";
import SwitchToHoldPopup from "../../Components/Recurring/SwitchToHoldPopup";
import UpdateCardPopup from "../../Components/Recurring/UpdateCardPopup";
// transactions
import {
  exportTransactionRecords,
  filterTransactionRecords,
  getNodes,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import TransactionsTable from "../../Components/Transactions/TransactionsTable";
import TransactionDetailsPopup from "../../Components/Transactions/TransactionDetailsPopup";
import CardTransactionsPopup from "../../Components/Transactions/CardTransactionsPopup";
import ManualEditTransactionPopup from "../../Components/Transactions/ManualEditTransactionPopup";
import DeleteTransactionPopup from "../../Components/Transactions/DeleteTransactionPopup";
import ManualTransactionsPopup from "../../Components/Transactions/ManualTransactionsPopup";
import RefundPopup from "../../Components/Transactions/RefundPopup";
import UpdateCampaignPopup from "../../Components/Transactions/UpdateCampaignPopup";
// tax receipts
import { filterTaxReceiptsRecords } from "../../Store/Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
import TaxReceiptTable from "../../Components/TaxReceipts/TaxReceiptTable";
import NewReceiptPopup from "../../Components/TaxReceipts/NewReceiptPopup";
// eCheck
import {
  filterECheckRecords,
  filterListViewRecords,
  getUserBankAccounts,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import BankingDetails from "../../Components/ECheck/BankingDetails";
import AddECheckTransactionPopup from "../../Components/ECheck/AddECheckTransactionPopup";
import DeleteBankAccountPopup from "../../Components/ECheck/DeleteBankAccountPopup";
import AddBankAccount from "../../Components/ECheck/AddBankAccount";
import EditBankAccount from "../../Components/ECheck/EditBankAccount";
import MakeDefaultPopup from "../../Components/ECheck/MakeDefaultPopup";
import DetailView from "../../Components/ECheck/DetailView";
import ECheckTable from "../../Components/ECheck/ECheckTable";
import MoreDetailsPopup from "../../Components/ECheck/MoreDetailsPopup";
import SchedulePopup from "../../Components/ECheck/SchedulePopup";
import EditECheckTransactionPopup from "../../Components/ECheck/EditECheckTransactionPopup";
import AddPaymentPopup from "../../Components/ECheck/AddPaymentPopup";
import CloseECheckPopup from "../../Components/ECheck/CloseECheckPopup";
import ListViewTransactionDetailsPopup from "../../Components/ECheck/ListViewTransactionDetailsPopup";
import CancelPaymentPopup from "../../Components/ECheck/CancelPaymentPopup";
import UpdateAmountPopup from "../../Components/ECheck/UpdateAmountPopup";
import UpdateBankingDetailsPopup from "../../Components/ECheck/UpdateBankingDetailsPopup";
import FilterECheckModal from "../../Components/ECheck/FilterECheckModal";
import FilterListViewModal from "../../Components/ECheck/FilterListViewModal";

// get user from LS
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const DonorDetails = ({
  sidebarToggled,
  setSidebarToggled,
  quickLinksDropdown,
  setQuickLinksDropdown,
}) => {
  // state
  const [user] = useState(getUser());

  // redirect user to login if no user found
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  // selected org
  const [selectedOrganization] = useState(getOrganization());

  // getting donor first and last name
  const { name } = useParams();

  const [firstName, lastName, tagNumber] = name.split("_");

  // redux state -- getting specific donor
  const { specificDonorData, specificDonorLoading, specificDonorError } =
    useSelector((state) => state.donors);

  // redux state -- getting highlights loadings
  const {
    donorLifetimeLoading,
    donorYearlyLoading,
    donorKeyDatesLoading,
    donorLastNMonthSummaryLoading,
    recentTransactionsLoading,
    yearOverYearsLoading,
    campaignsSummaryLoading,
  } = useSelector((state) => state.donorHighlights);

  // redux stare -- getting wallets loading
  const { walletLoading, walletData } = useSelector((state) => state.wallet);

  // redux state -- get recurrings
  const { recurringPaymentsLoading } = useSelector(
    (state) => state.recurringPayments
  );

  // redux state -- get transactions
  const { transactionsLoading } = useSelector((state) => state.transactions);

  // redux state -- tax receipts
  const { allLoading } = useSelector((state) => state.taxReceipts);

  // redux state -- get bank accounts
  const {
    bankAccountsLoading,
    eCheckLoading,
    eCheckError,
    listViewLoading,
    listViewError,
  } = useSelector((state) => state.eCheck);

  // get all options with a single loading state
  const [entityOptions, setEntityOptions] = useState([]);
  const [listViewEntityOptions, setListViewEntityOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [scheduleStatusOptions, setScheduleStatusOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(true);

  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    Promise.all([
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
      dispatch(getNodes(user.token)),
      axios.get(
        `${hostName}api/admin/v1/routeLov?routeName=echeck`,
        axiosConfig
      ),
      axios.get(
        `${hostName}api/admin/v1/lov?listName=eCheckFilter`,
        axiosConfig
      ),
      axios.get(
        `${hostName}api/admin/v1/lov?listName=eCheckScheduleFilter`,
        axiosConfig
      ),
    ])
      .then(
        ([
          countriesRes,
          usaStatesRes,
          provincesRes,
          nodesRes,
          routeRes,
          gridRes,
          listViewRes,
        ]) => {
          // handle countries
          const countriesOptions = countriesRes.data.map((country) => ({
            value: country.code,
            label: country.name,
          }));
          setCountries(countriesOptions);

          // handle usa states
          const usaStatesOptions = usaStatesRes.data.map((state) => ({
            value: state.code,
            label: state.name,
          }));
          setUsaStates(usaStatesOptions);

          // handle provinces
          const provincesOptions = provincesRes.data.map((province) => ({
            value: province.code,
            label: province.name,
          }));
          setProvinces(provincesOptions);

          // handle campaign options
          setCampaignOptions(nodesRes.payload.active_campaign);
          setAllCampaigns(nodesRes.payload.campaign_tag);

          // Handle frequency and institute options
          setFrequencyOptions(routeRes.data.data.billing_period);
          setInstituteOptions(routeRes.data.data.institute_number);

          // status
          setStatusOptions(routeRes.data.data.status);
          setScheduleStatusOptions(routeRes.data.data.schedule_status);

          // Handle eCheckFilter and eCheckScheduleFilter
          // Filter out the object with value 'email' from both arrays
          const filteredGridData = gridRes.data.data.filter(
            (item) => item.value !== "email"
          );
          const filteredListViewData = listViewRes.data.data.filter(
            (item) => item.value !== "email"
          );

          // Update the state with the filtered arrays
          setEntityOptions(filteredGridData);
          setListViewEntityOptions(filteredListViewData);
        }
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setOptionsLoading(false));
  }, []);

  // state to check if any loading from redux is true
  const [highlightsLoadings, setHighlightsLoadings] = useState(true);
  const [paymentsLoadings, setPaymentsLoadings] = useState(true);
  const [transactionsLoadings, setTransactionsLoadings] = useState(true);
  const [taxReceiptsLoadings, setTaxReceiptsLoadings] = useState(true);
  const [eCheckLoadings, setECheckLoadings] = useState(true);

  // checking if any loading is true in highlights tab
  const allHighlightsLoadingStates = [
    donorLifetimeLoading,
    donorYearlyLoading,
    donorKeyDatesLoading,
    donorLastNMonthSummaryLoading,
    recentTransactionsLoading,
    yearOverYearsLoading,
    campaignsSummaryLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allHighlightsLoadingStates.some((state) => state);
    setHighlightsLoadings(isAnyChildLoading);
  }, [allHighlightsLoadingStates]);

  // checking if any loading is true payments tab
  const allPaymentsLoadingStates = [
    walletLoading,
    recurringPaymentsLoading,
    optionsLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allPaymentsLoadingStates.some((state) => state);
    setPaymentsLoadings(isAnyChildLoading);
  }, [allPaymentsLoadingStates]);

  // checking if any loading is true in transactions tab
  const allTransactionsLoadingStates = [transactionsLoading, optionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allTransactionsLoadingStates.some(
      (state) => state
    );
    setTransactionsLoadings(isAnyChildLoading);
  }, [allTransactionsLoadingStates]);

  // checking if any loading is true in tax receipts tab
  const allTaxReceiptsLoadingStates = [allLoading, optionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allTaxReceiptsLoadingStates.some(
      (state) => state
    );
    setTaxReceiptsLoadings(isAnyChildLoading);
  }, [allTaxReceiptsLoadingStates]);

  // checking if any loading is true in eCheck tab
  const allECheckLoadingStates = [
    bankAccountsLoading,
    eCheckLoading,
    listViewLoading,
    optionsLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allECheckLoadingStates.some((state) => state);
    setECheckLoadings(isAnyChildLoading);
  }, [allECheckLoadingStates]);

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTransactions, setCurrentPageTransactions] = useState(1);
  const [currentPageTaxReceipts, setCurrentPageTaxReceipts] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesTransactions, setTotalPagesTransactions] = useState(1);
  const [totalPagesTaxReceipts, setTotalPagesTaxReceipts] = useState(1);
  const [perPage, setPerPage] = useState("10");
  const [perPageTransactions, setPerPageTransactions] = useState("20");
  const [perPageTaxReceipts, setPerPageTaxReceipts] = useState("20");

  // tabs
  const [activeTab, setActiveTab] = useState("Highlights");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get specific donor
  useEffect(() => {
    if (user) {
      dispatch(
        getSpecificDonor({
          token: user.token,
          firstName,
          lastName,
          tagNumber,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    } else {
      navigate("/login");
    }
  }, []);

  // current date obj
  let currentDate = new Date();

  // get all donors tab data at once
  useEffect(() => {
    if (specificDonorData) {
      // liftime
      dispatch(
        getDonorLifetime({
          token: user.token,
          email: specificDonorData.email,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // yearly
      dispatch(
        getDonorYearly({
          token: user.token,
          email: specificDonorData.email,
          year: currentDate.getFullYear().toString(),
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // key dates
      dispatch(
        getDonorKeyDates({
          token: user.token,
          email: specificDonorData.email,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // last n month summary
      dispatch(
        getDonorLastNMonthSummary({
          token: user.token,
          email: specificDonorData.email,
          n: "20",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // recent transaction
      dispatch(
        getDonorRecentTransactions({
          token: user.token,
          email: specificDonorData.email,
          n: "5",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // year over years
      dispatch(
        getDonorYearOverYears({
          token: user.token,
          email: specificDonorData.email,
          numberOfYears: "2",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // campaigns summary
      dispatch(
        getDonorCampaignsSummary({
          token: user.token,
          email: specificDonorData.email,
          year: currentDate.getFullYear().toString(),
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // get wallets
      dispatch(
        getWallets({
          token: user.token,
          email: specificDonorData.email,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      // get user specific recurrings
      dispatch(
        filterRecurringRecords({
          token: user.token,
          page: 1,
          pageSize: "10",
          order: "asc",
          apiData: {
            email: specificDonorData.email,
            by: "email",
          },
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setTotalPages(res.payload.links.lastPage);
          setPerPage(res.payload.links.perPage);
        }
      });

      // get donor transactions
      dispatch(
        filterTransactionRecords({
          token: user.token,
          page: 1,
          pageSize: "20",
          order: "asc",
          apiData: {
            email: specificDonorData.email,
            by: "email",
          },
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPageTransactions(res.payload.links.currentPage);
          setTotalPagesTransactions(res.payload.links.lastPage);
          setPerPageTransactions(res.payload.links.perPage);
        }
      });

      // get donor specific tax receipts data
      dispatch(
        filterTaxReceiptsRecords({
          token: user.token,
          page: 1,
          pageSize: "20",
          order: "asc",
          apiData: {
            email: specificDonorData.email,
            by: "email",
          },
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPageTaxReceipts(res.payload.links.currentPage);
          setPerPageTaxReceipts(res.payload.links.perPage);
          setTotalPagesTaxReceipts(res.payload.links.lastPage);
        }
      });

      // get donor specific bank accounts
      dispatch(
        getUserBankAccounts({
          token: user.token,
          email: specificDonorData.email,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    }
  }, [specificDonorData?.tagNumber]);

  // add wallet popup
  const [addWalletPopup, setAddWalletPopup] = useState(false);

  // delete wallet popup
  const [deleteWalletPopup, setDeleteWalletPopup] = useState(false);
  const [deleteWalletObj, setDeleteWalletObj] = useState(null);

  const handleDeleteWalletPopup = (wallet) => {
    setDeleteWalletPopup(true);
    setDeleteWalletObj(wallet);
  };

  // edit wallet popup
  const [editWalletPopup, setEditWalletPopup] = useState(false);
  const [editWalletObj, setEditWalletObj] = useState(null);

  const handleEditWalletPopup = (wallet) => {
    setEditWalletPopup(true);
    setEditWalletObj(wallet);
  };

  // wallet charge popup
  const [walletChargePopup, setWalletChargePopup] = useState(false);
  const [walletObj, setWalletObj] = useState(null);

  const handleWalletChargePopup = (wallet) => {
    setWalletChargePopup(true);
    setWalletObj(wallet);
  };

  // recurring details popup
  const [recurringDetailsPopup, setRecurringDetailsPopup] = useState(false);
  const [recurringObj, setRecurringObj] = useState(null);
  const handleRecurringDetailsPopup = (recurring) => {
    setRecurringDetailsPopup(true);
    setRecurringObj(recurring);
  };

  // declined transactions popup
  const [declinedTransactionsPopup, setDeclinedTransactionsPopup] =
    useState(false);
  const [declinedTransactionsObj, setDeclinedTransactionsObj] = useState(null);
  const handleDeclinedTransactionsPopup = (recurring) => {
    setDeclinedTransactionsPopup(true);
    setDeclinedTransactionsObj(recurring);
  };

  // history notes popup
  const [historyNotesPopup, setHistoryNotesPopup] = useState(false);
  const [historyNotesObj, setHistoryNotesObj] = useState(null);
  const handleHistoryNotesPopup = (recurring) => {
    setHistoryNotesPopup(true);
    setHistoryNotesObj(recurring);
  };

  // update card popup
  const [updateCardPopup, setUpdateCardPopup] = useState(false);
  const [updateCardObj, setUpdateCardObj] = useState(null);
  const handleUpdateCardPopup = (recurring) => {
    setUpdateCardPopup(true);
    setUpdateCardObj(recurring);
  };

  // change amount popup
  const [changeAmountPopup, setChangeAmountPopup] = useState(false);
  const [changeAmountObj, setChangeAmountObj] = useState(null);
  const handleChangeAmountPopup = (recurring) => {
    setChangeAmountPopup(true);
    setChangeAmountObj(recurring);
  };

  // close recurring popup
  const [closeRecurringPopup, setCloseRecurringPopup] = useState(false);
  const [closeRecurringObj, setCloseRecurringObj] = useState(null);
  const handleCloseRecurringPopup = (recurring) => {
    setCloseRecurringPopup(true);
    setCloseRecurringObj(recurring);
  };

  // switch to active popup
  const [switchToActivePopup, setSwitchToActivePopup] = useState(false);
  const [switchToActiveObj, setSwitchToActiveObj] = useState(null);
  const handleSwitchToActivePopup = (recurring) => {
    setSwitchToActivePopup(true);
    setSwitchToActiveObj(recurring);
  };

  // switch to hold popup
  const [switchToHoldPopup, setSwitchToHoldPopup] = useState(false);
  const [switchToHoldObj, setSwitchToHoldObj] = useState(null);
  const handleSwitchToHoldPopup = (recurring) => {
    setSwitchToHoldPopup(true);
    setSwitchToHoldObj(recurring);
  };

  // new recurring modal
  const [newRecurringPopup, setNewRecurringPopup] = useState(false);
  const handleNewRecurringPopup = () => {
    setNewRecurringPopup(true);
  };

  // one time
  const [oneTime] = useState(false);

  // donor specific
  const [donorSpecific] = useState(true);

  // scroll down
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (shouldScroll) {
      // Scroll to the desired section using the scrollIntoView method
      const recurringSection = document.getElementById("recurringSection");
      if (recurringSection) {
        recurringSection.scrollIntoView({ behavior: "smooth" });
      }

      // Scroll to the desired section using the scrollIntoView method
      const eCheckSection = document.getElementById("eCheckSection");
      if (eCheckSection) {
        eCheckSection.scrollIntoView({ behavior: "smooth" });
      }

      // Reset the scroll flag
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  // Function to trigger scrolling from outside ComponentA
  const triggerScroll = () => {
    setShouldScroll(true);
  };

  // donor specific transactions

  // manual transaction modal
  const [manualTransactionsPopup, setManualTransactionsPopup] = useState(false);

  const handleManualTransactionsPopup = (e) => {
    e.preventDefault();
    setManualTransactionsPopup(true);
  };

  // card transactions modal
  const [cardTransactionsPopup, setCardTransactionsPopup] = useState(false);

  const handleCardTransactionsPopup = (e) => {
    e.preventDefault();
    setCardTransactionsPopup(true);
  };

  // edit transaction popup
  const [editTransactionPopup, setEditTransactionPopup] = useState(false);
  const [editTransactionObj, setEditTransactionObj] = useState(null);

  const handleEditTransactionPopup = (transaction) => {
    setEditTransactionPopup(true);
    setEditTransactionObj(transaction);
  };

  // transaction details popup
  const [transactionDetailsPopup, setTransactionDetailsPopup] = useState(false);
  const [transactionObj, setTarnsactionObj] = useState(null);

  const handleTransactionDetailsPopup = (transaction) => {
    setTransactionDetailsPopup(true);
    setTarnsactionObj(transaction);
  };

  // update campaign popup
  const [updateCampaignPopup, setUpdateCampaignPopup] = useState(false);
  const [updateCampaignObj, setUpdateCampaignObj] = useState(null);

  const handleUpdateCampaignPopup = (transaction) => {
    setUpdateCampaignPopup(true);
    setUpdateCampaignObj(transaction);
  };

  // delete transaction popup
  const [deleteTransactionPopup, setDeleteTransactionPopup] = useState(false);
  const [deleteTransactionObj, setDeleteTransactionObj] = useState(null);

  const handleDeleteTransactionsPopup = (transaction) => {
    setDeleteTransactionPopup(true);
    setDeleteTransactionObj(transaction);
  };

  // transaction type popup (refund)
  const [transactionTypePopup, setTransactionTypePopup] = useState(false);
  const [transactionTypeObj, setTransactionTypeObj] = useState(null);

  const handleTransactionTypePopup = (transaction) => {
    setTransactionTypePopup(true);
    setTransactionTypeObj(transaction);
  };

  // redux state
  const { exportTransactionLoading } = useSelector(
    (state) => state.transactions
  );

  // export records
  const handleExportRecords = () => {
    dispatch(
      exportTransactionRecords({
        token: user.token,
        filteredParams: {
          apiData: {
            by: "email",
            email: specificDonorData.email,
          },
        },
      })
    ).then((res) => {
      if (res.payload) {
        // download xlsx
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else if (res.error.message === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else if (res.error.message && typeof res.error.message === "string") {
        toast.error(`${res.error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // new receipt popup
  const [newReceiptPopup, setNewReceiptPopup] = useState(false);

  // getting year options
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  // is user dp loaded
  const [isLoaded, setIsLoaded] = useState(false);

  // set is loaded to false again when user change his dp
  useEffect(() => {
    setIsLoaded(false);
  }, [specificDonorData?.profileImage]);

  // profile photo change
  const [photoFile, setPhotoFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [updatePhotoPopup, setUpdatePhotoPopup] = useState(false);

  const handleProfilePhoto = (file) => {
    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      const fileType = file.type;

      // Maximum file size in KB
      const maxFileSizeKB = 1024;

      // Check file type
      if (!allowedFileTypes.includes(fileType)) {
        setPhotoFile(null);
        setPhotoURL(null);
        setFileError("Invalid file type. Only PNG, JPEG, and JPG.");
        return;
      }

      // Check file size
      if (file.size > maxFileSizeKB * 1024) {
        setPhotoFile(null);
        setPhotoURL(null);
        setFileError(`File size should not exceed ${maxFileSizeKB}KB.`);
        return;
      }

      // File is valid
      setPhotoFile(file);
      setFileError(null);

      // Create object URL and save it in the state
      const url = URL.createObjectURL(file);
      setPhotoURL(url);

      // open popup
      setUpdatePhotoPopup(true);
    } else {
      setFileError(null);
    }
  };

  // delete photo
  const { deletePhotoLoading } = useSelector((state) => state.donors);

  // delete photo function
  const handleDeletePhoto = () => {
    setFileError(null);
    dispatch(
      deletePhoto({
        token: user.token,
        tagNumber: specificDonorData.tagNumber,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/signin");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // format phone number
  const formatPhoneNumber = (value) => {
    if (value) {
      if (value.length <= 10) {
        // Remove all non-numeric characters
        const digits = value.replace(/\D/g, "");

        // Format as (555) 555-1234
        if (digits.length <= 3) {
          return `(${digits}`;
        } else if (digits.length <= 6) {
          return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
        } else {
          return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
            6,
            10
          )}`;
        }
      } else {
        return value;
      }
    } else {
      return "";
    }
  };

  // eCheck

  // layout
  const [layout, setLayout] = useState("table");

  // filtered params
  const [tableViewFilteredParams, setTableViewFilteredParams] = useState(null);
  const [transactionViewFilteredParams, setTransactionViewFilteredParams] =
    useState(null);

  // filter modals
  const [tableViewFilterModal, setTableViewFilterModal] = useState(false);
  const [transactionViewFilterModal, setTransactionViewFilterModal] =
    useState(false);

  // get read apis data
  useEffect(() => {
    if (specificDonorData) {
      // table view data
      if (layout === "table") {
        // filter echeck data
        if (tableViewFilteredParams) {
          dispatch(
            filterECheckRecords({
              token: user.token,
              page: 1,
              pageSize: tableViewFilteredParams.pageSize,
              order: tableViewFilteredParams.order,
              apiData: {
                ...tableViewFilteredParams.apiData,
                email: specificDonorData.email,
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setTableViewCurrentPage(res.payload.links.currentPage);
              setTableViewPerPage(res.payload.links.perPage);
              setTableViewPageSize(res.payload.links.perPage);
              setTableViewTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        } else {
          // get all eCheck data
          dispatch(
            filterECheckRecords({
              token: user.token,
              page: 1,
              pageSize: "10",
              order: "asc",
              apiData: {
                email: specificDonorData.email,
                by: "email",
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setTableViewCurrentPage(res.payload.links.currentPage);
              setTableViewPerPage(res.payload.links.perPage);
              setTableViewPageSize(res.payload.links.perPage);
              setTableViewTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        }
      } else if (layout === "list") {
        // filter transaction view data
        if (transactionViewFilteredParams) {
          dispatch(
            filterListViewRecords({
              token: user.token,
              page: 1,
              pageSize: transactionViewFilteredParams.pageSize,
              order: transactionViewFilteredParams.order,
              apiData: {
                ...transactionViewFilteredParams.apiData,
                email: specificDonorData.email,
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setTransactionViewCurrentPage(res.payload.links.currentPage);
              setTransactionViewPerPage(res.payload.links.perPage);
              setTransactionViewPageSize(res.payload.links.perPage);
              setTransactionViewTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        } else {
          // get all listview data
          dispatch(
            filterListViewRecords({
              token: user.token,
              page: 1,
              pageSize: "20",
              order: "asc",
              apiData: {
                email: specificDonorData.email,
                by: "email",
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setTransactionViewCurrentPage(res.payload.links.currentPage);
              setTransactionViewPerPage(res.payload.links.perPage);
              setTransactionViewPageSize(res.payload.links.perPage);
              setTransactionViewTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        }
      }
    }
  }, [
    specificDonorData?.tagNumber,
    layout,
    tableViewFilteredParams,
    transactionViewFilteredParams,
  ]);

  // states for table view
  const [tableViewCurrentPage, setTableViewCurrentPage] = useState(1);
  const [tableViewTotalPages, setTableViewTotalPages] = useState(1);
  // per page is used outside filter modal
  const [tableViewPerPage, setTableViewPerPage] = useState("10");

  // page size is used in filter modal
  const [tableViewPageSize, setTableViewPageSize] = useState("10");
  const handleTableViewPerPage = (value) => {
    setTableViewPageSize(value);
  };

  // states for transaction view
  const [transactionViewCurrentPage, setTransactionViewCurrentPage] =
    useState(1);
  const [transactionViewTotalPages, setTransactionViewTotalPages] = useState(1);
  // per page is used outside filter modal
  const [transactionViewPerPage, setTransactionViewPerPage] = useState("20");

  // page size is used in filter modal
  const [transactionViewPageSize, setTransactionViewPageSize] = useState("20");
  const handleTransactionViewPerPage = (value) => {
    setTransactionViewPageSize(value);
  };

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // entity selected
  const [entitySelectedListView, setEntitySelectedListView] = useState([]);
  const handleSelectChangeListView = (selectedOptions) => {
    setEntitySelectedListView(selectedOptions);
  };

  // approved amount
  const [amount, setAmount] = useState("");
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // account number
  const [accountNumber, setAccountNumber] = useState("");

  // account holder name
  const [accountHolderName, setAccountHolderName] = useState("");

  // invoice
  const [invoice, setInvoice] = useState("");

  // email
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (specificDonorData) {
      setEmail(specificDonorData.email);
    } else {
      setEmail("");
    }
  }, [specificDonorData?.tagNumber]);

  // campaignTag
  const [campaignTag, setCampaignTag] = useState([]);
  const handleCampaignTagChange = (item) => {
    if (campaignTag.includes(item)) {
      setCampaignTag(campaignTag.filter((checkedItem) => checkedItem !== item));
    } else {
      setCampaignTag([...campaignTag, item]);
    }
  };

  // billing period
  const [billingPeriod, setBillingPeriod] = useState([]);
  const handleBillingPeriodChange = (item) => {
    if (billingPeriod.includes(item)) {
      setBillingPeriod(
        billingPeriod.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setBillingPeriod([...billingPeriod, item]);
    }
  };

  // created by
  const [createdBy, setCreatedBy] = useState("");

  // status
  const [status, setStatus] = useState([]);
  const handleStatusChange = (item) => {
    if (status.includes(item)) {
      setStatus(status.filter((checkedItem) => checkedItem !== item));
    } else {
      setStatus([...status, item]);
    }
  };

  // institute number
  const [instituteNumber, setInstituteNumber] = useState("");

  // transit number
  const [transitNumber, setTransitNumber] = useState("");

  // comments
  const [comments, setComments] = useState("");

  // sort by
  const [sortBy, setSortBy] = useState(null);

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // list view account number
  const [listViewAccountNumber, setListViewAccountNumber] = useState("");

  // list view account holder name
  const [listViewAccountHolderName, setListViewAccountHolderName] =
    useState("");

  // campaignTag
  const [campaignTagListView, setCampaignTagListView] = useState([]);
  const handleCampaignTagChangeListView = (item) => {
    if (campaignTagListView.includes(item)) {
      setCampaignTagListView(
        campaignTagListView.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setCampaignTagListView([...campaignTagListView, item]);
    }
  };

  // charged amount
  const [chargedAmount, setChargedAmount] = useState("");
  const handleChargedAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setChargedAmount(newValue);
  };

  // invoice
  const [invoiceListView, setInvoiceListView] = useState("");

  // email
  const [emailListView, setEmailListView] = useState("");

  useEffect(() => {
    if (specificDonorData) {
      setEmailListView(specificDonorData.email);
    } else {
      setEmailListView("");
    }
  }, [specificDonorData?.tagNumber]);

  // batch id
  const [batchIdListView, setBatchIdListView] = useState("");

  // billing period
  const [billingPeriodListView, setBillingPeriodListView] = useState([]);
  const handleBillingPeriodChangeListView = (item) => {
    if (billingPeriodListView.includes(item)) {
      setBillingPeriodListView(
        billingPeriodListView.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setBillingPeriodListView([...billingPeriodListView, item]);
    }
  };

  // institute number
  const [instituteNumberListView, setInstituteNumberListView] = useState("");

  // transit number
  const [transitNumberListView, setTransitNumberListView] = useState("");

  // created by
  const [createdByListView, setCreatedByListView] = useState("");

  // status
  const [statusListView, setStatusListView] = useState([]);
  const handleStatusChangeListView = (item) => {
    if (statusListView.includes(item)) {
      setStatusListView(
        statusListView.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setStatusListView([...statusListView, item]);
    }
  };

  // schedule status
  const [scheduleStatus, setScheduleStatus] = useState([]);
  const handleScheduleStatusChange = (item) => {
    if (scheduleStatus.includes(item)) {
      setScheduleStatus(
        scheduleStatus.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setScheduleStatus([...scheduleStatus, item]);
    }
  };

  // process date
  const [processDate, setProcessDate] = useState(null);

  // return date
  const [returnDate, setReturnDate] = useState(null);

  // comments
  const [listViewComments, setListViewComments] = useState("");

  // sort by
  const [sortByListView, setSortByListView] = useState(null);

  // order by
  const [orderByListView, setOrderByListView] = useState({
    value: "asc",
    label: "Ascending",
  });

  // reset filter table view
  const handleTableViewResetFilter = () => {
    if (tableViewFilteredParams) {
      setECheckLoadings(true);
      setTableViewFilteredParams(null);
      setTableViewFilterModal(false);
    }
    setEntitySelected([]);
    setAmount("");
    setAccountHolderName("");
    setAccountNumber("");
    setInvoice("");
    // setEmail("");
    setCampaignTag([]);
    setInstituteNumber("");
    setTransitNumber("");
    setStatus([]);
    setCreatedBy("");
    setBillingPeriod([]);
    setComments("");
    setTableViewPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset filter transaction view
  const handleTransactionViewResetFilter = () => {
    if (transactionViewFilteredParams) {
      setECheckLoadings(true);
      setTransactionViewFilteredParams(null);
      setTransactionViewFilterModal(false);
    }
    setEntitySelectedListView([]);
    setListViewAccountHolderName("");
    setListViewAccountNumber("");
    setCampaignTagListView([]);
    setChargedAmount("");
    setInvoiceListView("");
    // setEmailListView("");
    setBatchIdListView("");
    setBillingPeriodListView([]);
    setInstituteNumberListView("");
    setTransitNumberListView("");
    setCreatedByListView("");
    setStatusListView([]);
    setScheduleStatus([]);
    setProcessDate(null);
    setReturnDate(null);
    setListViewComments("");
    setTransactionViewPageSize(transactionViewPerPage);
    setSortByListView(null);
    setOrderByListView({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities
  const resetEntities = () => {
    setEntitySelectedListView([]);
    setListViewAccountHolderName("");
    setListViewAccountNumber("");
    setCampaignTagListView([]);
    setChargedAmount("");
    setInvoiceListView("");
    // setEmailListView("");
    setBatchIdListView("");
    setBillingPeriodListView([]);
    setInstituteNumberListView("");
    setTransitNumberListView("");
    setCreatedByListView("");
    setStatusListView([]);
    setScheduleStatus([]);
    setProcessDate(null);
    setReturnDate(null);
    setListViewComments("");
    setTransactionViewPageSize(transactionViewPerPage);
    setSortByListView(null);
    setOrderByListView({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities table view
  const resetEntitiesTableView = () => {
    setEntitySelected([]);
    setAmount("");
    setAccountHolderName("");
    setAccountNumber("");
    setInvoice("");
    // setEmail("");
    setCampaignTag([]);
    setInstituteNumber("");
    setTransitNumber("");
    setStatus([]);
    setCreatedBy("");
    setBillingPeriod([]);
    setComments("");
    setTableViewPageSize(perPage);
    setSortBy(null);
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // add bank account popup
  const [addBankAccountPopup, setAddBankAccountPopup] = useState(false);

  // edit bank account
  const [editBankAccountPopup, setEditBankAccountPopup] = useState(false);
  const [toEditBankAccountObj, setToEditBankAccountObj] = useState(null);

  const handleUpdateBankingDetailsPopup = (obj) => {
    setEditBankAccountPopup(true);
    setToEditBankAccountObj(obj);
  };

  // delete bank account
  const [deleteBankAccountPopup, setDeleteBankAccountPopup] = useState(false);
  const [toDeleteBankAccountObj, setToDeleteBankAccountObj] = useState(null);

  const handleDeleteBankingDetailsPopup = (obj) => {
    setDeleteBankAccountPopup(true);
    setToDeleteBankAccountObj(obj);
  };

  // make account default
  const [makeAccountDefaultPopup, setMakeAccountDefaultPopup] = useState(false);
  const [makeAccountDefaultObj, setMakeAccountDefaultObj] = useState(null);

  const handleMakeAccountDefaultPopup = (obj) => {
    setMakeAccountDefaultPopup(true);
    setMakeAccountDefaultObj(obj);
  };

  // new echeck transaction with readonly bank
  const [newECheckTransactionPopup, setNewECheckTransactionPopup] =
    useState(false);
  const [copiedObj, setCopiedObj] = useState(null);
  const [makeDisabled, setMakeDisabled] = useState(false);

  // more details popup
  const [moreDetailsPopup, setMoreDetailsPopup] = useState(false);
  const [moreDetailsObj, setMoreDetailsObj] = useState(null);

  const handleMoreDetailsPopup = (transaction) => {
    setMoreDetailsPopup(true);
    setMoreDetailsObj(transaction);
  };

  // schedule popup
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [scheduleTag, setScheduleTag] = useState(null);

  const handleSchedulePopup = (tag) => {
    setSchedulePopup(true);
    setScheduleTag(tag);
  };

  // edit echeck transaction
  const [editECheckTransactionPopup, setEditECheckTransactionPopup] =
    useState(false);
  const [editECheckObj, setEditECheckObj] = useState(null);

  const handleEditECheckPopup = (obj) => {
    setEditECheckTransactionPopup(true);
    setEditECheckObj(obj);
  };

  // close echeck popup
  const [closeECheckPopup, setCloseECheckPopup] = useState(false);

  const [closeECheckObj, setCloseECheckObj] = useState(null);
  const handleCloseECheckPopup = (obj) => {
    setCloseECheckPopup(true);
    setCloseECheckObj(obj);
  };

  // add payment
  const [addPaymentPopup, setAddPaymentPopup] = useState(false);

  const [addPaymentObj, setAddPaymentObj] = useState(null);
  const handleAddPaymentPopup = (obj) => {
    setAddPaymentPopup(true);
    setAddPaymentObj(obj);
  };

  // eCheck transaction view

  // transaction details popup
  const [transactionDetailsECheckPopup, setTransactionDetailsECheckPopup] =
    useState(false);
  const [transactionECheckObj, setTransactionECheckObj] = useState(null);

  const handleTransactionDetailsInECheckPopup = (transaction) => {
    setTransactionDetailsECheckPopup(true);
    setTransactionECheckObj(transaction);
  };

  // cancel payment popup
  const [cancelPaymentPopup, setCancelPaymentPopup] = useState(false);
  const [cancelPaymentObj, setCancelPaymentObj] = useState(null);

  const handleCancelPaymentPopup = (obj) => {
    setCancelPaymentPopup(true);
    setCancelPaymentObj(obj);
  };

  // update amount popup
  const [updateAmountPopup, setUpdateAmountPopup] = useState(false);
  const [updateAmountObj, setUpdateAmountObj] = useState(null);

  const handleUpdateAmountPopup = (obj) => {
    setUpdateAmountPopup(true);
    setUpdateAmountObj(obj);
  };

  // update banking details popup
  const [bankingDetailsPopup, setBankingDetailsPopup] = useState(false);

  const [bankingDetailsObj, setBankingDetailsObj] = useState(null);
  const handleBankingDetailsPopup = (obj) => {
    setBankingDetailsPopup(true);
    setBankingDetailsObj(obj);
  };

  return (
    <>
      {updatePhotoPopup && (
        <UpdatePhotoPopup
          token={user.token}
          specificDonorData={specificDonorData}
          setUpdatePhotoPopup={setUpdatePhotoPopup}
          photoURL={photoURL}
          photoFile={photoFile}
        />
      )}
      <Toaster />
      {user && (
        <div className={sidebarToggled ? "dashboard" : "dashboard toggled"}>
          <Header
            sidebarToggled={sidebarToggled}
            setSidebarToggled={setSidebarToggled}
            quickLinksDropdown={quickLinksDropdown}
            setQuickLinksDropdown={setQuickLinksDropdown}
          />
          <div className="content-wrapper">
            <Sidebar sidebarToggled={sidebarToggled} />
            <div className="middle-area">
              <div
                className={`middle-content flex-start ${
                  specificDonorLoading ||
                  specificDonorError ||
                  !specificDonorData
                    ? "height"
                    : ""
                }`}
              >
                {specificDonorLoading ? (
                  <div className="full-loading-screen">
                    <MetroSpinner
                      size={30}
                      color="#007C16"
                      loading={specificDonorLoading}
                    />
                  </div>
                ) : (
                  <>
                    {specificDonorError ? (
                      <div className="error-msg" style={{ marginTop: 0 }}>
                        {specificDonorError}
                      </div>
                    ) : (
                      <>
                        {specificDonorData ? (
                          <div className="donors-details-body">
                            <h6>
                              <Icon icon={chevronLeft} size={24} />
                              <Link
                                to="/donors"
                                style={{
                                  color: "#007c16",
                                  marginBottom: 2 + "px",
                                }}
                              >
                                BACK TO DONORS
                              </Link>
                            </h6>
                            <header className="donor-details-header">
                              <div className="crm-users-info">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="donor-circle"
                                    style={{
                                      backgroundColor:
                                        specificDonorData.status === 1
                                          ? specificDonorData &&
                                            specificDonorData.profileImage &&
                                            isLoaded
                                            ? "#f7f8fa"
                                            : "#007c16"
                                          : "#f2b92c",
                                      border: "1px solid #007c16",
                                    }}
                                  >
                                    {specificDonorData &&
                                    !specificDonorData.profileImage ? (
                                      <h3
                                        style={{
                                          color:
                                            specificDonorData.status === 1
                                              ? "#fff"
                                              : "#000",
                                        }}
                                      >
                                        {specificDonorData.firstName.charAt(0)}{" "}
                                        {specificDonorData.lastName.charAt(0)}
                                      </h3>
                                    ) : (
                                      <>
                                        {!isLoaded && (
                                          <h3
                                            style={{
                                              color:
                                                specificDonorData.status === 1
                                                  ? "#fff"
                                                  : "#000",
                                            }}
                                          >
                                            ...
                                          </h3>
                                        )}
                                        <img
                                          src={specificDonorData.profileImage}
                                          alt="dp"
                                          onLoad={() => setIsLoaded(true)}
                                          style={{
                                            display: !isLoaded
                                              ? "none"
                                              : "block",
                                          }}
                                        />
                                      </>
                                    )}

                                    <div className="upload-or-edit-icon-cirle">
                                      <input
                                        type="file"
                                        id="profileImageUpload"
                                        className="input-file"
                                        accept=".jpeg,.jpg,.png"
                                        onChange={(e) => {
                                          handleProfilePhoto(e.target.files[0]);
                                        }}
                                        onClick={(e) => (e.target.value = null)}
                                      />
                                      <label htmlFor="profileImageUpload">
                                        {!specificDonorData.profileImage ? (
                                          <Icon icon={arrows_plus} size={20} />
                                        ) : (
                                          <Icon
                                            icon={software_pencil}
                                            size={20}
                                          />
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  {fileError && (
                                    <div
                                      className="error-msg"
                                      style={{ width: "fit-content" }}
                                    >
                                      {fileError}
                                    </div>
                                  )}
                                </div>

                                <div className="user-name-email">
                                  <h1 className="media-name">
                                    {specificDonorData.firstName}{" "}
                                    {specificDonorData.middleName !== "" && (
                                      <>{specificDonorData.middleName}</>
                                    )}{" "}
                                    {specificDonorData.lastName}
                                  </h1>
                                  <h4>
                                    <Icon icon={mail} size={18} />{" "}
                                    {specificDonorData.email}
                                    <Icon
                                      icon={phone}
                                      size={18}
                                      style={{ marginLeft: 25 + "px" }}
                                    />{" "}
                                    {formatPhoneNumber(specificDonorData.phone)}
                                  </h4>
                                  <h4 className="h4-last">
                                    <Icon icon={mapPin} size={18} />{" "}
                                    {specificDonorData.streetAddress},{" "}
                                    {specificDonorData.city},{" "}
                                    {specificDonorData.provinceState},{" "}
                                    {specificDonorData.postalZipCode},{" "}
                                    {specificDonorData.country}
                                  </h4>
                                  {specificDonorData.profileImage && (
                                    <MDBBtn
                                      type="button"
                                      className="delete-photo-btn"
                                      onClick={handleDeletePhoto}
                                      disabled={!isLoaded || deletePhotoLoading}
                                    >
                                      {deletePhotoLoading
                                        ? "Removing"
                                        : "Remove Photo"}
                                    </MDBBtn>
                                  )}
                                </div>
                              </div>
                            </header>
                            <div className="tabs-container">
                              <div
                                className={`tab ${
                                  activeTab === "Highlights" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("Highlights")}
                                // style={{
                                //   opacity: highlightsLoadings ? 0.5 : 1,
                                //   pointerEvents: highlightsLoadings ? "none" : "auto",
                                // }}
                              >
                                Highlights
                              </div>
                              <div
                                className={`tab ${
                                  activeTab === "Payments" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("Payments")}
                                style={{
                                  opacity:
                                    // paymentsLoadings ||
                                    selectedOrganization.gatewayNodeTag === 0
                                      ? 0.5
                                      : 1,
                                  pointerEvents:
                                    // paymentsLoadings ||
                                    selectedOrganization.gatewayNodeTag === 0
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                Payments
                              </div>
                              <div
                                className={`tab ${
                                  activeTab === "Transactions" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("Transactions")}
                                // style={{
                                //   opacity: transactionsLoading ? 0.5 : 1,
                                //   pointerEvents: transactionsLoading ? "none" : "auto",
                                // }}
                              >
                                Transactions
                              </div>
                              <div
                                className={`tab ${
                                  activeTab === "Year End Reports"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTabClick("Year End Reports")
                                }
                                // style={{
                                //   opacity: taxReceiptsLoadings ? 0.5 : 1,
                                //   pointerEvents: taxReceiptsLoadings ? "none" : "auto",
                                // }}
                              >
                                Tax Receipts
                              </div>
                              <div
                                className={`tab ${
                                  activeTab === "eCheck" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("eCheck")}
                                style={{
                                  opacity:
                                    // eCheckLoadings ||
                                    selectedOrganization.eCheckNodeTag <= 0
                                      ? 0.5
                                      : 1,
                                  pointerEvents:
                                    // eCheckLoadings ||
                                    selectedOrganization.eCheckNodeTag <= 0
                                      ? "none"
                                      : "auto",
                                  textTransform: "none",
                                }}
                              >
                                eCHECK
                              </div>
                            </div>
                            <>
                              {activeTab === "Highlights" && (
                                <>
                                  {highlightsLoadings ? (
                                    <div className="highlights-loadings">
                                      <MetroSpinner
                                        size={30}
                                        color="#007c16"
                                        loading={highlightsLoadings}
                                      />
                                    </div>
                                  ) : (
                                    <div className="tab-content">
                                      <div className="parent-container">
                                        <div className="box">
                                          <Lifetime />
                                        </div>
                                        <div className="box">
                                          <ThisYear
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                        <div className="box">
                                          <KeyDates />
                                        </div>
                                        <div className="box">
                                          <NMonthSummary
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                      {/* recent transaction */}
                                      <div className="table-container">
                                        <div className="box">
                                          <RecentTransactions
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                      {/* year over years */}
                                      <div className="year-over-years-container">
                                        <div className="box">
                                          <YearOverYears
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                      {/* campaigns summary */}
                                      <div className="campaigns-summary-container">
                                        <div className="box">
                                          <CampaignsSummary
                                            token={user.token}
                                            email={specificDonorData.email}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {activeTab === "Payments" && (
                                <>
                                  {paymentsLoadings ? (
                                    <div className="highlights-loadings">
                                      <MetroSpinner
                                        size={30}
                                        color="#007c16"
                                        loading={paymentsLoadings}
                                      />
                                    </div>
                                  ) : (
                                    <div className="tab-content">
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                      >
                                        <h6>Wallet</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            {walletData?.wallets?.length >=
                                            5 ? (
                                              <Tippy content="Max cards limit reached">
                                                <div
                                                  style={{
                                                    background: "#007c17",
                                                    color: "#fff",
                                                    padding: 8 + "px",
                                                    width: "fit-content",
                                                    margin: 0,
                                                    opacity: 0.5,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Add New Card
                                                </div>
                                              </Tippy>
                                            ) : (
                                              <button
                                                type="button"
                                                className="action-buttons"
                                                onClick={() =>
                                                  setAddWalletPopup(true)
                                                }
                                              >
                                                Add New Card
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <Wallets
                                        handleDeleteWalletPopup={
                                          handleDeleteWalletPopup
                                        }
                                        handleEditWalletPopup={
                                          handleEditWalletPopup
                                        }
                                        handleWalletChargePopup={
                                          handleWalletChargePopup
                                        }
                                      />
                                      {/* recurrings of donor */}
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                        id="recurringSection"
                                      >
                                        <h6>Recurring Payments</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            <button
                                              type="button"
                                              className="action-buttons"
                                              onClick={handleNewRecurringPopup}
                                            >
                                              New Recurring
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="media-row">
                                        <RecurringContainer
                                          token={user.token}
                                          currentPage={currentPage}
                                          perPage={perPage}
                                          totalPages={totalPages}
                                          setCurrentPage={setCurrentPage}
                                          setPerPage={setPerPage}
                                          setTotalPages={setTotalPages}
                                          handleRecurringDetailsPopup={
                                            handleRecurringDetailsPopup
                                          }
                                          handleUpdateCardPopup={
                                            handleUpdateCardPopup
                                          }
                                          handleChangeAmountPopup={
                                            handleChangeAmountPopup
                                          }
                                          handleCloseRecurringPopup={
                                            handleCloseRecurringPopup
                                          }
                                          handleSwitchToActivePopup={
                                            handleSwitchToActivePopup
                                          }
                                          handleSwitchToHoldPopup={
                                            handleSwitchToHoldPopup
                                          }
                                          handleDeclinedTransactionsPopup={
                                            handleDeclinedTransactionsPopup
                                          }
                                          handleHistoryNotesPopup={
                                            handleHistoryNotesPopup
                                          }
                                          donorSpecific={donorSpecific}
                                          email={specificDonorData.email}
                                          triggerScroll={triggerScroll}
                                          setLoadings={setPaymentsLoadings}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {activeTab === "Transactions" && (
                                <>
                                  {transactionsLoadings ? (
                                    <div className="highlights-loadings">
                                      <MetroSpinner
                                        size={30}
                                        color="#007c16"
                                        loading={transactionsLoadings}
                                      />
                                    </div>
                                  ) : (
                                    <div className="tab-content">
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                      >
                                        <h6>Transactions</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            <MDBDropdown>
                                              <MDBDropdownToggle className="organizations-dropdown-button">
                                                NEW
                                              </MDBDropdownToggle>
                                              <MDBDropdownMenu>
                                                <MDBDropdownItem
                                                  link
                                                  onClick={
                                                    handleManualTransactionsPopup
                                                  }
                                                >
                                                  <span>
                                                    Manual Transaction
                                                  </span>
                                                </MDBDropdownItem>
                                                <MDBDropdownItem
                                                  link
                                                  disabled={
                                                    selectedOrganization.gatewayNodeTag ===
                                                    0
                                                  }
                                                  onClick={
                                                    handleCardTransactionsPopup
                                                  }
                                                >
                                                  <span>Card Transaction</span>
                                                </MDBDropdownItem>
                                              </MDBDropdownMenu>
                                            </MDBDropdown>
                                          </div>
                                          <div className="filter-and-reset-btns-div">
                                            {/* export records */}
                                            <Tippy
                                              content={
                                                exportTransactionLoading
                                                  ? "Please Wait"
                                                  : "Export Transactions"
                                              }
                                            >
                                              <button
                                                className="filter-media-modal-btn"
                                                type="button"
                                                onClick={handleExportRecords}
                                              >
                                                {exportTransactionLoading ? (
                                                  <MetroSpinner
                                                    loading={
                                                      exportTransactionLoading
                                                    }
                                                    size={24}
                                                    color="#6a6c6f"
                                                  />
                                                ) : (
                                                  <Icon
                                                    icon={arrowUp}
                                                    size={24}
                                                  />
                                                )}
                                              </button>
                                            </Tippy>
                                          </div>
                                        </div>
                                      </div>
                                      {/* transaction table */}
                                      <TransactionsTable
                                        token={user.token}
                                        setLoadings={setTransactionsLoadings}
                                        currentPage={currentPageTransactions}
                                        perPage={perPageTransactions}
                                        totalPages={totalPagesTransactions}
                                        setCurrentPage={
                                          setCurrentPageTransactions
                                        }
                                        setPerPage={setPerPageTransactions}
                                        setTotalPages={
                                          setTotalPagesTransactions
                                        }
                                        handleTransactionDetailsPopup={
                                          handleTransactionDetailsPopup
                                        }
                                        handleUpdateCampaignPopup={
                                          handleUpdateCampaignPopup
                                        }
                                        handleDeleteTransactionsPopup={
                                          handleDeleteTransactionsPopup
                                        }
                                        handleEditTransactionPopup={
                                          handleEditTransactionPopup
                                        }
                                        handleTransactionTypePopup={
                                          handleTransactionTypePopup
                                        }
                                        transactionTypePopup={
                                          transactionTypePopup
                                        }
                                        donorSpecific={donorSpecific}
                                        email={specificDonorData.email}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              {activeTab === "Year End Reports" && (
                                <>
                                  {taxReceiptsLoadings ? (
                                    <div className="highlights-loadings">
                                      <MetroSpinner
                                        size={30}
                                        color="#007c16"
                                        loading={taxReceiptsLoadings}
                                      />
                                    </div>
                                  ) : (
                                    <div className="tab-content">
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                      >
                                        <h6>Records</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            <button
                                              type="button"
                                              className="action-buttons"
                                              onClick={() =>
                                                setNewReceiptPopup(true)
                                              }
                                            >
                                              New Receipt
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <TaxReceiptTable
                                        token={user.token}
                                        perPage={perPageTaxReceipts}
                                        setPerPage={setPerPageTaxReceipts}
                                        currentPage={currentPageTaxReceipts}
                                        setCurrentPage={
                                          setCurrentPageTaxReceipts
                                        }
                                        totalPages={totalPagesTaxReceipts}
                                        setTotalPages={setTotalPagesTaxReceipts}
                                        setLoadings={setTaxReceiptsLoadings}
                                        donorSpecific={donorSpecific}
                                        donorSpecificEmail={
                                          specificDonorData.email
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              {activeTab === "eCheck" && (
                                <>
                                  {eCheckLoadings ? (
                                    <div className="highlights-loadings">
                                      <MetroSpinner
                                        size={30}
                                        color="#007c16"
                                        loading={eCheckLoadings}
                                      />
                                    </div>
                                  ) : (
                                    <div className="tab-content">
                                      <div
                                        className="media-heading-button-container"
                                        style={{ marginTop: 35 + "px" }}
                                      >
                                        <h6>Bank Accounts</h6>
                                        <div className="add-and-filter-btns-div">
                                          <div className="action-btns-div">
                                            <button
                                              type="button"
                                              className="action-buttons"
                                              onClick={() =>
                                                setAddBankAccountPopup(true)
                                              }
                                            >
                                              New Account
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <BankingDetails
                                        handleUpdateBankingDetailsPopup={
                                          handleUpdateBankingDetailsPopup
                                        }
                                        handleDeleteBankingDetailsPopup={
                                          handleDeleteBankingDetailsPopup
                                        }
                                        handleMakeAccountDefaultPopup={
                                          handleMakeAccountDefaultPopup
                                        }
                                        setNewECheckTransactionPopup={
                                          setNewECheckTransactionPopup
                                        }
                                        setCopiedObj={setCopiedObj}
                                        setMakeDisabled={setMakeDisabled}
                                      />

                                      <div id="eCheckSection">
                                        <div className="media-heading-button-container">
                                          <h6>Records</h6>
                                          <div className="add-and-filter-btns-div">
                                            <div className="action-btns-div">
                                              <button
                                                type="button"
                                                className="action-buttons"
                                                onClick={() => {
                                                  setNewECheckTransactionPopup(
                                                    true
                                                  );
                                                  setCopiedObj(null);
                                                  setMakeDisabled(false);
                                                }}
                                                style={{
                                                  opacity:
                                                    layout === "list" ? 0.5 : 1,
                                                  pointerEvents:
                                                    layout === "list"
                                                      ? "none"
                                                      : "auto",
                                                }}
                                              >
                                                New
                                              </button>
                                            </div>
                                            <div className="filter-and-reset-btns-div">
                                              <Tippy content="Detail View">
                                                <button
                                                  className={`filter-media-modal-btn reset ${
                                                    layout === "table"
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                  type="button"
                                                  onClick={() => {
                                                    if (layout !== "table") {
                                                      setECheckLoadings(true);
                                                      setLayout("table");
                                                      // setTransactionViewFilteredParams(
                                                      //   null
                                                      // );
                                                      // resetEntities();
                                                    }
                                                  }}
                                                >
                                                  <Icon icon={list} size={24} />
                                                </button>
                                              </Tippy>
                                              <Tippy content="Transaction View">
                                                <button
                                                  className={`filter-media-modal-btn reset ${
                                                    layout === "list"
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                  type="button"
                                                  onClick={() => {
                                                    if (layout !== "list") {
                                                      setECheckLoadings(true);
                                                      setLayout("list");
                                                      // setTableViewFilteredParams(
                                                      //   null
                                                      // );
                                                      // resetEntitiesTableView();
                                                    }
                                                  }}
                                                >
                                                  <Icon
                                                    icon={creditCard}
                                                    size={24}
                                                  />
                                                </button>
                                              </Tippy>

                                              {/* layout grid filter and reset button */}
                                              {layout === "table" && (
                                                <>
                                                  <Tippy content="Filter Records">
                                                    <button
                                                      className="filter-media-modal-btn reset"
                                                      type="button"
                                                      onClick={() =>
                                                        setTableViewFilterModal(
                                                          true
                                                        )
                                                      }
                                                    >
                                                      <Icon
                                                        icon={filter}
                                                        size={24}
                                                      />
                                                    </button>
                                                  </Tippy>
                                                  {tableViewFilteredParams && (
                                                    <Tippy content="Reset Records">
                                                      <button
                                                        className="filter-media-modal-btn reset"
                                                        type="button"
                                                        onClick={
                                                          handleTableViewResetFilter
                                                        }
                                                      >
                                                        <Icon
                                                          icon={refreshCw}
                                                          size={24}
                                                        />
                                                      </button>
                                                    </Tippy>
                                                  )}
                                                </>
                                              )}
                                              {/* layout list filter and reset button */}
                                              {layout === "list" && (
                                                <>
                                                  <Tippy content="Filter Records">
                                                    <button
                                                      className="filter-media-modal-btn reset"
                                                      type="button"
                                                      onClick={() =>
                                                        setTransactionViewFilterModal(
                                                          true
                                                        )
                                                      }
                                                    >
                                                      <Icon
                                                        icon={filter}
                                                        size={24}
                                                      />
                                                    </button>
                                                  </Tippy>
                                                  {transactionViewFilteredParams && (
                                                    <Tippy content="Reset Records">
                                                      <button
                                                        className="filter-media-modal-btn reset"
                                                        type="button"
                                                        onClick={
                                                          handleTransactionViewResetFilter
                                                        }
                                                      >
                                                        <Icon
                                                          icon={refreshCw}
                                                          size={24}
                                                        />
                                                      </button>
                                                    </Tippy>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        {/* table layout (same grid data in table layout) */}
                                        {layout === "table" && (
                                          <>
                                            {eCheckError ? (
                                              <div
                                                className="error-msg"
                                                style={{ width: "fit-content" }}
                                              >
                                                {eCheckError}
                                              </div>
                                            ) : (
                                              <DetailView
                                                token={user.token}
                                                donorSpecific={donorSpecific}
                                                email={specificDonorData.email}
                                                triggerScroll={triggerScroll}
                                                setLoadings={setECheckLoadings}
                                                filteredParams={
                                                  tableViewFilteredParams
                                                }
                                                perPage={tableViewPerPage}
                                                setPerPage={setTableViewPerPage}
                                                currentPage={
                                                  tableViewCurrentPage
                                                }
                                                setCurrentPage={
                                                  setTableViewCurrentPage
                                                }
                                                totalPages={tableViewTotalPages}
                                                setTotalPages={
                                                  setTableViewTotalPages
                                                }
                                                setPageSize={
                                                  setTableViewPageSize
                                                }
                                                handleMoreDetailsPopup={
                                                  handleMoreDetailsPopup
                                                }
                                                handleSchedulePopup={
                                                  handleSchedulePopup
                                                }
                                                handleEditECheckPopup={
                                                  handleEditECheckPopup
                                                }
                                                handleCloseECheckPopup={
                                                  handleCloseECheckPopup
                                                }
                                                handleAddPaymentPopup={
                                                  handleAddPaymentPopup
                                                }
                                                setNewECheckTransactionPopup={
                                                  setNewECheckTransactionPopup
                                                }
                                                setCopiedObj={setCopiedObj}
                                                setMakeDisabled={
                                                  setMakeDisabled
                                                }
                                              />
                                            )}
                                          </>
                                        )}
                                        {/* transaction layout */}
                                        {layout === "list" && (
                                          <>
                                            {listViewError ? (
                                              <div
                                                className="error-msg"
                                                style={{ width: "fit-content" }}
                                              >
                                                {listViewError}
                                              </div>
                                            ) : (
                                              <ECheckTable
                                                token={user.token}
                                                donorSpecific={donorSpecific}
                                                email={specificDonorData.email}
                                                triggerScroll={triggerScroll}
                                                listViewFilteredParams={
                                                  transactionViewFilteredParams
                                                }
                                                listViewPerPage={
                                                  transactionViewPerPage
                                                }
                                                setListViewPerPage={
                                                  setTransactionViewPerPage
                                                }
                                                listViewCurrentPage={
                                                  transactionViewCurrentPage
                                                }
                                                setListViewCurrentPage={
                                                  setTransactionViewCurrentPage
                                                }
                                                listViewTotalPages={
                                                  transactionViewTotalPages
                                                }
                                                setListViewTotalPages={
                                                  setTransactionViewTotalPages
                                                }
                                                setListViewPageSize={
                                                  setTransactionViewPageSize
                                                }
                                                handleCancelPaymentPopup={
                                                  handleCancelPaymentPopup
                                                }
                                                handleUpdateAmountPopup={
                                                  handleUpdateAmountPopup
                                                }
                                                handleTransactionDetailsPopup={
                                                  handleTransactionDetailsInECheckPopup
                                                }
                                                handleAddPaymentPopup={
                                                  handleAddPaymentPopup
                                                }
                                                handleBankingDetailsPopup={
                                                  handleBankingDetailsPopup
                                                }
                                                setLoadings={setECheckLoadings}
                                              />
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        ) : (
                          <div className="full-page-center">
                            <Tippy content="No Donor Found">
                              <img
                                src={noDataFound}
                                alt="No Donor Found"
                                width={`100px`}
                                height={`auto`}
                              />
                            </Tippy>

                            <Link
                              to="/donors"
                              style={{ marginTop: 15 + "px", color: "#007c16" }}
                            >
                              Return to donors
                            </Link>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* add wallet */}
      {addWalletPopup && (
        <AddWalletPopus
          token={user.token}
          setAddWalletPopup={setAddWalletPopup}
          email={specificDonorData.email}
        />
      )}
      {/* delete wallet */}
      {deleteWalletPopup && (
        <DeleteWalletPopup
          token={user.token}
          setDeleteWalletPopup={setDeleteWalletPopup}
          email={specificDonorData.email}
          deleteWalletObj={deleteWalletObj}
        />
      )}
      {/* edit wallet */}
      {editWalletPopup && (
        <EditWalletPopup
          token={user.token}
          setEditWalletPopup={setEditWalletPopup}
          editWalletObj={editWalletObj}
          email={specificDonorData.email}
        />
      )}
      {/* wallet charge popup */}
      {walletChargePopup && (
        <WalletChargePopup
          token={user.token}
          setWalletChargePopup={setWalletChargePopup}
          walletObj={walletObj}
          email={specificDonorData.email}
          campaignOptions={campaignOptions}
          setActiveTab={setActiveTab}
        />
      )}
      {/* new recurring */}
      {newRecurringPopup && (
        <CardTransactionsPopup
          token={user.token}
          emailAvailable={specificDonorData.email}
          setCardTransactionsPopup={setNewRecurringPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          oneTime={oneTime}
          triggerScroll={triggerScroll}
        />
      )}
      {/* recurring details */}
      {recurringDetailsPopup && (
        <RecurringDetailsPopup
          token={user.token}
          recurringObj={recurringObj}
          setRecurringDetailsPopup={setRecurringDetailsPopup}
        />
      )}
      {/* declined transactions */}
      {declinedTransactionsPopup && (
        <DeclinedTransactionsPopup
          setDeclinedTransactionsPopup={setDeclinedTransactionsPopup}
          declinedTransactionsObj={declinedTransactionsObj}
        />
      )}
      {/* history notes */}
      {historyNotesPopup && (
        <HistoryNotesPopup
          setHistoryNotesPopup={setHistoryNotesPopup}
          historyNotesObj={historyNotesObj}
        />
      )}
      {/* update card */}
      {updateCardPopup && (
        <UpdateCardPopup
          token={user.token}
          setUpdateCardPopup={setUpdateCardPopup}
          updateCardObj={updateCardObj}
        />
      )}
      {/* change amount */}
      {changeAmountPopup && (
        <ChangeAmountPopup
          token={user.token}
          setChangeAmountPopup={setChangeAmountPopup}
          changeAmountObj={changeAmountObj}
        />
      )}
      {/* close recurring popup */}
      {closeRecurringPopup && (
        <CloseRecurringPopup
          token={user.token}
          setCloseRecurringPopup={setCloseRecurringPopup}
          closeRecurringObj={closeRecurringObj}
        />
      )}
      {/* switch to active popup */}
      {switchToActivePopup && (
        <SwitchToActivePopup
          token={user.token}
          setSwitchToActivePopup={setSwitchToActivePopup}
          switchToActiveObj={switchToActiveObj}
        />
      )}
      {/* switch to hold popup */}
      {switchToHoldPopup && (
        <SwitchToHoldPopup
          token={user.token}
          setSwitchToHoldPopup={setSwitchToHoldPopup}
          switchToHoldObj={switchToHoldObj}
        />
      )}
      {manualTransactionsPopup && (
        <ManualTransactionsPopup
          token={user.token}
          emailAvailable={specificDonorData.email}
          setManualTransactionsPopup={setManualTransactionsPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          donorSpecific={donorSpecific}
        />
      )}
      {cardTransactionsPopup && (
        <CardTransactionsPopup
          token={user.token}
          emailAvailable={specificDonorData.email}
          setCardTransactionsPopup={setCardTransactionsPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          oneTime={true}
        />
      )}
      {transactionDetailsPopup && (
        <TransactionDetailsPopup
          token={user.token}
          setTransactionDetailsPopup={setTransactionDetailsPopup}
          transactionObj={transactionObj}
        />
      )}
      {updateCampaignPopup && (
        <UpdateCampaignPopup
          token={user.token}
          setUpdateCampaignPopup={setUpdateCampaignPopup}
          updateCampaignObj={updateCampaignObj}
          allCampaigns={allCampaigns}
        />
      )}
      {deleteTransactionPopup && (
        <DeleteTransactionPopup
          setDeleteTransactionPopup={setDeleteTransactionPopup}
          deleteTransactionObj={deleteTransactionObj}
          token={user.token}
          perPage={perPageTransactions}
          setCurrentPage={setCurrentPageTransactions}
          setPerPage={setPerPageTransactions}
          setTotalPages={setTotalPagesTransactions}
          currentPage={currentPageTransactions}
        />
      )}
      {/* transaction type refund */}
      {transactionTypePopup && (
        <RefundPopup
          token={user.token}
          setTransactionTypePopup={setTransactionTypePopup}
          transactionTypeObj={transactionTypeObj}
        />
      )}
      {/* manual transaction edit */}
      {editTransactionPopup && (
        <ManualEditTransactionPopup
          token={user.token}
          setEditTransactionPopup={setEditTransactionPopup}
          editTransactionObj={editTransactionObj}
          campaignOptions={campaignOptions}
          donorSpecific={donorSpecific}
        />
      )}
      {/* new receipt */}
      {newReceiptPopup && (
        <NewReceiptPopup
          token={user.token}
          setNewReceiptPopup={setNewReceiptPopup}
          yearOptions={yearOptions}
          donorSpecific={donorSpecific}
          donorSpecificEmail={specificDonorData.email}
        />
      )}

      {/* eCheck */}

      {/* bank */}

      {/* new echeck transaction popup */}
      {newECheckTransactionPopup && (
        <AddECheckTransactionPopup
          token={user.token}
          setNewECheckTransactionPopup={setNewECheckTransactionPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          frequencyOptions={frequencyOptions}
          instituteOptions={instituteOptions}
          copiedObj={copiedObj}
          setCopiedObj={setCopiedObj}
          donorSpecificEmail={specificDonorData.email}
          triggerScroll={triggerScroll}
          makeDisabled={makeDisabled}
        />
      )}

      {/* add bank account */}
      {addBankAccountPopup && (
        <AddBankAccount
          token={user.token}
          setAddBankAccountPopup={setAddBankAccountPopup}
          instituteOptions={instituteOptions}
          email={specificDonorData.email}
        />
      )}

      {/* edit bank account */}
      {editBankAccountPopup && (
        <EditBankAccount
          token={user.token}
          setEditBankAccountPopup={setEditBankAccountPopup}
          toEditBankAccountObj={toEditBankAccountObj}
          instituteOptions={instituteOptions}
          email={specificDonorData.email}
        />
      )}

      {/* delete bank account */}
      {deleteBankAccountPopup && (
        <DeleteBankAccountPopup
          token={user.token}
          setDeleteBankAccountPopup={setDeleteBankAccountPopup}
          toDeleteBankAccountObj={toDeleteBankAccountObj}
        />
      )}

      {/* make account default */}
      {makeAccountDefaultPopup && (
        <MakeDefaultPopup
          token={user.token}
          setMakeAccountDefaultPopup={setMakeAccountDefaultPopup}
          makeAccountDefaultObj={makeAccountDefaultObj}
        />
      )}

      {/* table view */}

      {/* more details */}
      {moreDetailsPopup && (
        <MoreDetailsPopup
          setMoreDetailsPopup={setMoreDetailsPopup}
          moreDetailsObj={moreDetailsObj}
        />
      )}

      {/* schedule */}
      {schedulePopup && (
        <SchedulePopup
          token={user.token}
          setSchedulePopup={setSchedulePopup}
          scheduleTag={scheduleTag}
        />
      )}

      {/* edit eCheck transaction popup */}
      {editECheckTransactionPopup && (
        <EditECheckTransactionPopup
          token={user.token}
          donorSpecific={donorSpecific}
          setEditECheckTransactionPopup={setEditECheckTransactionPopup}
          editECheckObj={editECheckObj}
          campaignOptions={campaignOptions}
          frequencyOptions={frequencyOptions}
          instituteOptions={instituteOptions}
        />
      )}

      {/* add payment popup */}
      {addPaymentPopup && (
        <AddPaymentPopup
          token={user.token}
          setAddPaymentPopup={setAddPaymentPopup}
          addPaymentObj={addPaymentObj}
          layout={layout}
        />
      )}

      {/* close echeck popup */}
      {closeECheckPopup && (
        <CloseECheckPopup
          token={user.token}
          setCloseECheckPopup={setCloseECheckPopup}
          closeECheckObj={closeECheckObj}
        />
      )}

      {/* transaction view */}

      {/* transaction details */}
      {transactionDetailsECheckPopup && (
        <ListViewTransactionDetailsPopup
          token={user.token}
          setTransactionDetailsPopup={setTransactionDetailsECheckPopup}
          transactionObj={transactionECheckObj}
        />
      )}

      {/* cancel payment */}
      {cancelPaymentPopup && (
        <CancelPaymentPopup
          token={user.token}
          setCancelPaymentPopup={setCancelPaymentPopup}
          cancelPaymentObj={cancelPaymentObj}
        />
      )}

      {/* update amount */}
      {updateAmountPopup && (
        <UpdateAmountPopup
          token={user.token}
          setUpdateAmountPopup={setUpdateAmountPopup}
          updateAmountObj={updateAmountObj}
        />
      )}

      {/* update banking details */}
      {bankingDetailsPopup && (
        <UpdateBankingDetailsPopup
          token={user.token}
          setBankingDetailsPopup={setBankingDetailsPopup}
          bankingDetailsObj={bankingDetailsObj}
          instituteOptions={instituteOptions}
          listViewFilteredParams={transactionViewFilteredParams}
          setListViewCurrentPage={setTransactionViewCurrentPage}
          setListViewPerPage={setTransactionViewPerPage}
          setListViewPageSize={setTransactionViewPageSize}
          setListViewTotalPages={setTransactionViewTotalPages}
          donorSpecific={donorSpecific}
          donorSpecificEmail={specificDonorData.email}
          triggerScroll={triggerScroll}
        />
      )}

      {/* filters */}

      {/* table view */}
      {tableViewFilterModal && (
        <FilterECheckModal
          setFilterECheckModal={setTableViewFilterModal}
          setFilteredParams={setTableViewFilteredParams}
          entityOptions={entityOptions}
          entitySelected={entitySelected}
          handleSelectChange={handleSelectChange}
          amount={amount}
          handleAmountChange={handleAmountChange}
          accountHolderName={accountHolderName}
          setAccountHolderName={setAccountHolderName}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          campaignTag={campaignTag}
          handleCampaignTagChange={handleCampaignTagChange}
          email={email}
          setEmail={setEmail}
          invoice={invoice}
          setInvoice={setInvoice}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          status={status}
          statusOptions={statusOptions}
          handleStatusChange={handleStatusChange}
          instituteNumber={instituteNumber}
          setInstituteNumber={setInstituteNumber}
          instituteOptions={instituteOptions}
          transitNumber={transitNumber}
          setTransitNumber={setTransitNumber}
          billingPeriod={billingPeriod}
          handleBillingPeriodChange={handleBillingPeriodChange}
          frequencyOptions={frequencyOptions}
          comments={comments}
          setComments={setComments}
          pageSize={tableViewPageSize}
          handlePerPage={handleTableViewPerPage}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setLoadings={setECheckLoadings}
          handleResetFilter={handleTableViewResetFilter}
          donorSpecific={donorSpecific}
        />
      )}

      {/* transaction view */}
      {transactionViewFilterModal && (
        <FilterListViewModal
          setFilterListViewModal={setTransactionViewFilterModal}
          setListViewFilteredParams={setTransactionViewFilteredParams}
          listViewEntityOptions={listViewEntityOptions}
          entitySelectedListView={entitySelectedListView}
          handleSelectChangeListView={handleSelectChangeListView}
          listViewAccountHolderName={listViewAccountHolderName}
          setListViewAccountHolderName={setListViewAccountHolderName}
          listViewAccountNumber={listViewAccountNumber}
          setListViewAccountNumber={setListViewAccountNumber}
          chargedAmount={chargedAmount}
          handleChargedAmountChange={handleChargedAmountChange}
          campaignTagListView={campaignTagListView}
          handleCampaignTagChangeListView={handleCampaignTagChangeListView}
          emailListView={emailListView}
          setEmailListView={setEmailListView}
          invoiceListView={invoiceListView}
          setInvoiceListView={setInvoiceListView}
          batchIdListView={batchIdListView}
          setBatchIdListView={setBatchIdListView}
          createdByListView={createdByListView}
          setCreatedByListView={setCreatedByListView}
          billingPeriodListView={billingPeriodListView}
          handleBillingPeriodChangeListView={handleBillingPeriodChangeListView}
          frequencyOptions={frequencyOptions}
          statusListView={statusListView}
          handleStatusChangeListView={handleStatusChangeListView}
          statusOptions={statusOptions}
          scheduleStatus={scheduleStatus}
          handleScheduleStatusChange={handleScheduleStatusChange}
          scheduleStatusOptions={scheduleStatusOptions}
          instituteNumberListView={instituteNumberListView}
          setInstituteNumberListView={setInstituteNumberListView}
          instituteOptions={instituteOptions}
          transitNumberListView={transitNumberListView}
          setTransitNumberListView={setTransitNumberListView}
          processDate={processDate}
          setProcessDate={setProcessDate}
          returnDate={returnDate}
          setReturnDate={setReturnDate}
          listViewComments={listViewComments}
          setListViewComments={setListViewComments}
          listViewPageSize={transactionViewPageSize}
          handlePerPageInListView={handleTransactionViewPerPage}
          sortByListView={sortByListView}
          setSortByListView={setSortByListView}
          orderByListView={orderByListView}
          setOrderByListView={setOrderByListView}
          setLoadings={setECheckLoadings}
          handleListViewResetFilter={handleTransactionViewResetFilter}
          donorSpecific={donorSpecific}
        />
      )}
    </>
  );
};

export default DonorDetails;
