/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/Organization.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getOrganizationData,
  updateOrganization,
} from "../../Store/Slices/Sidebar/Organization/OrganizationSlice";
import toast, { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import axios from "axios";
import hostName from "../../config";
import OrganizationDetailsForm from "../Organization/OrganizationDetailsForm";
import CharityDetailsForm from "../Organization/CharityDetailsForm";
import ContactDetailsForm from "../Organization/ContactDetailsForm";
import SignaturePopup from "../Organization/SignaturePopup";

const OrganizationComponent = ({ token }) => {
  // redux state
  const {
    organizationLoading,
    organizationData,
    organizationError,
    updateOrganizationLoading,
  } = useSelector((state) => state.organization);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get organization data
  useEffect(() => {
    dispatch(getOrganizationData(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // tabs
  const [activeTab, setActiveTab] = useState("Organization");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // all api options data states
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);

  // getting all options at once
  useEffect(() => {
    Promise.all([
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
    ])
      .then(([countriesRes, usaStatesRes, provincesRes]) => {
        // Handle countries
        const countryOptions = countriesRes.data.map((country) => ({
          value: country.code,
          label: country.name,
        }));
        setCountries(countryOptions);

        // Handle USA states
        const usaStatesOptions = usaStatesRes.data.map((state) => ({
          value: state.code,
          label: state.name,
        }));
        setUsaStates(usaStatesOptions);

        // Handle Canada provinces
        const provincesOptions = provincesRes.data.map((province) => ({
          value: province.code,
          label: province.name,
        }));
        setProvinces(provincesOptions);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [organizationLoading, allOptionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // signature popup
  const [signaturePopup, setSignaturePopup] = useState(false);

  // organization states
  const [organization, setOrganization] = useState({
    // organization
    name: "",
    logo: "",
    streetAddress: "",
    unitNumber: "",
    city: "",
    country: "",
    provinceState: "",
    postalZipCode: "",
    phone: "",
    formattedPhone: "",
    email: "",
    website: "",
    receiptFooter: "",
    footerNote: "",
    receiptAlertEmail: "",
    locationName: "",
    signUpDate: "",
    timezone: "",
    shortName: "",
    portalUrl: "",
    forceRegistration: "",
    updateDateTime: "",
    // charity
    charityOrganization: "",
    charityStreetAddress: "",
    charityUnitNumber: "",
    charityCity: "",
    charityProvinceState: "",
    charityPostalZipCode: "",
    charityRegisterationNumber: "",
    taxReceiptFooter: "",
    taxReceiptStartDate: "",
    signatureImage: "",
    signatureImageBytes: "",
    signatureImageType: "",
    generateSignature: "",
    // contact
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    formattedContactPhone: "",
  });

  // format raw phone to formatted
  const formatPhoneNumber = (value) => {
    if (value) {
      if (value.length <= 10) {
        // Remove all non-numeric characters
        const digits = value.replace(/\D/g, "");

        // Format as (555) 555-1234
        if (digits.length <= 3) {
          return `(${digits}`;
        } else if (digits.length <= 6) {
          return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
        } else {
          return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
            6,
            10
          )}`;
        }
      } else {
        return value;
      }
    } else {
      return "";
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;

    // Remove all non-numeric characters
    const digits = input.replace(/\D/g, "");

    // Restrict length to 10 digits for North American numbers
    if (digits.length <= 10) {
      const formatted = formatPhoneNumber(digits);
      setOrganization((prevOrg) => ({
        ...prevOrg,
        phone: digits,
      }));
      setOrganization((prevOrg) => ({
        ...prevOrg,
        formattedPhone: formatted,
      }));
    }
  };

  const handleContactPhoneChange = (e) => {
    const input = e.target.value;

    // Remove all non-numeric characters
    const digits = input.replace(/\D/g, "");

    // Restrict length to 10 digits for North American numbers
    if (digits.length <= 10) {
      const formatted = formatPhoneNumber(digits);
      setOrganization((prevOrg) => ({
        ...prevOrg,
        contactPhone: digits,
      }));
      setOrganization((prevOrg) => ({
        ...prevOrg,
        formattedContactPhone: formatted,
      }));
    }
  };

  // Update local state when organizationData changes
  useEffect(() => {
    setOrganization({
      // organization
      name: organizationData?.name || "",
      logo: organizationData?.logo || "",
      streetAddress: organizationData?.streetAddress || "",
      unitNumber: organizationData?.unitNumber || "",
      city: organizationData?.city || "",
      country: organizationData?.country || "",
      provinceState: organizationData?.provinceState || "",
      postalZipCode: organizationData?.postalZipCode || "",
      phone: organizationData?.phone || "",
      formattedPhone: formatPhoneNumber(organizationData?.phone || "") || "",
      email: organizationData?.email || "",
      website: organizationData?.website || "",
      receiptFooter: organizationData?.receiptFooter || "",
      footerNote: organizationData?.footerNote || "",
      receiptAlertEmail: organizationData?.receiptAlertEmail || "",
      locationName: organizationData?.locationName || "",
      signUpDate: organizationData?.signupDate || "",
      timezone: organizationData?.timezone || "",
      shortName: organizationData?.shortName || "",
      portalUrl: organizationData?.portalUrl || "",
      forceRegistration: organizationData?.forceRegistration || "",
      updateDateTime: organizationData?.updateDateTime || "",
      // charity
      charityOrganization: organizationData?.charityOrganization || "",
      charityStreetAddress: organizationData?.charityStreetAddress || "",
      charityUnitNumber: organizationData?.charityUnitNumber || "",
      charityCity: organizationData?.charityCity || "",
      charityProvinceState: organizationData?.charityProvinceState || "",
      charityPostalZipCode: organizationData?.charityPostalZipCode || "",
      charityRegisterationNumber:
        organizationData?.charityRegistrationNumber || "",
      taxReceiptFooter: organizationData?.taxReceiptFooter || "",
      taxReceiptStartDate: organizationData?.taxReceiptStartDate
        ? (() => {
            const parts = organizationData.taxReceiptStartDate.split("-");
            if (parts.length === 3) {
              const year = parseInt(parts[0], 10);
              const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
              const day = parseInt(parts[2], 10);
              return new Date(year, month, day);
            }
          })()
        : "",

      signatureImage: organizationData?.signatureImage || "",
      signatureImageBytes: organizationData?.signatureImageBytes || "",
      signatureImageType: organizationData?.signatureImageType || "",
      generateSignature: organizationData?.generateSignature || "",
      // contact
      contactName: organizationData?.contactName || "",
      contactEmail: organizationData?.contactEmail || "",
      contactPhone: organizationData?.contactPhone || "",
      formattedContactPhone:
        formatPhoneNumber(organizationData?.contactPhone || "") || "",
    });
  }, [organizationData]);

  // format charity tax receipt start date
  const formatDate = (dateObj) => {
    if (dateObj && dateObj !== "") {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  };

  // organization details update
  const handleOrganizationDetailsSubmit = (e) => {
    e.preventDefault();
    const singleObj = {
      // general
      token,
      tagNumber: organizationData.tagNumber,
      // organization
      name:
        organization.name !== "" ? organization.name : organizationData.name,
      phone: organization.phone,
      email: organization.email,
      website: organization.website,
      streetAddress:
        organization.streetAddress !== ""
          ? organization.streetAddress
          : organizationData.streetAddress,
      unitNumber: organization.unitNumber,
      city:
        organization.city !== "" ? organization.city : organizationData.city,
      country:
        organization.country !== ""
          ? organization.country
          : organizationData.country,
      provinceState:
        organization.provinceState !== ""
          ? organization.provinceState
          : organizationData.provinceState,
      postalZipCode:
        organization.postalZipCode !== ""
          ? organization.postalZipCode
          : organizationData.postalZipCode,
      locationName: organization.locationName,
      receiptAlertEmail: organization.receiptAlertEmail,
      receiptFooter: organization.receiptFooter,
      footerNote: organization.footerNote,
      forceRegistration: organization.forceRegistration,
      // charity
      charityOrganization:
        organization.charityRegisterationNumber !== ""
          ? organization.charityOrganization !== ""
            ? organization.charityOrganization
            : organizationData.charityOrganization
          : organization.charityOrganization,
      charityStreetAddress:
        organization.charityRegisterationNumber !== ""
          ? organization.charityStreetAddress !== ""
            ? organization.charityStreetAddress
            : organizationData.charityStreetAddress
          : organization.charityStreetAddress,
      charityUnitNumber: organization.charityUnitNumber,
      charityCity:
        organization.charityRegisterationNumber !== ""
          ? organization.charityCity !== ""
            ? organization.charityCity
            : organizationData.charityCity
          : organization.charityCity,
      charityProvinceState:
        organization.charityRegisterationNumber !== ""
          ? organization.charityProvinceState !== ""
            ? organization.charityProvinceState
            : organizationData.charityProvinceState
          : organization.charityProvinceState,
      charityPostalZipCode:
        organization.charityRegisterationNumber !== ""
          ? organization.charityPostalZipCode !== ""
            ? organization.charityPostalZipCode
            : organizationData.charityPostalZipCode
          : organization.charityPostalZipCode,
      charityRegisterationNumber: organization.charityRegisterationNumber,
      taxReceiptFooter: organization.taxReceiptFooter,
      taxReceiptStartDate: formatDate(organization.taxReceiptStartDate),
      // contact
      contactName:
        organization.contactName !== ""
          ? organization.contactName
          : organizationData.contactName,
      contactEmail: organization.contactEmail,
      contactPhone: organization.contactPhone,
    };
    dispatch(updateOrganization(singleObj)).then((res) => {
      if (res.payload.successMsg) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              {organizationError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {organizationError}
                </div>
              ) : (
                <>
                  {organizationData ? (
                    <div className="manage-organization">
                      <div className="tabs-container">
                        <div
                          className={`tab ${
                            activeTab === "Organization" ? "active" : ""
                          }`}
                          style={{
                            opacity: updateOrganizationLoading ? 0.5 : 1,
                            pointerEvents: updateOrganizationLoading
                              ? "none"
                              : "auto",
                          }}
                          onClick={() => handleTabClick("Organization")}
                        >
                          Organization
                        </div>
                        <div
                          className={`tab ${
                            activeTab === "Charity" ? "active" : ""
                          }`}
                          style={{
                            opacity: updateOrganizationLoading ? 0.5 : 1,
                            pointerEvents: updateOrganizationLoading
                              ? "none"
                              : "auto",
                          }}
                          onClick={() => handleTabClick("Charity")}
                        >
                          Charity
                        </div>
                        <div
                          className={`tab ${
                            activeTab === "Contact" ? "active" : ""
                          }`}
                          style={{
                            opacity: updateOrganizationLoading ? 0.5 : 1,
                            pointerEvents: updateOrganizationLoading
                              ? "none"
                              : "auto",
                          }}
                          onClick={() => handleTabClick("Contact")}
                        >
                          Contact
                        </div>
                      </div>
                      {/* tab content */}
                      {activeTab === "Organization" && (
                        <div className="tab-content">
                          <OrganizationDetailsForm
                            token={token}
                            organization={organization}
                            setOrganization={setOrganization}
                            countries={countries}
                            usaStates={usaStates}
                            provinces={provinces}
                            handleOrganizationDetailsSubmit={
                              handleOrganizationDetailsSubmit
                            }
                            phone={organization.phone}
                            formattedPhone={organization.formattedPhone}
                            handlePhoneChange={handlePhoneChange}
                          />
                        </div>
                      )}
                      {activeTab === "Charity" && (
                        <div className="tab-content">
                          <CharityDetailsForm
                            organization={organization}
                            setOrganization={setOrganization}
                            countries={countries}
                            usaStates={usaStates}
                            provinces={provinces}
                            handleOrganizationDetailsSubmit={
                              handleOrganizationDetailsSubmit
                            }
                            setSignaturePopup={setSignaturePopup}
                          />
                        </div>
                      )}
                      {activeTab === "Contact" && (
                        <div className="tab-content">
                          <ContactDetailsForm
                            organization={organization}
                            setOrganization={setOrganization}
                            handleOrganizationDetailsSubmit={
                              handleOrganizationDetailsSubmit
                            }
                            contactPhone={organization.contactPhone}
                            formattedContactPhone={
                              organization.formattedContactPhone
                            }
                            handleContactPhoneChange={handleContactPhoneChange}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="no-data-found-div">
                      <Tippy content="No Organization Data Found">
                        <img
                          src={noDataFound}
                          alt="No Organization Data Found"
                        />
                      </Tippy>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {signaturePopup && (
        <SignaturePopup
          token={token}
          setSignaturePopup={setSignaturePopup}
          organization={organization}
        />
      )}
    </>
  );
};

export default OrganizationComponent;
