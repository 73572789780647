import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { MDBCheckbox } from "mdb-react-ui-kit";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// multi select animated components
const animatedComponents = makeAnimated();

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FilterECheckModal = ({
  setFilterECheckModal,
  setFilteredParams,
  entityOptions,
  entitySelected,
  handleSelectChange,
  amount,
  handleAmountChange,
  accountHolderName,
  setAccountHolderName,
  accountNumber,
  setAccountNumber,
  campaignTag,
  handleCampaignTagChange,
  email,
  setEmail,
  invoice,
  setInvoice,
  createdBy,
  setCreatedBy,
  status,
  statusOptions,
  handleStatusChange,
  instituteNumber,
  setInstituteNumber,
  instituteOptions,
  transitNumber,
  setTransitNumber,
  billingPeriod,
  handleBillingPeriodChange,
  frequencyOptions,
  comments,
  setComments,
  pageSize,
  handlePerPage,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  setLoadings,
  handleResetFilter,
  donorSpecific
}) => {
  // redux nodes
  const { nodesData } = useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    setFilterECheckModal(false);
  };

  // should render fields?
  const shouldRenderAmountField = entitySelected.some(
    (option) => option.value === "amount"
  );

  const shouldRenderAccountHolderNameField = entitySelected.some(
    (option) => option.value === "account_holder_name"
  );

  const shouldRenderAccountNumberField = entitySelected.some(
    (option) => option.value === "account_number"
  );

  const shouldRenderCampaignTagField = entitySelected.some(
    (option) => option.value === "campaign_tag"
  );

  const shouldRenderEmailField = entitySelected.some(
    (option) => option.value === "email"
  );

  const shouldRenderInvoiceField = entitySelected.some(
    (option) => option.value === "invoice"
  );

  const shouldRenderCreatedByField = entitySelected.some(
    (option) => option.value === "created_by"
  );

  const shouldRenderBillingPeriodField = entitySelected.some(
    (option) => option.value === "billing_period"
  );

  const shouldRenderStatusField = entitySelected.some(
    (option) => option.value === "status"
  );

  const shouldRenderInstituteNumberField = entitySelected.some(
    (option) => option.value === "institute_number"
  );

  const shouldRenderTransitNumberField = entitySelected.some(
    (option) => option.value === "transit_number"
  );

  const shouldRenderCommentsField = entitySelected.some(
    (option) => option.value === "comments"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // handle transit number
  const handleTransitNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 10.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setTransitNumber(sanitizedInput);
  };

  const handleAccountNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 20.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 20);
    setAccountNumber(sanitizedInput);
  };

  // filter event
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'amount' to the apiData if it's selected
    if (shouldRenderAmountField) {
      apiData.amount = parseFloat(amount);
    }

    // Add 'account_holder_name' to the apiData if it's selected
    if (shouldRenderAccountHolderNameField) {
      apiData.account_holder_name = accountHolderName;
    }

    // Add 'account_number' to the apiData if it's selected
    if (shouldRenderAccountNumberField) {
      apiData.account_number = accountNumber;
    }

    // Add 'campaign_tag' to the apiData if it's selected
    if (shouldRenderCampaignTagField) {
      apiData.campaign_tag = campaignTag;
    }

    // Add 'created_by' to the apiData if it's selected
    if (shouldRenderCreatedByField) {
      apiData.created_by = createdBy;
    }

    // Add 'invoice' to the apiData if it's selected
    if (shouldRenderInvoiceField) {
      apiData.invoice = invoice;
    }

    // Add 'email' to the apiData if it's selected
    if (shouldRenderEmailField) {
      apiData.email = email;
    }

    // Add 'billing_period' to the apiData if it's selected
    if (shouldRenderBillingPeriodField) {
      apiData.billing_period = billingPeriod;
    }

    // add status
    if (shouldRenderStatusField) {
      apiData.status = status;
    }

    // add institute number
    if (shouldRenderInstituteNumberField) {
      apiData.institute_number = instituteNumber;
    }

    // add transit number
    if (shouldRenderTransitNumberField) {
      apiData.transit_number = transitNumber;
    }

    // add comments
    if (shouldRenderCommentsField) {
      apiData.comments = comments;
    }

    // sort by
    if (sortBy) {
      apiData.by = sortBy.value;
    }

    // if amount > 0
    if (shouldRenderAmountField && amount > 0) {
      setLoadings(true);
      setFilterECheckModal(false);
      setFilteredParams({
        apiData,
        pageSize,
        order: orderBy.value,
      });
    } else if (!shouldRenderAmountField) {
      setLoadings(true);
      setFilterECheckModal(false);
      setFilteredParams({
        apiData,
        pageSize,
        order: orderBy.value,
      });
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter eCheck Transactions</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>

            <Select
              isMulti
              components={animatedComponents}
              styles={styles}
              options={entityOptions}
              onChange={handleSelectChange}
              value={entitySelected}
            />
            <form
              style={{ marginTop: 10 + "px" }}
              className="update-media-form"
              onSubmit={handleFormSubmit}
            >
              {/* amount */}
              {shouldRenderAmountField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "amount")
                        ?.label
                    }
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={amount}
                    onChange={(e) => handleAmountChange(e)}
                    inputMode="decimal"
                    step="0.01"
                    title=""
                    required
                    placeholder={
                      entitySelected.find((option) => option.value === "amount")
                        ?.label
                    }
                    helperText={
                      amount !== "" && amount <= 0
                        ? "Value must be greater than 0"
                        : ""
                    }
                    error={amount !== "" && amount <= 0}
                  />
                </div>
              )}
              {/* accountHolderName */}
              {shouldRenderAccountHolderNameField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "account_holder_name"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "account_holder_name"
                      )?.label
                    }
                    required
                    value={accountHolderName}
                    onChange={(e) => setAccountHolderName(e.target.value)}
                  />
                </div>
              )}
              {/* account number */}
              {shouldRenderAccountNumberField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "account_number"
                      )?.label
                    }
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={accountNumber}
                    onChange={handleAccountNumber}
                    title=""
                    required
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "account_number"
                      )?.label
                    }
                  />
                </div>
              )}
              {/* campaign_tag */}
              {shouldRenderCampaignTagField && nodesData && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "campaign_tag"
                      )?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {nodesData.campaign_tag.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={campaignTag.includes(item.value)}
                          onChange={() => handleCampaignTagChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* comments */}
              {shouldRenderCommentsField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "comments"
                      )?.label
                    }
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    title=""
                    required
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "comments"
                      )?.label
                    }
                  />
                </div>
              )}
              {/* email */}
              {shouldRenderEmailField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "email")
                        ?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find((option) => option.value === "email")
                        ?.label
                    }
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    readOnly={donorSpecific}
                  />
                </div>
              )}
              {/* invoice */}
              {shouldRenderInvoiceField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "invoice"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "invoice"
                      )?.label
                    }
                    required
                    value={invoice}
                    onChange={(e) => setInvoice(e.target.value)}
                  />
                </div>
              )}
              {/* created_by */}
              {shouldRenderCreatedByField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "created_by"
                      )?.label
                    }
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "created_by"
                      )?.label
                    }
                    required
                    value={createdBy}
                    onChange={(e) => setCreatedBy(e.target.value)}
                  />
                </div>
              )}
              {/* billing_period */}
              {shouldRenderBillingPeriodField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "billing_period"
                      )?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {frequencyOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={billingPeriod.includes(item.value)}
                          onChange={() => handleBillingPeriodChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* status */}
              {shouldRenderStatusField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find((option) => option.value === "status")
                        ?.label
                    }
                  </label>
                  <div className="checkbox-container">
                    {statusOptions.map((item) => (
                      <div className="checkbox-div" key={item.value}>
                        <MDBCheckbox
                          label={item.label}
                          checked={status.includes(item.value)}
                          onChange={() => handleStatusChange(item.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* institute_number */}
              {shouldRenderInstituteNumberField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "institute_number"
                      )?.label
                    }
                  </label>
                  <Select
                    required
                    options={instituteOptions}
                    value={instituteOptions.find(
                      (option) => option.value === instituteNumber
                    )}
                    onChange={(option) => setInstituteNumber(option.value)}
                    styles={styles}
                  />
                </div>
              )}
              {/* transit number */}
              {shouldRenderTransitNumberField && (
                <div className="input-margins-div">
                  <label>
                    {
                      entitySelected.find(
                        (option) => option.value === "transit_number"
                      )?.label
                    }
                  </label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={transitNumber}
                    onChange={handleTransitNumber}
                    title=""
                    required
                    placeholder={
                      entitySelected.find(
                        (option) => option.value === "transit_number"
                      )?.label
                    }
                  />
                </div>
              )}
              {/* page size */}
              <div className="input-margins-div">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === pageSize
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                />
              </div>
              {/* sort by */}
              <div className="input-margins-div">
                <label>Sort by</label>
                <Select
                  value={sortBy}
                  onChange={(option) => setSortBy(option)}
                  options={entityOptions}
                  styles={styles}
                  isClearable
                />
              </div>
              {/* order by */}
              <div className="input-margins-div">
                <label>Order by</label>
                <Select
                  value={orderBy}
                  onChange={(option) => setOrderBy(option)}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "desc", label: "Descending" },
                  ]}
                  styles={styles}
                />
              </div>
              <div className="reset-and-filter-div">
                <button
                  type="button"
                  className="reset-fields-btn"
                  onClick={handleResetFilter}
                >
                  RESET
                </button>
                <button type="submit" className="filter-records-btn">
                  FILTER
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterECheckModal;
