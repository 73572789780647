import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterECheckRecords,
  getECheckData,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import CardDataTable from "./CardDataTable";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const DetailView = ({
  token,
  donorSpecific,
  email,
  triggerScroll,
  setLoadings,
  filteredParams,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  setPageSize,
  handleMoreDetailsPopup,
  handleSchedulePopup,
  handleEditECheckPopup,
  handleCloseECheckPopup,
  handleAddPaymentPopup,
  setNewECheckTransactionPopup,
  setCopiedObj,
  setMakeDisabled
}) => {
  // redux state
  const { eCheckData } = useSelector((state) => state.eCheck);

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (donorSpecific === undefined) {
        if (filteredParams) {
          dispatch(
            filterECheckRecords({
              token,
              page: 1,
              pageSize: value,
              order: filteredParams.order,
              apiData: filteredParams.apiData,
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);
            }
          });
        } else {
          dispatch(
            getECheckData({
              token,
              page: 1,
              pageSize: value,
              order: "asc",
              by: "email",
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);
            }
          });
        }
      } else {
        if (filteredParams) {
          dispatch(
            filterECheckRecords({
              token: token,
              page: 1,
              pageSize: value,
              order: filteredParams.order,
              apiData: {
                ...filteredParams.apiData,
                email,
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        } else {
          // get all eCheck data
          dispatch(
            filterECheckRecords({
              token,
              page: 1,
              pageSize: value,
              order: "asc",
              apiData: {
                email,
                by: "email",
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        }
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (donorSpecific === undefined) {
        if (filteredParams) {
          dispatch(
            filterECheckRecords({
              token,
              page: value,
              pageSize: perPage,
              order: filteredParams.order,
              apiData: filteredParams.apiData,
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);
            }
          });
        } else {
          dispatch(
            getECheckData({
              token,
              page: value,
              pageSize: perPage,
              order: "asc",
              by: "email",
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);
            }
          });
        }
      } else {
        if (filteredParams) {
          dispatch(
            filterECheckRecords({
              token: token,
              page: value,
              pageSize: perPage,
              order: filteredParams.order,
              apiData: {
                ...filteredParams.apiData,
                email,
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        } else {
          // get all eCheck data
          dispatch(
            filterECheckRecords({
              token,
              page: value,
              pageSize: perPage,
              order: "asc",
              apiData: {
                email,
                by: "email",
              },
            })
          ).then((res) => {
            if (
              res.error &&
              res.error.message === "Request failed with status code 401"
            ) {
              localStorage.removeItem("user");
              navigate("/login");
            } else {
              setCurrentPage(res.payload.links.currentPage);
              setPerPage(res.payload.links.perPage);
              setPageSize(res.payload.links.perPage);
              setTotalPages(res.payload.links.lastPage);

              // Scroll to the desired section using the scrollIntoView method
              triggerScroll();
            }
          });
        }
      }
    }
  };

  return (
    <>
      {eCheckData?.data?.length > 0 ? (
        <>
          <div className="transactions-table">
            <MDBTable align="middle">
              <MDBTableHead className="thead">
                <tr>
                  <th scope="col">Campaign</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Billing Period</th>
                  <th scope="col">Account Title</th>
                  <th scope="col">Institute Name</th>
                  <th scope="col">Transit#</th>
                  <th scope="col">Account#</th>
                  <th scope="col">Status</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Actions</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {eCheckData?.data?.length > 0 &&
                  eCheckData.data.map((data, index) => (
                    // the below is a row
                    <CardDataTable
                      key={data.tagNumber}
                      data={data}
                      index={index}
                      donorSpecific={donorSpecific}
                      handleMoreDetailsPopup={handleMoreDetailsPopup}
                      handleEditECheckPopup={handleEditECheckPopup}
                      handleCloseECheckPopup={handleCloseECheckPopup}
                      handleSchedulePopup={handleSchedulePopup}
                      handleAddPaymentPopup={handleAddPaymentPopup}
                      setNewECheckTransactionPopup={
                        setNewECheckTransactionPopup
                      }
                      setCopiedObj={setCopiedObj}
                      setMakeDisabled={setMakeDisabled}
                    />
                  ))}
              </MDBTableBody>
            </MDBTable>
          </div>

          <div className="transactions-pagination">
            <div className="per-page-and-pagination">
              <div className="per-page">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === String(perPage)
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                  menuPlacement="top"
                />
              </div>
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No eCheck Transactions Found">
            <img src={noDataFound} alt="No eCheck Transactions Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default DetailView;
