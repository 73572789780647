/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import ReactDatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearAddPaymentStates,
  addPayment,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// custom text field
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// get organization for currency symbol
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const AddPaymentPopup = ({
  token,
  setAddPaymentPopup,
  addPaymentObj,
  layout,
}) => {
  // state
  const [selectedOrganization] = useState(getOrganization()); // for currency symbol

  // redux state
  const { addPaymentLoading, addPaymentData } = useSelector(
    (state) => state.eCheck
  );

  // close modal
  const handleCloseModal = () => {
    if (!addPaymentLoading) {
      setAddPaymentPopup(false);
    }
  };

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear previous error msg
  useEffect(() => {
    dispatch(clearAddPaymentStates());
  }, []);

  // start date
  const [startDate, setStartDate] = useState(new Date());

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // hiding the past dates
  const isSelectableDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison
    return date >= currentDate;
  };

  // amount
  const [amount, setAmount] = useState(addPaymentObj?.amount || "");

  // amount change
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/[^0-9.]/g, "");
    const parts = newValue.split(".");
    if (parts.length > 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // comments
  const [comments, setComments] = useState(addPaymentObj?.comments || "");

  // form submit event
  const handleAddPayment = (e) => {
    e.preventDefault();
    dispatch(
      addPayment({
        token,
        tagNumber: addPaymentObj.tagNumber,
        processDate: formatDate(startDate),
        source: layout === "list" ? "transaction" : "summary",
        amount: parseFloat(amount),
        comments,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setAddPaymentPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className={`update-widgets-modal`}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Add Payment - {addPaymentObj.tagNumber}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleAddPayment}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="startDate">Date</label>
                  <ReactDatePicker
                    style={{
                      width: 100 + "%",
                    }}
                    className="edit-media-inputs"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    placeholderText="Start Date"
                    // required
                    minDate={new Date()}
                    filterDate={isSelectableDate}
                    portalId="end-date-datepicker"
                    popperClassName="custom-datepicker-zindex"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="amount">Amount</label>
                  {addPaymentObj.campaignMinimumAmount !== "" && (
                    <span
                      style={{
                        marginLeft: 10 + "px",
                        fontSize: 13 + "px",
                        fontWeight: 600,
                        color: "#6a6c6f",
                      }}
                    >
                      Minimum Amount: {selectedOrganization?.currencySymbol}
                      {addPaymentObj.campaignMinimumAmount}
                    </span>
                  )}
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={amount}
                    onChange={handleAmountChange}
                    title=""
                    // required
                    placeholder="Amount"
                    helperText={
                      amount !== "" &&
                      amount < addPaymentObj.campaignMinimumAmount
                        ? `Please enter an amount greater than or equal to ${addPaymentObj.campaignMinimumAmount}`
                        : ""
                    }
                    error={
                      amount !== "" &&
                      amount < addPaymentObj.campaignMinimumAmount
                    }
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="comments">Comments</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    autoComplete="off"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    title=""
                    required
                    placeholder="Comments"
                  />
                </MDBCol>
              </MDBRow>

              {addPaymentData &&
                addPaymentData.tagNumber === addPaymentObj.tagNumber &&
                (addPaymentData.apiError || addPaymentData.axiosError) && (
                  <div className="error-msg">
                    {addPaymentData.apiError ? (
                      <>
                        {typeof addPaymentData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(addPaymentData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {addPaymentData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{addPaymentData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{addPaymentData.axiosError}</>
                    )}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={addPaymentLoading}
                >
                  {addPaymentLoading ? (
                    <MetroSpinner
                      loading={addPaymentLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddPaymentPopup;
