/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearMakeAccountDefaultStates,
  makeDefault,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const MakeDefaultPopup = ({
  token,
  setMakeAccountDefaultPopup,
  makeAccountDefaultObj,
}) => {
  // redux state
  const { makeDefaultLoading, makeDefaultData } = useSelector(
    (state) => state.eCheck
  );

  // close modal
  const handleCloseModal = () => {
    if (!makeDefaultLoading) {
      setMakeAccountDefaultPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear prev deleteBankAccount redux state on component mount
  useEffect(() => {
    dispatch(clearMakeAccountDefaultStates());
  }, []);

  // make account default
  const makeThisAccountDefault = () => {
    dispatch(
      makeDefault({
        token,
        tagNumber: makeAccountDefaultObj.tagNumber,
      })
    ).then((res) => {
      const { successMsg, axiosError } = res.payload;

      if (successMsg) {
        // close popup
        setMakeAccountDefaultPopup(false);

        // Success toast
        toast.success(successMsg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Scroll to the desired section using the scrollIntoView method
        const bankAccountSection =
          document.getElementById("bankAccountSection");
        if (bankAccountSection) {
          bankAccountSection.scrollIntoView({ behavior: "smooth" });
        }
      } else if (axiosError === "Request failed with status code 401") {
        // Handle unauthorized access
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Set as Default Account - {makeAccountDefaultObj.accountHolderName}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#007c16" }}>
                Are you sure you want to set this account as your default?{" "}
                <span
                  style={{
                    fontWeight: "600",
                    color: "#212529",
                  }}
                >
                  {makeAccountDefaultObj.accountNumber}
                </span>
              </p>
              <p style={{ color: "#6a6c6f" }}>
                This will update your default account for all future
                transactions.
              </p>
            </div>

            {makeDefaultData &&
              makeDefaultData.tagNumber === makeAccountDefaultObj.tagNumber &&
              (makeDefaultData.apiError || makeDefaultData.axiosError) && (
                <div className="error-msg">
                  {makeDefaultData.apiError ? (
                    <>
                      {typeof makeDefaultData.apiError === "object" ? (
                        // Case 1: If apiError is an object
                        Object.keys(makeDefaultData.apiError).map(
                          (field, index) => (
                            <p key={index} style={{ marginBottom: 0 }}>
                              {makeDefaultData.apiError[field][0]}
                            </p>
                          )
                        )
                      ) : (
                        // Case 2: If apiError is a simple string
                        <>{makeDefaultData.apiError}</>
                      )}
                    </>
                  ) : (
                    <>{makeDefaultData.axiosError}</>
                  )}
                </div>
              )}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                className="submit addModal"
                disabled={makeDefaultLoading}
                onClick={makeThisAccountDefault}
              >
                {makeDefaultLoading ? (
                  <MetroSpinner
                    loading={makeDefaultLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "SET"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MakeDefaultPopup;
